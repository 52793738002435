/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
 import React from "react";
 import styled from "styled-components";
 import AccordionSummary from "@material-ui/core/AccordionSummary";
 
 const StyledAccordionSummary = styled(({ isExpanded, ...props }) => (
   <AccordionSummary {...props} />
 ))`
   min-height: initial;
   display: flex;
 
   &.Mui-expanded {
     min-height: initial;
   }
 
   .MuiAccordionSummary-content {
     margin: 10px 0px 10px 0px;
   }
 
   .MuiAccordionSummary-expandIcon {
     transition: transform 0.3s ease-in-out;
   }
 
   @media (max-width: 600px) {
     & .MuiAccordionSummary-expandIcon {
       transform: ${({ isExpanded }) =>
         isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
     }
   }
 
   &.MuiButtonBase-root {
     padding: 0px 12px;
   }
 `;
 
 export { StyledAccordionSummary };
 