/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//UI
import {
  StyledGrid,
  StyledContainer,
} from "@hcl-commerce-store-sdk/react-component";

function StandardPageHero2BlocksLayout({
  cid,
  banner,
  sectionOne,
  sectionTwo,
  classesLogin,
  ...props
}: any) {
  let { page } = props;
  if (!page) {
    page = {};
  }

  /*const Banner = () => {
    return banner ? (
      <StyledGrid item xs={12} id={`banner_${cid}`}>
        {banner.map((e: any) => {
          const CurrentComponent = e.CurrentComponent;
          return (
            <div key={e.key} className="section">
              <CurrentComponent />
            </div>
          );
        })}
      </StyledGrid>
    ) : (
      <></>
    );
  };*/

  const SectionOne = () => {
    return sectionOne ? (
      <>
        <StyledGrid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          id={`sectionone_${cid}`}
          className={classesLogin.divLayout}>
          {sectionOne.map((e: any) => {
            const CurrentComponent = e.CurrentComponent;
            return <CurrentComponent key={e.key} />;
          })}
        </StyledGrid>
      </>
    ) : (
      <></>
    );
  };

  const SectionTwo = () => {
    return sectionTwo ? (
      <>
        <StyledGrid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          id={`sectiontwo_${cid}`}
          className={classesLogin.divLayout}>
          {sectionTwo.map((e: any) => {
            const CurrentComponent = e.CurrentComponent;
            return <CurrentComponent key={e.key} />;
          })}
        </StyledGrid>
      </>
    ) : (
      <></>
    );
  };

  const SectionMiddle = () => {
    return sectionTwo ? (
      <>
        <StyledGrid
          item
          xs={12}
          sm={8}
          md={2}
          lg={5}
          id={`sectiontwo_${cid}`}></StyledGrid>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <StyledContainer id={cid}>
        <StyledGrid container spacing={3} justify="center">
          <SectionOne />
          <div className={classesLogin.divSeparator}></div>
          <SectionTwo />
        </StyledGrid>
      </StyledContainer>
    </>
  );
}

export { StandardPageHero2BlocksLayout };
