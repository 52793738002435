/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

 import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
 import { Configuration } from './configuration';
 // Some imports not used depending on template conditions
 // @ts-ignore
 import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
 // @ts-ignore
 import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

 /**
  * StoreLocatorApi - axios parameter creator
  * @export
  */
  export interface getParent {
    GeoNode?: object;
    }
 export const shippingInfoUpdateOrderShippingInfoApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * Gets store location information by a store unique ID.
          * @summary Get by store unique ID
          * @param {string} storeId The store identifier.
          * @param {string} orderId The unique identifier.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
          shippingInfoUpdateOrderShippingInfo: async (storeId: string, body?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('shippingInfoUpdateOrderShippingInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/shipping_info`
                .replace(`{${"storeId"}}`, String(storeId))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'put', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
     }
 };
 
 /**
  * StoreLocatorApi - functional programming interface
  * @export
  */
 export const shippingInfoUpdateOrderShippingInfoApiFp = function(configuration?: Configuration) {
     const localVarAxiosParamCreator = shippingInfoUpdateOrderShippingInfoApiAxiosParamCreator(configuration)
     return {
         /**
          * Gets store location information by a store unique ID.
          * @summary Get by store unique ID
          * @param {string} storeId The store identifier.
          * @param {string} parentgeoId The unique identifier.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
          async shippingInfoUpdateOrderShippingInfo(storeId: string, body: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingInfoUpdateOrderShippingInfo(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
     }
 };
 
 /**
  * StoreLocatorApi - factory interface
  * @export
 
 /**
  * StoreLocatorApi - object-oriented interface
  * @export
  * @class StoreLocatorApi
  * @extends {BaseAPI}
  */
 export class ShippingInfoApi extends BaseAPI {
     /**
      * Gets store location information by a store unique ID.
      * @summary Get by store unique ID
      * @param {string} storeId The store identifier.
      * @param {string} parentgeoId The unique identifier.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof StoreLocatorApi
      */
      public shippingInfoUpdateOrderShippingInfo(storeId: string, body: any, options?: any) {
        return shippingInfoUpdateOrderShippingInfoApiFp(this.configuration).shippingInfoUpdateOrderShippingInfo(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }
 }
 