/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { getSite, useSite } from "../../../../../_foundation/hooks/useSite";
//Custom libraries
import addressUtil from "../../../../../utils/addressUtil";
import { HOME } from "../../../../../constants/routes";
//Redux
import { registrationStatusSelector } from "../../../../../redux/selectors/user";
import * as userAction from "../../../../../redux/actions/user";
//UI
import {
  StyledFormControlLabel,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StyledTextField } from "../../../../elements/text-field";
//GA360
import AsyncCall from "../../../../../_foundation/gtm/async.service";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import visibilityIcon from "../../../../../img/visibilityIcon.svg";
import showPasswordIcon from "../../../../../img/showPasswordIcon.svg";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Checkbox } from "@material-ui/core";
import validaSocioService from "../../../../../_foundation/apis/transaction/validaSocio.service";

interface RegistrationContext {
  cid: string;
  showSignInPage: any;
}

const LoginLabels = styled.span`
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 160%;
  font-weight: 300;
  margin-top: ;
`;

const TextSubHeader = styled.span`
  color: #000;
  font-size: 15px;
  margin-top: 0;
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
`;

const LoginButton = styled.button`
  background: #fd4364;
  color: #fff;
  border-radius: 0;
  min-width: 230px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-family: Fjalla One, sans-serif;

  :disabled {
    background: #fd4364a6;
  }
  :enabled {
    cursor: pointer;
  }
`;

const RegisterLabel = styled.span`
  font-size: 13px;
  font-family: Roboto;
  font-weight: 300;
  line-height: 16px;
`;
const styledLoginModal = makeStyles((theme) => ({
  divContainer: {},
  divItem: {
    marginTop: "10px",
    paddingRight: "2%",
  },
  divItemRight: {
    marginTop: "10px",
    paddingLeft: "2%",
  },

  divSepBot: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      borderBottom: "1px solid #e9e9e9",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  box: {
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  loginTextBox: {
    "&input": {
      height: "40px !important",
    },
    margin: "0px !important",
    marginBottom: "10px !important",
    height: "40px !important",
    borderRadius: "0px",
    border: "1px solid #bfbfbf",
    backgroud: "#FFFFFF !important",
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  loginTextBoxError: {
    margin: "0px !important",
    marginBottom: "10px !important",
    height: "40px !important",
    borderRadius: "0px",
    border: "1px solid #d90101",
    backgroud: "#FFFFFF !important",
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {},
  },
  divInput: {
    marginBottom: "20px",
  },
  divForgot: {
    marginTop: "10px",
  },
  "@global": {
    fieldset: {
      display: "none",
    },
    input: {
      height: "40px !important",
      padding: "0px 8px !important",
    },
    p: {
      lineHeight: "1 !important",
      fontSize: "12px !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "9.5px !important",
      },
    },
  },
  labelCheck: {
    fontFamily: "Roboto, sans-serif",
    marginBottom: "0px",
  },
  textAdvert: {
    display: "flex",
    padding: "0.5rem",
    color: "#0a0a0a",
    fontWeight: 400,
    backgroundColor: "#f7e4e1",
    border: "1px solid #930f16b0",
    textAlign: "justify",
    fontSize: "12px !important",
  },
  width100: {
    width: "98%",
    marginTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  controlLabel: {
    marginRight: "8px",
    marginLeft: "0px",
  },
  checkBox: {
    marginRight: "8px",
    height: "20px !important",
    minWidth: "20px !important",
    borderRadius: "4px !important",
    border: "1px solid #fbfbfb !important",
    borderColor: "#fbfbfb !important",
    cursor: "pointer",
    color: "#FFF !important",
  },
  subHeader: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "40px",
    },
  },
  divForm: {
    justifyContent: "center",
    borderBottom: "1px solid #bfbfbf",
    paddingBottom: "10px",
    marginBottom: "25px",
  },
  bottom: {
    justifyContent: "center",
    textAlign: "center",
  },
  showPassword: {
    position: "absolute",
    marginTop: "5px",

    cursor: "pointer",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "33%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "28%",
    },
  },
  termsPrivacy: {
    cursor: "pointer",
    color: "#1bb0aa !important",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rowChecks: {
    display: "inline-flex",
    marginBottom: "5px",
    alignItems: "center",
  },
  labelCheckDiv: {},
  iconProp: {
    color: "#000",
    width: "12px",
    marginRight: "5px",
  },
  iconEye2: {
    color: "#000",
    padding: "0px",
    width: "20px",
  },
  labelMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  labelDesktop: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
function RegistrationLayout({
  cid,
  showSignInPage,
  ...props
}: RegistrationContext) {
  const classesLogin = styledLoginModal();
  const widgetName = getDisplayName(RegistrationLayout);
  const dispatch = useDispatch();
  const registrationStatus = useSelector(registrationStatusSelector);
  const { t } = useTranslation();
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const catalogId: string = mySite ? mySite.catalogID : "";
  const preferredLanguage: string = mySite ? mySite.defaultLanguageID : "";
  const [noSocio, setNoSocio] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [lastName2, setLastName2] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [receiveEmail, setReceiveEmail] = useState<boolean>(true);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [validSocio, setValidSocio] = useState<boolean>(true);
  // temporal: baja sap .
  // const [statusSap, setStatusSap] = useState<boolean>(false);

  const siteInfo = getSite();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */
  const canCreate = (): boolean => {
    if (
      noSocio.trim() !== "" &&
      noSocio.length == 10 &&
      email.trim() !== "" &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      password1.trim() !== "" &&
      password2.trim() !== "" &&
      phone.trim() !== "" &&
      acceptTerms !== false
      // temporal : baja sap
      // statusSap !== false
    ) {
      return (
        addressUtil.validateEmail(email) &&
        addressUtil.validatePhoneNumber(phone)
      );
    }
    return false;
  };

  const validCliente = async (): Promise<boolean> => {
    try {
      let serviceRespSocio = await validaSocioService.validate({
        storeId: storeId,
        noSocio: noSocio.trim(),
      });
      // console.log(serviceRespSocio);

      let data = JSON.parse(serviceRespSocio.data.response);
      // console.log(data);

      if (data.response.indexOf("Successful") != -1) {
        setValidSocio(true);
        return true;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      setValidSocio(false);
      return false;
    }
  };

  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  // console.log(
  //   noSocio,
  //   firstName,
  //   lastName,
  //   password1,
  //   password2,
  //   email,
  //   phone,
  //   storeId,
  //   catalogId,
  //   receiveEmail,
  //   preferredLanguage,
  //   payloadBase
  // );

  const handleSubmit = async (props: any) => {
    props.preventDefault();
    if (await validCliente()) {
      dispatch(
        userAction.registrationAction({
          body: {
            logonId: noSocio.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            logonPassword: password1,
            logonPasswordVerify: password2,
            registerType: "G",
            profileType: "C",
            email1: email,
            phone1: phone.trim(),
            storeId,
            catalogId,
            preferredLanguage,
            receiveEmail: receiveEmail,
            organizationDistinguishedName: "o=Plata,o=root organization",
            receiveEmailPreference: [
              {
                value: receiveEmail,
                storeID: storeId,
              },
            ],
            attributes: [
              {
                contactInfoAttrValue: lastName2.trim(),
                contactInfoAttrKey: "addressField1",
              },
            ],
            challengeQuestion: "-",
            challengeAnswer: "-",
          },
          ...payloadBase,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Validations
  const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
  const validatedNosocio = (val) => {
    if (rx_live.test(val)) setNoSocio(val);
  };

  const validatedPass = (val) => {
    return val.length >= 6 && /\d/.test(val);
  };

  if (registrationStatus) {
    //GA360
    if (mySite.enableGA) {
      AsyncCall.sendFormCompletionEvent("Register", {
        enableUA: mySite.enableUA,
        enableGA4: mySite.enableGA4,
      });
    }
    return <Redirect to={HOME} />;
  } else {
    return (
      <>
        <Grid container className={classesLogin.divContainer}>
          <Grid className={classesLogin.divSepBot}>
            <TextSubHeader className={classesLogin.subHeader}>
              LLENA TUS DATOS Y DISFRUTA DE LOS BENEFICIOS
            </TextSubHeader>
          </Grid>
          {/* Temporal: baja sap */}
          {/* <Grid className={classesLogin.width100}>
            <LoginLabels className={classesLogin.textAdvert}>
              Estimado distribuidor de dpdirecto, el proceso de creación de
              cuenta por el momento está siendo actualizado para ofrecerte un
              mejor servicio, en un lapso de 24 horas podrás crear tu cuenta de
              manera satisfactoria e iniciar a incrementar tus ganancias.
            </LoginLabels>
          </Grid> */}
          <form
            noValidate
            name="registrationForm"
            id={`registration_form_5_${cid}`}
            onSubmit={handleSubmit}>
            <Grid container className={classesLogin.divForm}>
              <StyledGrid item xs={6} md={6} className={classesLogin.divItem}>
                <Grid>
                  <LoginLabels className={classesLogin.labelDesktop}>
                    Número de distribuidor: *
                  </LoginLabels>
                  <LoginLabels className={classesLogin.labelMobile}>
                    N° de distribuidor: *
                  </LoginLabels>
                </Grid>
                <StyledTextField
                  autoFocus
                  className={
                    noSocio.length == 10
                      ? classesLogin.loginTextBox
                      : noSocio == ""
                      ? classesLogin.loginTextBox
                      : classesLogin.loginTextBoxError
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="noSocio"
                  onChange={(e) => validatedNosocio(e.target.value)}
                  value={noSocio}
                  id={"Input_Distributor"}
                  tabIndex={1}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                  }}
                  error={!validSocio || !addressUtil.validateSocio(noSocio)}
                  helperText={
                    !addressUtil.validateSocio(noSocio)
                      ? "El N° debe ser de 10 digitos"
                      : !validSocio
                      ? t("RegistrationLayout.Msgs.InvalidClientNumber")
                      : ""
                  }
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </StyledGrid>
              <StyledGrid
                item
                xs={6}
                md={6}
                className={classesLogin.divItemRight}>
                <Grid>
                  <LoginLabels>Nombre(s): *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={classesLogin.loginTextBox}
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  id={"Input_First_Name"}
                  onChange={(event) => {
                    if (/^[a-zA-Z\s]*$/.test(event.target.value)) {
                      setFirstName(event.target.value);
                    } else {
                      event.preventDefault();
                      return false;
                    }
                  }}
                  value={firstName}
                  tabIndex={2}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </StyledGrid>
              <StyledGrid item xs={6} md={6} className={classesLogin.divItem}>
                <Grid>
                  <LoginLabels>Apellido paterno: *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={classesLogin.loginTextBox}
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  id={"Input_Last_Name"}
                  onChange={(event) => {
                    if (/^[a-zA-Z\s]*$/.test(event.target.value)) {
                      setLastName(event.target.value);
                    } else {
                      event.preventDefault();
                      return false;
                    }
                  }}
                  value={lastName}
                  tabIndex={3}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </StyledGrid>
              <StyledGrid
                item
                xs={6}
                md={6}
                className={classesLogin.divItemRight}>
                <Grid>
                  <LoginLabels>Apellido materno: </LoginLabels>
                </Grid>
                <StyledTextField
                  className={classesLogin.loginTextBox}
                  margin="normal"
                  required
                  fullWidth
                  name="lastName2"
                  id={"Input_Last_Name_Mat"}
                  onChange={(e) => {
                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                      setLastName2(e.target.value);
                    } else {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  value={lastName2}
                  tabIndex={3}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </StyledGrid>
              <StyledGrid item xs={6} md={6} className={classesLogin.divItem}>
                <Grid>
                  <LoginLabels>Correo electrónico: *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={
                    addressUtil.validateEmail(email)
                      ? classesLogin.loginTextBox
                      : classesLogin.loginTextBoxError
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  autoComplete="email"
                  id={"Input_Email"}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  tabIndex={5}
                  inputProps={{
                    maxLength: 100,
                    type: "email",
                  }}
                  error={!addressUtil.validateEmail(email)}
                  helperText={
                    !addressUtil.validateEmail(email)
                      ? "Capture formato correcto"
                      : ""
                  }
                />
              </StyledGrid>
              <StyledGrid
                item
                xs={6}
                md={6}
                className={classesLogin.divItemRight}>
                <Grid>
                  <LoginLabels>Teléfono: *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={
                    phone.length == 10
                      ? classesLogin.loginTextBox
                      : phone == ""
                      ? classesLogin.loginTextBox
                      : classesLogin.loginTextBoxError
                  }
                  margin="normal"
                  fullWidth
                  name="phone"
                  id={"Input_Phone"}
                  autoComplete="phone"
                  onChange={(event) => {
                    if (/^[0-9]*$/.test(event.target.value)) {
                      setPhone(event.target.value);
                    } else {
                      event.preventDefault();
                      return false;
                    }
                  }}
                  value={phone}
                  tabIndex={6}
                  inputProps={{
                    maxLength: 10,
                    type: "tel",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  error={!addressUtil.validatePhoneNumber(phone)}
                  helperText={
                    !addressUtil.validatePhoneNumber(phone)
                      ? "El teléfono debe ser de 10 dígitos"
                      : ""
                  }
                />
              </StyledGrid>

              <StyledGrid item xs={6} md={6} className={classesLogin.divItem}>
                <Grid>
                  <LoginLabels>Contraseña: *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={
                    password1 == ""
                      ? classesLogin.loginTextBox
                      : validatedPass(password1)
                      ? classesLogin.loginTextBox
                      : classesLogin.loginTextBoxError
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="password1"
                  autoComplete="new-password"
                  onChange={(e) => setPassword1(e.target.value)}
                  value={password1}
                  type={showPassword ? "text" : "password"}
                  error={true}
                  helperText={
                    password1 == ""
                      ? ""
                      : !validatedPass(password1) &&
                        "Mínimo 1 letra, 1 número y 6 caractéres"
                  }
                  tabIndex={7}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classesLogin.iconProp}
                        tabIndex={-1}>
                        <IconButton
                          aria-label="close"
                          onClick={handleShowPassword}
                          className={classesLogin.iconEye2}
                          tabIndex={-1}>
                          {!showPassword ? (
                            <img src={visibilityIcon} tabIndex={-1} />
                          ) : (
                            <img src={showPasswordIcon} tabIndex={-1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledGrid>

              <StyledGrid
                item
                xs={6}
                md={6}
                className={classesLogin.divItemRight}>
                <Grid>
                  <LoginLabels>Confirmar contraseña: *</LoginLabels>
                </Grid>
                <StyledTextField
                  className={
                    password2 == ""
                      ? classesLogin.loginTextBox
                      : password1 == password2
                      ? classesLogin.loginTextBox
                      : classesLogin.loginTextBoxError
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="password2"
                  autoComplete="new-password"
                  onChange={(e) => setPassword2(e.target.value)}
                  value={password2}
                  type={showPassword2 ? "text" : "password"}
                  error={true}
                  helperText={
                    password2 == ""
                      ? ""
                      : password1 !== password2 &&
                        "Las contraseñas no coinciden"
                  }
                  tabIndex={8}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classesLogin.iconProp}
                        tabIndex={-1}>
                        <IconButton
                          aria-label="close"
                          onClick={handleShowPassword2}
                          className={classesLogin.iconEye2}
                          tabIndex={-1}>
                          {!showPassword2 ? (
                            <img src={visibilityIcon} tabIndex={-1} />
                          ) : (
                            <img src={showPasswordIcon} tabIndex={-1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledGrid>
              <StyledGrid
                item
                xs={12}
                md={12}
                className={classesLogin.divItem}
                style={{ paddingTop: "5px" }}>
                <Grid className={classesLogin.rowChecks}>
                  <input
                    type="checkbox"
                    className={classesLogin.checkBox}
                    value="acceptTerms"
                    tabIndex={9}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    style={{ marginLeft: "0px" }}
                  />
                  {/*<Checkbox*/}
                  {/*    color="secondary"*/}
                  {/*    value="acceptTerms"*/}
                  {/*    tabIndex={0}*/}
                  {/*    onChange={(e) => setAcceptTerms(e.target.checked)}*/}
                  {/*    style={{*/}
                  {/*      marginRight: '3px',*/}
                  {/*      padding: "9px 2px 9px 9px"*/}
                  {/*    }}*/}
                  {/*/>*/}
                  <RegisterLabel className={classesLogin.labelCheck}>
                    * He leído y consentido el{" "}
                    <span className={classesLogin.termsPrivacy}>
                      <a
                        href="/aviso-privacidad"
                        className={classesLogin.termsPrivacy}>
                        Aviso de Privacidad.
                      </a>
                    </span>
                  </RegisterLabel>
                </Grid>
                <Grid className={classesLogin.rowChecks}>
                  <input
                    type="checkbox"
                    className={classesLogin.checkBox}
                    value="receiveEmail"
                    color="primary"
                    tabIndex={10}
                    onChange={(e) => setReceiveEmail(e.target.checked)}
                    style={{ marginLeft: "0px", borderBlock: "#bfbfbf" }}
                  />
                  {/*<Checkbox*/}
                  {/*    color="secondary"*/}
                  {/*    tabIndex={9}*/}
                  {/*    value="receiveEmail"*/}
                  {/*    onChange={(e) => setReceiveEmail(e.target.checked)}*/}
                  {/*    style={{*/}
                  {/*      marginRight: '3px',*/}
                  {/*      padding: "9px 2px 9px 9px"*/}
                  {/*    }}*/}
                  {/*/>*/}
                  <Grid className={classesLogin.labelCheckDiv}>
                    <RegisterLabel className={classesLogin.labelCheck}>
                      Acepto recibir información relacionada con productos,
                      ofertas y promociones.
                    </RegisterLabel>
                  </Grid>
                </Grid>
              </StyledGrid>
            </Grid>
            <Grid className={classesLogin.bottom}>
              <LoginButton
                type="submit"
                color="primary"
                id={"Create_Account"}
                disabled={!canCreate()}
                className="login-process-button top-margin-1">
                {"CREAR CUENTA"}
              </LoginButton>
            </Grid>
          </form>
        </Grid>
      </>
    );
  }
}

export { RegistrationLayout };
