/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import React, { useRef } from "react";
import { ClickAwayListener } from "@material-ui/core";
//style
import {
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledPopper,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import AllCategoriesExpandedMenu from "../../header/AllCategoriesExpandedMenu";
import ExpandedMenuItem from "./ExpandedMenuItem";
import { Link } from "react-router-dom";

const menuHoverPersonalized = {
  backgroundColor: "#2e3047",
  cursor: "pointer",
  borderTop: "5px solid #00b0aa",
  height: "98%",
};

const styledTypography = {
  fontSize: 15,
  fontWeight: 300,
  fontFamily: "sans-serif"
};

const styledButton = {
  // borderBottom: "3.5px solid #00b0aa",
  height: "100%",
  borderRadius: "0px",
};

const styledPopper = {
  borderTop: "3.5px solid #00b0aa",
};

const MenubarMenuItem = (props) => {
  const { page, selectMenuItem, selectedMenuItem } = props;
  const popperRef: React.RefObject<HTMLButtonElement> =
    useRef<HTMLButtonElement>(null);
  const handleClickAway = (event) => {
    const target = event.target;
    if (
      popperRef.current &&
      (popperRef.current.contains(target) || popperRef.current === target)
    ) {
      return;
    }
    if (selectedMenuItem.id === page.id) {
      selectMenuItem(null);
    }
  };

  const POPPER_ID = `MENU_POPPER_${page.id}`;
  const setWidth = (data) => {
    const { width } = data.offsets.reference;
    if (width > data.popper.width) {
      data.styles.width = width;
    }
    return data;
  };
  return (
    <>
      <StyledBox
        mr={1}
        ml={1}
        className={
          selectedMenuItem !== null && selectedMenuItem.id === page.id
            ? "expanded-menu-hover"
            : "expanded-menu"
        }
        style={
          selectedMenuItem !== null && selectedMenuItem.id === page.id
            ? menuHoverPersonalized
            : null
        }>
        <StyledButton
          className="horizontal-padding-2 vertical-padding-2 fix-padding"
          ref={popperRef}
          variant="text"
          onMouseOver={() => selectMenuItem(page.id)}
          style={
            selectedMenuItem !== null && selectedMenuItem.id === page.id
              ? styledButton
              : null
          }>
          <Link to={page.seo?.href} onClick={(event) => window.orderSort = null}>
              <StyledTypography
                style={styledTypography}
                className="expanded-menu-text"
                variant="body1"
                data-testid="menubar-menuitem-button">
                {page.name.toUpperCase()}
              </StyledTypography>
            </Link>
        </StyledButton>
      </StyledBox>
      <StyledPopper
        style={styledPopper}
        id={POPPER_ID}
        data-testid={POPPER_ID}
        open={selectedMenuItem !== null && selectedMenuItem.id === page.id}
        anchorEl={popperRef.current}
        placement="bottom-start"
        disablePortal
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: true,
          },
          updateWidth: {
            enabled: true,
            order: 875,
            fn: setWidth,
          },
        }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledBox>
            {page.id === "allCategories" ? (
              <AllCategoriesExpandedMenu
                pages={page.children}></AllCategoriesExpandedMenu>
            ) : (
              <ExpandedMenuItem page={page}></ExpandedMenuItem>
            )}
          </StyledBox>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default MenubarMenuItem;
