/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//Redux
import { SiteInfo } from "../../../redux/reducers/reducerStateInterface";
import { siteSelector } from "../../../redux/selectors/site";
import { CommerceEnvironment } from "../../../constants/common";

interface useSiteResponse {
  mySite: SiteInfo;
  storeDisplayName: string;
  installments: any;
  mpPublicKey: string;
}

const useSite = (): useSiteResponse => {
  const { i18n } = useTranslation();
  const mySite: SiteInfo | any = useSelector(siteSelector);
  const langId =
    CommerceEnvironment.reverseLanguageMap[
      i18n.languages[0].split("-").join("_")
    ];
  const storeDisplayName = mySite
    ? mySite.storeCfg.description.filter((d) => d.languageId === langId)[0]
        ?.displayName ||
      mySite.storeCfg.description.filter(
        (d) => d.languageId === mySite.defaultLanguageID
      )[0]?.displayName ||
      mySite.storeName
    : "";
  const installments = [
    {
      installments:1,
      minOrderTotal:20,
      description:"A un solo cargo"

    },
    {
      installments:3,
      minOrderTotal:40,
      description:"A 3 Meses sin intereses"
    },
    {
      installments:6,
      minOrderTotal:60,
      description:"A 6 Meses sin intereses"
    },
  ];
  const mpPublicKey = "APP_USR-70595856-e598-4c64-a6aa-fb9ea11c70db"
  return { 
    mySite,
    storeDisplayName,
    installments,
    mpPublicKey
   };
};


export { useSite };
