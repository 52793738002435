/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
 export const SET_SESSION = "SET_SESSION_BANAMEX";
 export const SET_IS_CARD_SAVED = "SET_IS_CARD_SAVED"; 
 export const PAY_WITH_CARD_SAVED = "PAY_WITH_CARD_SAVED"; 
 export const SET_TRANSACTIONID = "SET_TRANSACTIONID"; 
 export const SET_IS_BTN_PRESSED = "SET_IS_BTN_PRESSED"; 
