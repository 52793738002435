/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { useState } from "react";
import styled from "styled-components";
import { Card, makeStyles,Divider } from "@material-ui/core";
import { StyledButton } from "../button";
import { StyledLink } from "../link";
import { StyledGrid } from "../grid";
import { StyledTypography } from "../typography";
import { StyledCardActions } from "./styled-card-actions";
import { StyledCardContent } from "./styled-card-content";


const StyledDPButton = styled.button.attrs((props: {bgColor: string, hoverDisabled?:boolean}) => props)`
            background-color: #${(props) => props.bgColor};
            color: white;
            font-size: 15px;
            font-family: Fjalla One,sans-serif;
            font-weight: 400;
            border: none;
            padding: 0px 10px;
            width: auto;
            height: 40px;
            cursor:${(props) => props.disabled?"cursor":"pointer"};
            
            &:hover{
              background-color: #${(props) => props.hoverDisabled?props.bgColor:`1565c0`};
            }
        `;

const StyledCardWrapper = styled(({ ...props }) => <Card {...props} />)`
  
  ${({ theme }) => `
  background:transparent;
    border: none;
    box-shadow:none;
    width:100%;

    &.product-card {
      border-radius: 0px;
      .MuiTypography-body2 {
        width: 66%;
        margin: 0 auto;
      }
    }

    &.address-card {
      position: relative;
      border: 1px solid  ${theme.palette.divider};

      a {
        font-weight: 400;
      }
    }

    &.selected {
      border-radius: ${theme.shape.borderRadius}px;
      border: 2px solid ${theme.palette.primary.main};
    }

    &.checkoutAddressCard{
      border:none;
      margin:0;
    }
  `}
`;

const StyledConfirmOverlay = styled.div`
  ${({ theme }) => `
      background-color: ${theme.palette.background.paper}${theme.palette.background.transparency};
      position: absolute;
      bottom:0;
      height: 70%;
      width: 100%;
  `}
`;

interface StyledParameterizedCardProps {
  headerProps?: any;
  contentComponent?: any;
  cardActions?: any;
  confirmLabel?: string;
  cancelLabel?: string;
  className?: string;
  cardFooter?: any;
}

const linkStyles = makeStyles((theme) => ({
  linkContainer:{
    color: "#1565c0",
    fontSize: "13px",
    fontWeight: 500,
    fontFamily:"Roboto"
  }
  
}));



const StyledCard: React.FC<StyledParameterizedCardProps> = (props: any) => {
  const {
    headerProps,
    contentComponent,
    cardActions,
    cardFooter,
    confirmLabel,
    cancelLabel,
    className,
  } = props;
  const [confirmState, setConfirmState] = useState<boolean>(false);
  const [confirmActionIndex, setConfirmActionIndex] = useState<number>(0);
  const classes = linkStyles();
  const toggleConfirm = (index: number) => {
    setConfirmState(!confirmState);
    setConfirmActionIndex(index);
  };

  return (
    <StyledCardWrapper className={className + " full-height miclase"}>
      <StyledGrid
        container
        direction="column"
        alignItems="stretch"
        className="full-height">
        {headerProps && (
          <>
            <StyledGrid item xs={false} className="full-width">
              <StyledCardContent className="horizontal-padding-2 vertical-padding-2">
                {headerProps}
              </StyledCardContent>
            </StyledGrid>
            {(contentComponent || cardActions?.length > 0) && <Divider />}
          </>
        )}

        {contentComponent && (
          <>
            <StyledGrid item xs>
              <StyledCardContent className="horizontal-padding-0 vertical-padding-0">
                {contentComponent}
              </StyledCardContent>
            </StyledGrid>
            {cardActions?.length > 0 && <Divider />}
          </>
        )}

        {cardActions?.length > 0 && (
          <StyledGrid item xs={false} >
            <StyledCardActions className="horizontal-padding-2 vertical-padding-2">
              {cardActions.map((v: any, index: number) =>
                v.link ? (
                  <StyledLink to={v.link} key={v.text + "_" + index}>
                    {v.text}
                  </StyledLink>
                ) : (
                  v.custom ?(
                    <StyledDPButton
                        bgColor={"ff4364"}
                        onClick={() => v.handleClick()}
                        >
                        {v.text}
                    </StyledDPButton>
                  ):(
                    <StyledButton
                      variant="text"
                      key={v.text + "_" + index}
                      onClick={() => {
                        if (v.enableConfirmation) {
                          toggleConfirm(index);
                        } else {
                          v.handleClick();
                        }
                      }}>
                      <StyledTypography variant="body2" className={classes.linkContainer}>
                        {v.text}
                      </StyledTypography>
                    </StyledButton>
                  )
                  
                )
              )}
            </StyledCardActions>

            {confirmState && (
              <StyledConfirmOverlay>
                <StyledGrid
                  container
                  direction="column"
                  justify="flex-end"
                  className="full-height horizontal-padding-2"
                  spacing={2}>
                  <StyledGrid item>
                    <StyledButton
                      className="confirm-action-button"
                      variant="outlined"
                      fullWidth
                      onClick={cardActions[confirmActionIndex]?.handleClick}>
                      {confirmLabel}
                    </StyledButton>
                  </StyledGrid>
                  <StyledGrid item>
                    <StyledButton
                      className="cancel-action-button"
                      variant="outlined"
                      fullWidth
                      onClick={() => toggleConfirm(0)}>
                      {cancelLabel}
                    </StyledButton>
                  </StyledGrid>
                </StyledGrid>
              </StyledConfirmOverlay>
            )}
          </StyledGrid>
        )}

        {cardFooter && (
          <StyledGrid item xs={false}>
            {cardFooter}
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledCardWrapper>
  );
};

export { StyledCard };
