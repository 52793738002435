/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const BreadcrumbWrapper = (props: any) => (
  <Breadcrumbs {...props} separator={<FiberManualRecordIcon fontSize="small" style={{fontSize:"4px", color:"#009894"}} />} />
);

export const StyledBreadcrumbs = styled(BreadcrumbWrapper)`
  ${({ theme }) => `
    margin: ${theme.spacing(3)}px 0px ${theme.spacing(1)}px;
    margin: 20px 5px 5px;

    a {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      color: #009894;
      line-height:14px;
      font-size:12px;
    }

    span {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      line-height:14px;
      font-size:12px;
    }

    @media only screen and (max-width: 600px) {
        margin: ${theme.spacing(3)}px 0px ${theme.spacing(1)}px;
        margin: 20px 0px 5px;
    }
  `}
`;
