/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledSwatch } from "../card";
import { StyledRadio, StyledRadioGroup } from "../radio";
import { StyledFormControl, StyledFormControlLabel } from "../form";

function RadioGroupWrapper({
  id,
  name,
  onChangeHandler,
  values,
  useSwatches,
  className,
  productDetailsAny,
  ...props
}: any) {
  let defaultSelected: string = "";
  let currentSelectedAttributes: any;
  let attributeChangeMap: Map<any, any>;
  if (props.isB2B) {
    attributeChangeMap = props.attributeState
      ? props.attributeState
      : new Map();
    defaultSelected = attributeChangeMap.get(id)
      ? attributeChangeMap.get(id)
      : productDetailsAny;
  } else {
    currentSelectedAttributes = props.currentSelection?.selectedAttributes
      ? props.currentSelection.selectedAttributes
      : {};
    defaultSelected = currentSelectedAttributes[id];
    if (defaultSelected) {
      if (!Array.isArray(defaultSelected)) {
        defaultSelected = currentSelectedAttributes[id];
      } else {
        defaultSelected = currentSelectedAttributes[id][0];
      }
    } else {
      defaultSelected = "";
    }
  }
  const [value, setValue] = React.useState(defaultSelected);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChangeHandler(id, event.target.value);
  };

  const handleClick = (optionValue: string) => {
    setValue(optionValue);
    onChangeHandler(id, optionValue);
  };

  return (
    <StyledFormControl
      component="fieldset"
      className={useSwatches ? `${className} swatch-group` : className}>
      <StyledRadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}>
        {props.isB2B && (
          <StyledFormControlLabel
            value={productDetailsAny}
            control={<StyledRadio />}
            label={productDetailsAny}
          />
        )}
        {values.map((v: any, index: number) =>
          useSwatches ? (
            <StyledSwatch
              style={{
                backgroundImage: `url("${v.image1path}")`,
              }}
              onClick={() => handleClick(v.identifier)}
              selected={defaultSelected === v.identifier}
              size="medium"
              key={v.identifier}
            />
          ) : (
            <StyledFormControlLabel
              key={v.identifier}
              value={v.identifier}
              control={<StyledRadio />}
              label={v.value}
              disabled={
                props.isB2B || Array.isArray(currentSelectedAttributes[id])
                  ? false
                  : currentSelectedAttributes[id] !== v.identifier
              }
            />
          )
        )}
      </StyledRadioGroup>
    </StyledFormControl>
  );
}

RadioGroupWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  useSwatches: PropTypes.bool,
};

const StyledSwatchRadioGroup = styled(({ ...props }) => (
  <RadioGroupWrapper {...props} />
))`
  ${({ theme }) => `
    &.swatch-group .MuiFormGroup-root {
      flex-direction: row;
    }

    .MuiButtonBase-root {
      padding-top: ${theme.spacing(0.5)}px;
      padding-button: ${theme.spacing(0.5)}px;
    }
  `}
`;

export { StyledSwatchRadioGroup };
