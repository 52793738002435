/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import React, { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
//Custom libraries
import styled from "styled-components";
import { StyledBox } from "../box";
import { StyledProgressPlaceholder } from "../circular-progress";
import { StyledTypography } from "../typography";
import { CustomStyledCardMedia, StyledCard } from "../card";

import useProductCardWidget, {
  ProductContext,
} from "../../widgets/product-card/ProductCardWidget";
import { makeStyles } from "@material-ui/core";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import QuickInfoDetailsWidget from "../../custom-widgets/QuickInfo/QuickInfoDetailsWidget";
import { InfoElectronico } from "../../commerce-widgets/product-information/ProductoElectronico";

// import icon from "../../custom-widgets/GalleryProduct/icono-producto-ganador.png"

const StyledProductCard = styled(({ className, ...props }) =>
  props.to ? (
    <Link className={className} {...props} />
  ) : (
    <StyledBox className={className} {...props} />
  )
)`
  margin: 0px;

  ${({ theme }) => `
    display: block;
    height: 100%;
    min-height:177px;
    width:100%;

    &.product-grid {
      .MuiCard-root {
        height: 100%;
      }
    }
  `}
`;

interface CardTextProps {
  fontSize?: any;
  margin?: any;
  color?: any;
}

const StyledProductCards = styled.div<CardTextProps>`
  a {
    display: block;
    color: ${(p) => p.color};
    font-size: ${(p) => p.fontSize};
    line-height: 16px;
    margin: ${(p) => p.margin};
    overflow: hidden;
    font-weight: 500;
    text-align: left;
    height: 34px;
    font-family: Roboto, HelveticaNeue-Light;
    width: 236px;
    min-height: auto;
    @media only screen and (max-width: 1180px) {
      width: calc(100% - 20px);
    }
  }
`;
/*
const StyledPreviewOverlay = styled.div
  `
    display:flex;
    flex-direction:row;
    height:100%;
  `
;
*/
function OverlayMenuItem(props) {
  const handler = useContext(ProductContext);
  const [productCode, setproductCode] = useState("");
  const [action, setAction] = useState(0);
  const callbackToChild = useRef<any>(null);
  const img = props?.img;
  const openProductCardWidged = (partNumber) => {
    if (props.isTop) {
      if (partNumber !== productCode) {
        setproductCode(partNumber);
        setAction(action + 1);
      } else {
        if (callbackToChild !== null) {
          callbackToChild.current();
        }
      }
    } else {
      handler(partNumber);
    }
  };
  return (
    <>
      <div className={props.className}>
        {img ? (
          <img src={img} />
        ) : props.noLink ? (
          <div onClick={() => openProductCardWidged(props.partNumber)}>
            {" "}
            {props.title}
          </div>
        ) : (
          <a href={props.seoUrl}>{props.title}</a>
        )}
      </div>
      <QuickInfoDetailsWidget
        productNumber={productCode}
        displayQuickInfo={action}
        callbackToParent={callbackToChild}
      />
    </>
  );
}
const StyledOverlayMenuItem = styled(({ ...props }) => (
  <OverlayMenuItem {...props} />
))`
    background:rgba(0,0,0,0.6);
    font-weight 300;

    &:hover{
      background:rgba(0,0,0,1);
    }

  `;
function ProductCardOverlay(props) {
  return (
    <div className={props.className}>
      <StyledOverlayMenuItem
        className="productPreview"
        noLink
        title="VISTA PREVIA"
        partNumber={props.partNumber}
        isTop={props.isTop}
      />
      <StyledOverlayMenuItem
        className="productDetails"
        href="/detalles"
        title="DETALLES"
        seoUrl={props.seoUrl}
      />
    </div>
  );
}

const StyledProductCardOverlay = styled(({ ...props }) => (
  <ProductCardOverlay {...props} />
))`
  opacity: 0%;
  position: relative;
  bottom: calc(-100% + 35px);
  width: 100%;
  height: 35px;
  font-size: 12px;
  text-align: center;

  a,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.productCardOverlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .productPreview,
  .productDetails {
    border: solid;
    border-color: white;
    height: 100%;
    width: 50%;
  }

  .productPreview {
    border-width: 0px;
  }
  .productDetails {
    border-width: 0px 0px 0px 1px;
  }

  @media only screen and (max-width: 940px) {
    div {
      display: none;
    }
  }
`;
// dos
function ProductCardNew(props) {
  var countofert = 0;
  const validateRebbonsType = (content, identifier, counterOfert = 1) => {
    if (content === 1) {
      switch (identifier) {
        case "DPD_Oferta":
          return "onlyDpdOferta";
        case "Nuevo":
          return "onlyProductNew";
        default:
          return "onlyProductDiscount";
      }
    } else {
      if (countofert === 1) {
        switch (identifier) {
          case "Nuevo":
            return "productNewUp";
          case "DPD_Oferta":
            return "dpdOfertaUp";
          default:
            return "ribbonDefaultUp";
        }
      } else {
        switch (identifier) {
          case "Nuevo":
            return "productNewDown";
          case "DPD_Oferta":
            return "dpdOfertaDown";
          default:
            return "ribbonDefaultDown";
        }
      }
    }
  };
  return (
    <div className={props.className}>
      {props.content.map((attribute: any, index: number) => {
        if (attribute.storeDisplay && countofert < 2) {
          if (props.content.length === 1) {
            return (
              <>
                <StyledOverlayMenuItem
                  className={validateRebbonsType(
                    props.content.length,
                    attribute.identifier,
                    countofert
                  )}
                  noLink
                  title={
                    props.ofer && attribute.identifier !== "Nuevo"
                      ? attribute.values[0]["value"]
                      : attribute.name
                  }
                />
              </>
            );
          } else {
            countofert++;
            return (
              <StyledOverlayMenuItem
                className={validateRebbonsType(
                  props.content.length,
                  attribute.identifier,
                  countofert
                )}
                noLink
                title={
                  props.ofer && attribute.identifier !== "Nuevo"
                    ? attribute.values[0]["value"]
                    : attribute.name
                }
              />
            );
          }
        }
      })}
    </div>
  );
}
// uno
const StyledProductCardNew = styled(({ ...props }) => (
  <ProductCardNew {...props} />
))`
  position: relative;
  bottom: calc(-100% + 255px);
  width: 100%;
  height: 35px;
  font-size: 12px;
  text-align: center;

  a,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.productCardOverlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .productNewUp {
    height: 50%;
    width: 40%;
    background: rgba(129, 89, 147, 1);
    border-radius: 0px 15px 0px 0px;
  }
  .productNewDown {
    height: 50%;
    width: 40%;
    background: rgba(129, 89, 147, 1);
    border-radius: 0px 0px 15px 0px;
  }
  .ribbonDown {
    height: 50%;
    width: 40%;
    background: green;
    border-radius: 0px 0px 15px 0px;
  }
  .ribbonDefaultUp {
    height: 50%;
    width: 40%;
    background: rgb(241, 140, 0);
    border-radius: 0px 15px 0px 0px;
  }
  .ribbonDefaultDown {
    height: 50%;
    width: 40%;
    background: rgb(241, 140, 0);
    border-radius: 0px 0px 15px 0px;
  }
  .onlyProductNew {
    height: 80%;
    width: 40%;
    background: rgba(129, 89, 147, 1);
    border-radius: 0px 15px 15px 0px;
  }
  .productDiscount1 {
    height: 50%;
    width: 40%;
    background: rgb(241, 140, 0);
    border-radius: 0px 15px 0px 0px;
  }
  .onlyProductDiscount {
    height: 80%;
    width: 40%;
    background: rgb(241, 140, 0);
    border-radius: 0px 15px 15px 0px;
  }

  .dpdOfertaUp {
    height: 50%;
    width: 40%;
    background: #bf0000;
    border-radius: 0px 15px 0px 0px;
  }
  .dpdOfertaDown {
    height: 50%;
    width: 40%;
    background: #bf0000;
    border-radius: 0px 0px 15px 0px;
  }

  .onlyDpdOferta {
    height: 80%;
    width: 40%;
    background: #bf0000;
    border-radius: 0px 15px 15px 0px;
  }
  .productDiscount2 {
    height: 50%;
    width: 40%;
    background: rgb(241, 140, 0);
    border-radius: 0px 0px 15px 0px;
  }

  @media (max-width: 667px) and (orientation: portrait) {
    bottom: calc(-100% + 230px);
    // left: calc( -100% + 164.5px);
  }

  @media (max-width: 667px) and (orientation: landscape) {
    bottom: calc(-100% + 230px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: portrait) {
    bottom: calc(-100% + 230px);
    // left: calc( -100% + 194.5px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: landscape) {
    bottom: calc(-100% + 245px);
  }
`;
function ProductoGanador(props) {
  return (
    <div className={props.className} style={{ right: 5 }}>
      {props.content.length > 0 ? (
        <>
          <div style={{ justifyContent: "right" }}>
            <StyledOverlayMenuItem
              className={"productoGanador"}
              img={
                "https://dpjye2wk9gi5z.cloudfront.net/wcsstore/Dpdirecto/images/insignia-dpd-150x150.png"
              }
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

const StyledProductoGanador = styled(({ ...props }) => (
  <ProductoGanador {...props} />
))`
  position: relative;
  bottom: calc(-100% + 290px);
  width: 100%;
  height: 35px;
  font-size: 12px;
  text-align: center;

  a,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .productoGanador {
    height: 80%;
    margin-top: -5px !important;
    width: 20%;
    background: transparent;
  }

  @media (max-width: 667px) and (orientation: portrait) {
    bottom: calc(-100% + 270px);
    // left: calc( -100% + 164.5px);
  }

  @media (max-width: 667px) and (orientation: landscape) {
    bottom: calc(-100% + 270px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: portrait) {
    bottom: calc(-100% + 280px);
    // left: calc( -100% + 194.5px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: landscape) {
    bottom: calc(-100% + 265px);
  }
`;

function TallasDisponibles(props) {
  return (
    <>
      {props?.content.map((attribute: any, index: number) => {
        if (attribute.identifier === "DPD_TallasDisponibles") {
          return (
            <>
              <StyledOverlayMenuItem
                className={props.className}
                title={attribute.values[0].value}
              />
            </>
          );
        }
      })}
    </>
  );
}
const StyledTallasDisponibles = styled(({ ...props }) => (
  <TallasDisponibles {...props} />
))`
  background: none;
  padding-top: 10px;
  width: 100%;
  font-size: 12px;
  text-decoration: underline;
  margin-bottom: -2px;
  :hover {
    background: none;
  }
`;

const linkStyles = makeStyles((theme) => ({
  displayNone: {
    display: "none",
  },
  price: {
    color: "rgb(102, 102, 102)",
    display: "block",
    fontFamily: 'Roboto, "Open Sans", "Helvetica Neue", Arial',
    fontSize: "18px",
    fontWeight: 500,
  },
  priceNew: {
    color: "rgb(102, 102, 102)",
    display: "block",
    fontFamily:
      'Roboto,"HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif',
    fontSize: "18px",
    fontWeight: 800,
    textDecoration: "line-through",
    fontStyle: "italic",
  },
  priceDiscount: {
    color: "red",
    display: "block",
    fontFamily: 'Roboto, "Open Sans", "Helvetica Neue", Arial',
    fontSize: "18px",
    fontWeight: 500,
  },
  nombreProducto: {
    fontFamily: "Roboto, HelveticaNeue-Light",
    color: "rgb(51, 51, 51)",
    fontSize: "14px",
    lineHeight: "16px",
    margin: "5px 0px 0px",
  },
  floatCenter: {
    float: "initial",
  },
  floatLeft: {
    float: "left",
  },
}));

interface ProductCardProps {
  partNumber?: any;
  seoUrl: any;
  name: any;
  thumbnail: any;
  thumbnailLoading?: boolean;
  price?: number | null;
  swatches: any[];
  catentryId?: string;
  onClick?: any;
  actions?: any;
  className?: any;
  categoryId?: string;
  formattedPriceDisplay: any;
  priceProduct?: any;
  productAttributes?: any;
  isTop?: boolean;
  bannerRecommendation?: boolean;
  infoElectronico?: InfoElectronico;
}

/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */
function ProductCard(props: ProductCardProps) {
  const [ofer, setOfer] = useState(false);
  const partNumber: string = props.partNumber;
  const seoUrl: any = props.seoUrl;
  const name: string = props.name;
  const thumbnail: string = props.thumbnail;
  const categoryId: string = props.categoryId ? props.categoryId : "";
  const thumbnailLoading: boolean = props.thumbnailLoading
    ? props.thumbnailLoading
    : false;
  const price: number | null = props.price ? props.price : null;
  const swatches: any[] = props.swatches;
  const catentryId: string = props.catentryId ? props.catentryId : "";
  const onClickAction: any = props.onClick ? { onClick: props.onClick } : {};
  const actions: any | null = props.actions ? props.actions : null;
  const formattedPriceDisplay = props.formattedPriceDisplay;
  const priceProduct = props.priceProduct ? props.priceProduct : null;
  const productAttributes = props.productAttributes;
  const isTop = props.isTop ? props.isTop : false;
  const bannerRecommendation = props.bannerRecommendation;
  //Electronicos
  const infoElectronico = props.infoElectronico
  const isElectronico = infoElectronico?.isElectronico
  const hasOfferDeContado = infoElectronico?.hasOfferDeContado

  var atributosValidos = [] as any;
  var productoGanador = [] as any;
  var tallasDisponibles = [] as any;

  productAttributes &&
    productAttributes.map((attribute: any, index: number) => {
      if (attribute.storeDisplay === "true") {
        if (
          !attribute.name.includes("DPT_") &&
          !attribute.name.includes("DPS_")
        ) {
          atributosValidos.push(attribute);
        }
      } else if (attribute.name === "DPD_Producto Ganador") {
        productoGanador.push(attribute);
      } else if (attribute.identifier === "DPD_TallasDisponibles") {
        console.log(attribute.name, "tallas disponibels");
        tallasDisponibles.push(attribute);
      }
    });

  useEffect(() => {
    if(isElectronico){
      if(hasOfferDeContado){
        setOfer(true)
      }
      else{
        setOfer(false)
      }
    }
    
    else{
      if (priceProduct.length === 1) {
        if (priceProduct[0]["usage"] != undefined) {
          setOfer(false);
        }
      } else {
        if (
          priceProduct[1]["usage"] != undefined &&
          priceProduct[1]["usage"] == "Offer" &&
          priceProduct[0]["usage"] != undefined &&
          parseFloat(priceProduct[0]["value"]) >
            parseFloat(priceProduct[1]["value"])
        ) {
          setOfer(true);
        }
      }
    }
  }, [priceProduct]);

  const classes = linkStyles();

  const contentComponent = (
    <>
      {thumbnailLoading ? (
        <StyledProgressPlaceholder className="vertical-padding-8" />
      ) : (
        <LazyLoadComponent>
          <CustomStyledCardMedia
            className=""
            image={thumbnail}
            title={name}
            component="div"
            to={{
              pathname: seoUrl,
              state: { categoryId: categoryId },
            }}>
            <StyledProductCardOverlay
              className="productCardOverlay"
              partNumber={partNumber}
              seoUrl={seoUrl}
              isTop={props.isTop}
            />
            <StyledProductCardNew ofer={true} content={atributosValidos} />
            <StyledProductoGanador content={productoGanador} />
          </CustomStyledCardMedia>
        </LazyLoadComponent>
      )}
      <StyledTallasDisponibles content={tallasDisponibles} />
      <StyledGrid
        fontSize="14px"
        color="#333"
        margin="10px 0px 0px"
        className={"wrapText " + classes.nombreProducto}
        style={{ marginTop: "15px" }}>
        <Link
          to={{
            pathname: seoUrl,
            state: { categoryId: categoryId },
          }}
          style={{ color: "rgb(51, 51, 51)" }}>
          <h3
            style={{
              fontSize: "14px",
              fontWeight: "unset",
              lineHeight: "unset",
            }}>
            {name}
          </h3>
        </Link>
      </StyledGrid>
      {swatches.length > 0 && <StyledTypography>{swatches}</StyledTypography>}
      {price && (
        <StyledProductCards
          fontSize="18px"
          color="#666"
          style={{ marginTop: "10px" }}>
          {ofer ? (
            <>
              <div className={classes.priceDiscount}>
                <div
                  className={
                    bannerRecommendation
                      ? classes.floatCenter
                      : classes.floatLeft
                  }>
                  {formattedPriceDisplay}
                </div>
                {!bannerRecommendation && <span>&nbsp;</span>}{" "}
              </div>
            </>
          ) : isTop ? (
            <div className={classes.price}>
              <div>{formattedPriceDisplay}</div>
            </div>
          ) : (
            <div className={classes.price}>
              <div style={{ float: "left" }}>{formattedPriceDisplay}</div>
              &nbsp;MXN
            </div>
          )}
        </StyledProductCards>
      )}
    </>
  );

  return (
    <StyledProductCard
      className="product-card"
      id={catentryId ? `productCard_a_1_${catentryId}` : ""}
      {...onClickAction}>
      <StyledCard
        className="product-card otraClase"
        contentComponent={contentComponent}
        cardActions={actions}
      />
    </StyledProductCard>
  );
}
export default ProductCard;
