import styled from "styled-components";

export const StyledLinkQI = styled.a`
    &:hover{
        text-decoration: underline;
        color:#01b0ab;
    }
`

export const StyledSelect = styled.select`
  background-color: #eaeaea;
  cursor: pointer;
  border: none;
  background-image: url(https://www.dpdirecto.mx/wcsstore/Dpcatalogo/images/flecha-select.png) !important;
  background-repeat: no-repeat !important;
  background-position: right -10px top -8px !important;
  margin-top: 20px;
  margin-right: 10px;
  width: 80%;
  height: 38px;
  padding: 5px 10px;
  -webkit-appearance: none;
  @media only screen and (max-width: 900px) {
    margin-right: 20px;
  }
`;

export const StyledInput = styled.input`
  margin-top: 20px;
  width: 69px;
  height: 38px;
  padding-left: 20px;
  border:1px solid #bfbfbf;
  border-radius: 0px;
  outline:0;
  cursor: text;
  &:focus{
    border:1px solid #01b0ab;
  }
  @media only screen and (max-width: 900px) {
    padding-left: 5px;
    width: 20%;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSpacer = styled.div.attrs(
  (props: { height: string }) => props
)`
  min-height: ${(props) => props.height};
  //min-width:100%;
`;

export const StyledCloseQuickInfo = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 28px;
  cursor: pointer;
`;

export const StyledContainerProgress = styled.div`
  background-color: black;
  height: 100vh;
  width: 100%;
  z-index: 100;
`

export const StyledLoadingContainer = styled.div`
  /*loaging div*/

  &.hidden {
    display: none;
  }

  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 45%;
  z-index: 100;
  width: 50px;
  min-height: 50px;
  display: block;
  @media only screen and (max-width: 900px) {
    position:fixed;
    top:31%;
  }

`;

export const StyledQuickInfoContainer = styled.div`

    &.hidden{
        display:none;
    }
    @media only screen and (max-width: 900px) {
        position:fixed;
      }
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:100%;
    min-height:145vh;
    z-index:100;
    
    
    background: rgba(0,0,0,.2);
    font-family: Roboto;
    font-weight: 400;

    //product gallery

    #quickInfoContainer{
        @media only screen and (max-width: 900px) {
            position:fixed;
            top:31%;
            padding:15px 15px;
            border-width: 2px;
            width: 90%;
            left: 20px;
          }
        position:absolute;
        display: flex;
        top:10%;
        left: calc(50% - 466px);
        //width:932px;
        max-width: 932px;
        min-height:604px;
        flex-direction: row;
        flex-wrap:wrap;
        background-color: white;
        padding:5px 15px 15px;
        margin:0 auto;
        border-style: solid;
        border-width: 1px;
        border-color:black;
        &.hidden{
            display:none;
        }
        .containerElectronico{
          width: 100%;
        }
        .quickInfoGalleryDisplay{
            max-width: calc(50% - 10px);
            .productImage img{
                width: 397px;
                height: 397px;
                object-fit: contain;
                margin-right: 36px;
                margin-left: 30px;
                @media only screen and (max-width: 900px) {
                    margin-right: 0px;
                    margin-left: 0px;
                    margin-bottom: 10px;
                }
            }
            
            .productCarouselContainer{
                text-align: center;
            }
            .productCarousel{
                
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-left: 40px;
                @media only screen and (max-width: 900px) {
                    margin-left: 20px !important;
                }
                img{
                    width: 64px;
                    height: 64px;
                    margin: 10px 5px;
                    object-fit: contain;
                    &:hover{
                        border-style: solid;
                        border-color: lightseagreen;
                        border-width: 1px;
                    }
                }
            }
            .img-style{
                width: 64px;
                height: 64px;
                margin: 10px 5px;
                object-fit: contain;
                &:hover{
                    cursor: pointer;
                    border-style: solid;
                    border-color: lightseagreen;
                    border-width: 2px;
                }
            }
            .img-selected{
                border-style: solid;
                border-color: lightseagreen;
                border-width: 1px;
                width: 64px;
                height: 64px;
                margin: 10px 5px;
                object-fit: contain;
                &:hover{
                    cursor: pointer;
                    border-style: solid;
                    border-color: lightseagreen;
                    border-width: 2px;
                }
            }
           
        }
    }
    //product content
    .quickInfoProductContent{
        width: 50%;
        @media only screen and (max-width: 900px) {
            margin-left: 10px;
            padding-left: 0px;
          }
        padding-left: 20px;
        
        .productPartNumber{
            color:#333;
            font-weight: 400;
        }
        .productSeparator{
            height: 1px;
            width: 100%;
            background-color:#e9e9e9;
        }
        .productTitle{
            font-size: 22px;
            font-weight: 400;
            margin:10px 0px 5px;
            line-height: 26px;
            
        }
        .productPrice{
            font-size: 22px;
            font-weight: 400;
            color:#009894;
            font-family: Roboto;
            margin: 10px 0px 10px;
        }
        .productWarranty,.productCode,.productMoreInfo{
            font-size: 14px;
            color:#333;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .productWarranty,.productMoreInfo{
            @media only screen and (max-width: 900px) {
                margin:20px 0px 10px;
            }
            margin:10px 0px 10px;
        }
        .productDetails{
            font-size: 12px;
            .productPartNumber{
                
                margin-bottom:10px;
                font-size: 14px;
                @media only screen and (max-width: 900px) {
                    margin-bottom:0px;
                }
                
            }
            .productDescription{
                color:#777;
            }
        }
        .noInventoryItem{
            margin-top:20px;
            width: 100% !important;
            padding-bottom: 20px;
            border-bottom: 1px solid #e9e9e9;
        }
        .quickInfoCartContentElec{
          width:90%;
        }
        .quickInfoCartContent{
            width:285px;
            .productSizes{
                .productSizeTitle{
                    margin:5px 0px;
                }
                .sizeButton{
                    border-style: solid;
                    border-width: 1px;
                    width:53px;
                    height: 41px;
                    margin-right: 10px;
                    margin-bottom: 15px;
                    background-color: white;
                    color:black;
                    &:hover{
                        background: #000;
                        color:white;
                    }
                }
                .sizeButton.selected{
                    background: #012966;
                    color:white;
                }
            }
        }
        
        
        
    }


}

    a{
        color:#009894;
    }
`;
export const StyledAddToCardButton = styled.button`
  background-color: #ff4364;
  color: white;
  font-size: 16px;
  border: none;
  margin-top: 10px;
  width: 100%;
  height: 55px;
  padding: 5px 10px;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    margin-top: 20px;
}
`;
