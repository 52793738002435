/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
 export const SET_NOMBRE_COMPONENTE = "SET_NOMBRE_COMPONENTE";
 export const SET_NOMBRE_COMPONENTE_SUCCESS = "SET_NOMBRE_COMPONENTE_SUCCESS";
