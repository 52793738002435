/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";
//import { dpColor } from "../../themes/variables";
import { Link } from "react-router-dom";
import noImageProduct from "../../../img/noImage.png";

//const thumbnailSize = dimensions.productCard.thumbnail;

const StyledCardMedia = styled(({ ...props }) => <CardMedia {...props} />)`
  width: 100%;
  height: 236px;
  margin: 0px 0px 0px 0px;
  background-size: contain;
`;

function CustomCardMedia({ className, ...props }) {
  function imageExists(url) {
    var http = new XMLHttpRequest();

    http.open("HEAD", url, false);
    http.send();

    return http.status;
  }
  const CardInfo = { ...props };
  const url = `/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${CardInfo[
    "children"
  ][0].props.partNumber.substring(2)}V1.jpg`;
  const exist = 200;
  if (exist === 200 && CardInfo["children"][0].props.partNumber) {
    CardInfo["image"] =
      window.urlBaseImg +
      `/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${CardInfo[
        "children"
      ][0].props.partNumber.substring(2)}V1.jpg`;
  } else {
    CardInfo["image"] = noImageProduct;
  }
  //console.log("carinfo => ", CardInfo);

  return (
    <div className={className}>
      <CardMedia className="productThumbnail" {...CardInfo}></CardMedia>
      <Link className={"linkToProduct"} to={props.to} />
    </div>
  );
}

const CustomStyledCardMedia = styled(({ ...props }) => (
  <CustomCardMedia {...props} />
))`
  display: block;
  height: 236.66px;
  width:236.66px;
  max-height: 236.66px;
  max-width:236.66px;
  margin: 0px 0px 0px 0px;
  overflow: hidden;

  .productThumbnail {
    height: 100%;
    width: 100%;
    background-size: contain;
    @media screen and (max-width: 600px){
      background-size: 182px;
    }
  }
  }

  position: relative;
  .linkToProduct {
    display: block;
    width: 100%;
    height: calc(100% - 37px);
    position: absolute;
    top: 0;
  }
  &:hover .productCardOverlay {
    opacity: 100%;
  }
  @media only screen and (max-width: 940px) {
    height: 236px;
    width:100%;
    max-height: 236px;
    max-width:236px;
    .linkToProduct {
    height: 100%;
  }
  a{
    min-height:auto;
  }
  
  @media only screen and (max-width: 940px) {
    height: 250px;
    .productThumbnail {
      //width: 90%;
  }
  }
`;

export { StyledCardMedia, CustomStyledCardMedia };
