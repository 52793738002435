import React from "react";

import { makeStyles, Divider, Tooltip, withStyles, Theme } from "@material-ui/core";

import { SvgIcon } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { useSite } from "../../../_foundation/hooks/useSite";

export interface InfoElectronico {
  isElectronico: any;
  hasOfferDeContado: any;
  prices: PricesElectronicos;
}

export interface PricesElectronicos {
  offerDpVale: any;
  offerDeContado: any;
  strikedDeContado: any;
}

export const DEFAULT_PRICES_ELECTRONICOS: PricesElectronicos = {
  offerDpVale: undefined,
  offerDeContado: undefined,
  strikedDeContado: undefined
};

export const DEFAULT_INFO_ELECTRONICO: InfoElectronico = {
  isElectronico: undefined,
  hasOfferDeContado: undefined,
  prices: DEFAULT_PRICES_ELECTRONICOS
};


const styles = makeStyles((theme) => ({
  dpValeElecContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "37% 4% 48%",
    marginTop: "15px",
    gap: "5px",
    marginBottom: "-20px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "48% 1% 48%",
      gap: "15px",
    },
    "@media (max-width: 600px)": {
      marginTop: "25px !important"
    }
  },
  dpValePrice: {
    color: "#000",
    fontStyle: "italic",
    fontSize: "17px",
    fontWeight: 600,
    fontFamily: "Roboto, italic",
    display: "grid",
    textTransform: "none",
    "& .dp-vale-info": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      "& > svg": {
        fill: "#8f8f8f",
        width: "16px",
        height: "16px",
      },
      "& > img": {
        width: "72px",
        height: "24px",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        width: "1px", // Ancho del borde
        backgroundColor: "#E2E2E2", // Color del borde
      },

    },
    "& .dp-vale-quincenas": {
      color: "#B40309",
      fontSize: "18px",
      fontWeight: 700,
      fontStyle: "normal",
      marginTop: "10px"
    },
    "& .dp-vale-precio-final": {
      fontStyle: "normal",
      fontSize: "12px",
      fontWeight: 400,

    }

  },
  dpValeContado: {
    color: "#000",
    fontStyle: "italic",
    fontSize: "17px",
    fontWeight: 600,
    fontFamily: "Roboto, italic",
    display: "grid",
    textTransform: "none",
    "& .dp-vale-contado-container": { 
      display: "inline-block"
    },
    "& .dp-vale-contado": {
      fontSize: "18px",
      fontWeight: "bold",
      fontStyle: "normal",
      "&.offer": {
        color: "#B40309",
      },
    },
    "& .dp-vale-contado-discount": {
      fontSize: "18px",
      fontWeight: "bold",
      fontStyle: "normal",
      color: "#929292",
      marginLeft: "5px"
    },
    "& .dp-vale-pago": {
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 400,
    }
  },
}));
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#cfcfcf',
    color: '#000',
    maxWidth: 290,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    fontWeight: 500,
    boxShadow: '1px 1px 1px 1px #ddd',
  },
}))(Tooltip);



type ProductoElectronicoProps = {
  prices: PricesElectronicos;
};
const ProductoElectronico: React.FC<ProductoElectronicoProps> = (props) => {
  const {offerDpVale, offerDeContado, strikedDeContado} = props.prices;
  const classes = styles();
  const { mySite } = useSite();  

  const rangoQuincenas = mySite?.storeCfg?.userData?.rango_quincenas_dpvale;

  const hasOfferDeContado = strikedDeContado !== undefined;

  //Calcula las quincenas para las que aplica el producto
  let quincenas = 1;
  if(rangoQuincenas){
    const objQuin = {};
    const arrRangoQuincenas = rangoQuincenas.split(',');
    arrRangoQuincenas.map((q)=> {
      const [clave, valor] = q.split(':');
      objQuin[clave] = parseFloat(valor);
    })
    Object.entries(objQuin).forEach(([clave, valor]) => {
      if (typeof valor === 'number') {
        if(offerDpVale >= valor){
          quincenas = parseInt(clave);
        }
      }
    });
  }

  return (
    <div className={`${classes.dpValeElecContainer}`}>
      <div className={`${classes.dpValePrice}`}>
        <div className="dp-vale-info">
          Con <img src="https://dp-imagenes.s3.us-west-2.amazonaws.com/images/icons/dpvale-logo.png" alt="" /> 
            <HtmlTooltip arrow
              title={
              <React.Fragment>
                <em>{"El importe de la compra cambiará al elegir pagar con dpvale en forma de pago."}</em>
              </React.Fragment>
                }
            >
                <SvgIcon component={InfoIcon} />
            </HtmlTooltip>
        </div>
        <div className="dp-vale-quincenas">
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(Number(offerDpVale / quincenas))} quincenal
        </div>
        <div className="dp-vale-precio-final">
          {`${new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(Number(offerDpVale))} en ${quincenas} quincenas*`}
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={`${classes.dpValeContado}`}>
        <span>
          De contado
        </span>
        <div className="dp-vale-contado-container">
          {/* Precio de oferta de contado */}
          <span className={`dp-vale-contado ${hasOfferDeContado ? "offer" : ""}`}>
            {`${new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number(offerDeContado))} `}
          </span>
          
          {/* Si tiene oferta en el precio de contado */}
          {hasOfferDeContado && (
            <span
              className="dp-vale-contado-discount"
              style={{
                textDecoration: "line-through",
              }}>
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(Number(strikedDeContado))}
            </span> 
          )}
        </div>
        <span className="dp-vale-pago">
          Un solo pago
        </span>
      </div>
    </div>
  )
}
export default ProductoElectronico;