/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/componente";
import initStates from "./initStates";

/**
 * Site reducer
 */
const componenteReducer = createReducer(initStates.componente, (builder) => {

  builder.addCase(
    ACTIONS.SET_NOMBRE_COMPONENTE,
    (state: any | any, action: AnyAction) => {
    
        const paylod = action.payload
        state.currentComponent = action.payload.nombre
    //   Object.assign(state, { currentComponent: { ...action.payload } });
    }
  );
});


export default componenteReducer;
