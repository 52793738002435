/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";

const AppBarWrapper = (props: any) => <AppBar {...props} />;

const StyledSidebarAppBar = styled(AppBarWrapper)`
  ${({ theme }) => `
    top: 143px;
    bottom: 0;
    height: calc(100% - 100px);
    width:10%;
    left:21px;
    line-height: ${theme.spacing(6)}px;

    box-shadow: none;
    background-color:transparent;

    a {
      color: black;
      line-height: 72px;
      cursor: pointer;
      font-size:20px;

      svg {
        vertical-align: left;
        margin-right: ${theme.spacing(0.5)}px;
      }
    }
  `}

`;

export { StyledSidebarAppBar };
