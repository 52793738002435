/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const ComponentWrapper = (props: any) => <Grid {...props} />;

const StyledGrid = styled(ComponentWrapper)`
  ${({ theme }) => `

    &.sidebar {
      ${theme.breakpoints.down("sm")} {
        padding: 0;
      }
    }

    ${theme.breakpoints.down("xs")} {
      &.checkout-actions {
        flex-direction: row;

        .MuiGrid-item {
          .MuiButtonBase-root {
            width: 10%;
          }
        }
      }
    }

    @media only screen and (max-width: 980px) {
      
      &.checkoutStepper{
       display:none;
     }
      
    }

  `}
  .checkoutTitle{
    font-weight: 700;
    font-size: 15px;
    display: block;
    margin:0;

  }
  &.zeroMargin{
    margin:0px;
    margin-left:0px!important;
  }

  

 
`;

export { StyledGrid };
