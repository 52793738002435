/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";

interface TwoTierMenuProps {
  page?: any;
}

const styledMenuRypography: CSSProperties = {
  fontSize: "12px",
  borderBottom: "1px solid #00b0aa",
  color: "black",
  padding: "0px 0px 12px 0px",
};

const styledText: CSSProperties = {
  fontSize: "13px",
  fontWeight: "300",
};

/**
 * TwoTierMenu component
 * expanded menu two tier submenu
 * @param props
 */
const TwoTierMenu: React.FC<TwoTierMenuProps> = (props: any) => {
  const page = props.page ? props.page : [];

  var count = 0;
  if (page && page.children && page.children.length > 0 && page.children[0].name) {    
    if (page.children[page.children.length - 1].name !== page.children[0].name && page.name == "Marcas") {
      page.children.push(page.children[0]);
    }
  }

  return (
    <>
      <StyledPaper className="expanded-menu-two-tier-submenu" style={{ paddingBottom: page.name == "Marcas" ? "24px" : ""}}>
        <StyledBox m={3}>
          <Link to={page.seo?.href} onClick={(event) => window.orderSort = null}>
            <StyledMenuTypography
              style={styledMenuRypography}
              variant="body1"
              className="expanded-menu-bold">
              {"Ver departamento " + page.name}
            </StyledMenuTypography>
          </Link>
          {
            page.children != undefined ?
            (
              page.children.length < 10 ?
                page.children.map((page2: any, i: number) => (
                  <ul key={page2.id}>
                    <Link to={page2.seo?.href} onClick={(event) => window.orderSort = null}>
                      <StyledMenuTypography
                        style={styledText}
                        variant="body2"
                        className="expanded-menu-sub-links">
                        {page2.name}
                      </StyledMenuTypography>
                    </Link>
                  </ul>
                ))
              :
                page.children.map((page2: any, i: number) => (
                  i !== 0 &&
                  <ul key={page2.id} style={{ float: ((i % 3)) ? "left" : "inherit", width: "110px"}}>
                    <Link to={page2.seo?.href} onClick={(event) => window.orderSort = null}>
                      <StyledMenuTypography
                        style={styledText}
                        variant="body2"
                        className="expanded-menu-sub-links">
                        {page2.name}
                      </StyledMenuTypography>
                    </Link>
                  </ul>
                ))
            ) : <></>
          }
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default TwoTierMenu;
