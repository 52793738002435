
/**
 * Custom getAvailability
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { GetAvailabilityApi } from "./get-availability-service/get-availability-api";
import { site } from "../../constants/site";

const getAvailabilityApiInstance = new GetAvailabilityApi(
  undefined,
  site.transactionContext
);
const getAvailabilityService = {
  /**
   * Gets product availability for the specified sku.
   * `@method`
   * `@name getAvailabilityService#getAvailabilityBySku`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} sku (required)` The child property of `Parameters`.The store identifier.
   */
   getAvailabilityBySku(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      sku
    } = parameters;
    return getAvailabilityApiInstance.productAvailabilityGetAvailabilityApiBySku(
      storeId,
      sku
    );
  },

  /**
   * Gets product availability for the specified orderId.
   * `@method`
   * `@name getAvailabilityService#getAvailabilityByOrderId`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} orderId (required)` The child property of `Parameters`.The store identifier.
   */
   getAvailabilityByOrderId(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      orderId
    } = parameters;
    return getAvailabilityApiInstance.productAvailabilityGetAvailabilityApiByOrderId(
      storeId,
      orderId
    );
  },
};

export default getAvailabilityService;
