/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import PropTypes from "prop-types";
import useProductCardWidget, {
  ProductContext,
} from "../../widgets/product-card/ProductCardWidget";

import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useDispatch, useSelector } from "react-redux";
import getDisplayName from "react-display-name";
//Foundation libraries
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import associatedPromotionCodeService from "../../../_foundation/apis/transaction/associatedPromotionCode.service";
import productsService from "../../../_foundation/apis/search/products.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import Grid from "@material-ui/core/Grid";
//Custom libraries
import {
  OFFER,
  DISPLAY,
  DESCRIPTIVE,
  EMPTY_STRING,
} from "../../../constants/common";
import { ATTR_IDENTIFIER } from "../../../constants/catalog";
import StoreUtil from "../../../utils/storeUtil";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as orderActions from "../../../redux/actions/order";
import * as errorActions from "../../../redux/actions/error";
import {
  cartSelector,
  orderItemsSelector,
} from "../../../redux/selectors/order";
import * as catalogActions from "../../../redux/actions/catalog";
//UI
import {
  StyledCloseQuickInfo,
  StyledLoadingContainer,
  StyledQuickInfoContainer,
  StyledDiv,
  StyledInput,
  StyledSelect,
  StyledSpacer,
  StyledAddToCardButton,
  StyledLinkQI,
  StyledContainerProgress,
} from "./StyledComponents/StyledComponents";
import { BsShieldFillCheck } from "react-icons/bs";

//GA360
import AsyncCall from "../../../_foundation/gtm/async.service";
import { GrFormClose } from "react-icons/gr";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Divider,
  Portal,
  Select,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { loginContext } from "../../../App";
import { StyledTypography } from "../../elements";

import { LoginModal } from "../../header";
import getAvailabilityUtil from "../../../_foundation/utils/getavailabilityUtil";
import noImageProduct from "../../../img/noImage.png";
import { prototype } from "events";
import * as successActions from "../../../redux/actions/success";
import { CART } from "../../../constants/routes";
import ProductoElectronico, { DEFAULT_INFO_ELECTRONICO, DEFAULT_PRICES_ELECTRONICOS, InfoElectronico } from "../../commerce-widgets/product-information/ProductoElectronico";
import { ProductoAgotado } from "../../elements/ProductoAgotado";

interface CurrentSelectionType {
  sku: any;
  quantity: number;
  selectedAttributes: any;
  index: number;
  seoUrl: any;
}

const styledMUI = makeStyles((theme) => ({
  responsiveContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  hideResponsive: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  responsiveQIC: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "0px !important",
      borderStyle: "hidden !important",
      paddingBottom: "30px !important",
    },
  },
  responsiveIMG: {
    [theme.breakpoints.down("sm")]: {
      width: "120px !important",
      height: "120px !important",
    },
  },
  responsiveSizes: {
    [theme.breakpoints.down("sm")]: {
      width: "73% !important",
      marginTop: "5px !important",
    },
  },
  responsiveQty: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {},
  },
  responsiveTalla: {
    marginTop: "10px",
    fontFamily: "Roboto !important",
    fontSize: "13px !important",
    color: "#000",
    fontWeight: "bold",
  },
  responsiveQICBottom: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    width: "100%",
  },
  responsiveTittle: {
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Roboto !important",
      fontWeight: "bold !important",
      fontSize: "15px !important",
      color: "#000",
      marginTop: "0px !important",
    },
  },
  divCodigo: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    fontFamily: "Roboto",
    fontSize: "13px",
    color: "#808080",
  },
  divPrices: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    fontFamily: "Roboto",
    fontSize: "13px",
    color: "#000000",
    fontWeight: 700,
  },
  responsiveButtonCart: {
    height: "40px !important",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  responsiveDescription: {
    [theme.breakpoints.down("sm")]: {
      width: "60% !important",
    },
  },
  divider: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
      marginTop: "10px",
    },
  },
  responsiveClose: {
    marginBottom: "20px",
  },
  dropDownTalla: {
    borderWidth: 5,
    borderColor: "red",
    border: "0px solid red !important",
    "&$selected": {
      border: "0px solid red !important",
    },
    "&$selected:hover": {
      border: "0px solid red !important",
    },
  },
  informationContainer: {
    display: "flex",
  },
  containerElectronico:{
    width:"100%",
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
  },
  imgProductoGanador: {
    position: "absolute",
    right: 20,
    bottom: 160,
    width: 75,
    marginTop: 10,
    marginRight: 0,
    marginLeft: 0,
  },
  // classesribon
  txtRibbon: {
    textAlign: "center",
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 3,
  },
  txtOneRibbon: {
    textAlign: "center",
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  dpdOferta: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#BF0000",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: "#BF0000",
    color: "#ffffff",
  },
  dpdOfertaUp: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#BF0000",
    borderTopRightRadius: 15,
    backgroundColor: "#BF0000",
    color: "#ffffff",
  },
  dpdOfertaDown: {
    position: "absolute",
    left: 30,
    top: 45,
    width: 75,
    borderColor: "#BF0000",
    borderBottomRightRadius: 15,
    backgroundColor: "#BF0000",
    color: "#ffffff",
  },
  productoGanadorRibbon: {
    position: "absolute",
    right: 20,
    bottom: 160,
    width: 75,
    marginTop: 10,
    marginRight: 0,
    marginLeft: 0,
  },
  nuevoUp: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#815993",
    borderTopRightRadius: 15,
    backgroundColor: "#815993",
    color: "#ffffff",
  },
  nuevoDown: {
    position: "absolute",
    left: 30,
    top: 45,
    width: 75,
    borderColor: "#815993",
    borderBottomRightRadius: 15,
    backgroundColor: "#815993",
    color: "#ffffff",
  },
  nuevo: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#815993",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: "#815993",
    color: "#ffffff",
  },
  default: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#f18200",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: "#f18200",
    color: "#ffffff",
  },
  defaultUp: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 75,
    borderColor: "#f18200",
    borderTopRightRadius: 15,
    backgroundColor: "#f18200",
    color: "#ffffff",
  },
  defaultDown: {
    position: "absolute",
    left: 30,
    top: 45,
    width: 75,
    borderColor: "#f18200",
    borderBottomRightRadius: 15,
    backgroundColor: "#f18200",
    color: "#ffffff",
  },
}));

/**
 * Product Display component
 * displays product defining atrributes, descriptive atrributes, availability, promotions etc.
 * @param productNumber
 */
function QuickInfoDetailsWidget({
  productNumber,
  displayQuickInfo,
  isCart,
  rowData,
  infoElectronicos,
  ...props
}: any) {
  const classes = styledMUI();
  const widgetName = getDisplayName(QuickInfoDetailsWidget);
  const location: any = useLocation();
  const productPartNumber = productNumber;
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const catalogIdentifier: string = mySite ? mySite.catalogID : "";
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  // const ONLINE_STORE_KEY: string = "Online";
  // const AVAILABLE_KEY: string = "Available";
  let cancels: Canceler[] = [];
  const CancelToken = Axios.CancelToken;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [promotion, setPromotion] = useState<Array<any>>([]);
  const [inventoryAvailableFlag, setInventoryAvailableFlag] =
    useState<boolean>(true);
  const [buyableFlag, setBuyableFlag] = useState<boolean>(true);
  const [sizeFlag, setSizeFlag] = useState(true);

  const setUpdateFlag = props.setUpdateFlag;

  const CURRENT_SELECTION_INIT: CurrentSelectionType = {
    sku: [],
    quantity: 1,
    selectedAttributes: {},
    index: 0,
    seoUrl: "",
  };

  const theme = useTheme();
  const smWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const [product, setProduct] = useState<any>(null);
  const [objOferta, setObjOferta] = useState<any>(null);

  const [productoGanador, setProductoGanador] = useState<any>(null);
  const [normalPrice, setNormalPrice] = useState<any>(null);

  const [currentSelection, setCurrentSelection] =
    useState<CurrentSelectionType>(CURRENT_SELECTION_INIT);

  const [displayName, setDisplayName] = useState<string>("");
  const [displayPartNumber, setDisplayPartNumber] =
    useState<string>(productPartNumber);
  const [displayShortDesc, setDisplayShortDesc] = useState<string>("");
  const [displayOfferPrice, setDisplayOfferPrice] = useState<number>(0);
  const [displayListPrice, setDisplayListPrice] = useState<number>(0);
  const [displayFullImage, setDisplayFullImage] = useState<string>("");
  const [displayLongDesc, setDisplayLongDesc] = useState<string>("");
  const [displayDescriptiveAttrList, setDisplayDescriptiveAttrList] = useState<
    any[]
  >([]);
  const [attachmentsList, setAttachmentsList] = useState<any[]>([]);
  const contract = useSelector(currentContractIdSelector);
  const [carouselImages, setCarouselImages] = React.useState<any[]>([]);

  const loginStatus = useSelector(loginStatusSelector);
  const [allTallas, setAllTallas] = useState<any>(null);
  const [productSKUInventory, setProductSKUInventory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddingItems, setIsAddingItems] = useState<boolean>(false);
  const [availableProductsState, setAvailableProductsState] = useState<any>();
  const [customProductCode, setCustomProductCode] = useState<any>("");
  //Electronicos
  const [infoElectronico, setInfoElectronico] = useState<InfoElectronico>();
  const isElectronico = infoElectronico?.isElectronico;

  const payloadBase: any = {
    widget: "ProductB2BDetailsLayout",
    cancelToken: new CancelToken((c) => {
      cancels.push(c);
    }),
  };
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const [addItemActionTriggered, setAddItemActionTriggered] =
    useState<boolean>(false);
  const [showWindow, setshowWindow] = useState(false);

  const parse = require("html-react-parser");

  useEffect(() => {
    if (showWindow) {
      if (isCart) {
        setSelectedSKU(rowData.partNumber);
        setSelectedSKUId(rowData.partNumber);
        setSelectedSKUQuantity(parseInt(rowData.quantity));
        setSizeFlag(false);
      }
    }
  }, [showWindow]);

  const handleLogin = useContext(loginContext);
  
  /**
   * Get product information based on its type
   */
  const getProduct = (catentry: any) => {
    if (catentry) {
      if (catentry.type === "product" || catentry.type === "variant") {
        setProduct(catentry);
        initializeProduct(catentry);
        getAssociatedPromotions(catentry);
        getRibbonInfo(catentry);
        getProductCode(catentry?.id);
      } else if (catentry.type === "item" && catentry.parentCatalogEntryID) {
        let productsId: string[] = [];
        productsId.push(catentry.parentCatalogEntryID);
        const parameters: any = {
          ...payloadBase,
          id: productsId,
          contractId: contract,
        };
        productsService
          .findProductsUsingGET(parameters)
          .then((res) => {
            if (res.data.contents && res.data.contents.length > 0) {
              initializeProduct(res.data.contents[0], catentry.attributes);
              getAssociatedPromotions(res.data.contents[0]);
              setProduct(res.data.contents[0]);
              getRibbonInfo(catentry);
              getProductCode(catentry?.id);
            }
          })
          .catch((e) => {
            console.log("could not retrieve the parent product details", e);
          });
      }
    }
  };

  /**
   * Get associated promotions for the product
   */
  const getAssociatedPromotions = (productInfo) => {
    if (productInfo) {
      let promotion: any[] = [];
      const parameters: any = {
        ...payloadBase,
        q: "byProduct",
        qProductId: productInfo.id,
      };
      associatedPromotionCodeService
        .findPromotionsByProduct(parameters)
        .then((res) => {
          if (res.data.associatedPromotions) {
            for (let promo of res.data.associatedPromotions) {
              if (promo.description)
                promotion = promo.description.shortDescription;
            }
            setPromotion(promotion);
          }
        })
        .catch((e) => {
          console.log(
            "Could not retrieve product associated promotion details",
            e
          );
        });
    }
  };

  /**
   * Set the display electronic product information on the page using the specified catentry data
   * @param catentry Catentry info to use for display; either item/product/variant
   */
  const setDisplayInfoElectronico = (catentry: any) => {
    try{
      if(catentry && infoElectronicos){
        let producto :InfoElectronico = infoElectronicos.has(catentry.id) ? infoElectronicos?.get(catentry.id)! : DEFAULT_INFO_ELECTRONICO
        //Si esta en el carrito, busca match en los productos hijos
        if (isCart){
          catentry.items.forEach(item =>{
            //Si alguno de los productos esta mapeado
            producto = infoElectronicos.has(item.id) ? infoElectronicos?.get(item.id)! : DEFAULT_INFO_ELECTRONICO
          })
        }
        //Si es electronico
        if(producto.isElectronico){
          producto.prices.strikedDeContado = catentry["x_prices.field1"]
          //producto.prices.offerDpVale = displayOfferPrice
          setInfoElectronico(producto)
          selectUnitallaElectronico(catentry)
        }
      }
      else{
        setInfoElectronico(DEFAULT_INFO_ELECTRONICO)
      }
    }
    catch(e){
      console.error("Error, no existe informacion del producto electronico",e)
      setInfoElectronico(DEFAULT_INFO_ELECTRONICO)
    }
  }

  /**
   * Set the display information on the page using the specified catentry data
   * @param catentry Catentry info to use for display; either item/product/variant
   * @param productInfo Product data to fall back to if needed
   */
  const setDisplayInfo = (catentry: any, productInfo?: any) => {
    // const cdnUrl = "https://dpjye2wk9gi5z.cloudfront.net"

    if (catentry) {
      if (catentry.name) {
        setDisplayName(catentry.name);
      } else {
        setDisplayName("");
      }

      if (catentry.partNumber) {
        setDisplayPartNumber(catentry.partNumber);
      } else {
        setDisplayPartNumber("");
      }

      if (catentry.shortDescription) {
        setDisplayShortDesc(catentry.shortDescription);
      } else {
        setDisplayShortDesc("");
      }

      if (catentry.price?.length > 0) {
        setDisplayPrices(catentry.price);
      } else {
        setDisplayPrices([]);
      }

      if (catentry.attributes?.length > 0) {
        getDescriptiveAttributes(catentry.attributes);
      } else if (productInfo?.attributes?.length > 0) {
        //Fallback to product's descriptive attributes
        getDescriptiveAttributes(productInfo.attributes);
      } else {
        getDescriptiveAttributes([]);
      }

      if (catentry.longDescription) {
        setDisplayLongDesc(catentry.longDescription);
      } else if (productInfo?.longDescription) {
        //Fallback to product's long description
        setDisplayLongDesc(productInfo.longDescription);
      } else {
        setDisplayLongDesc("");
      }

      // const partNum = productNumber.split('-').slice(0, 2).join('-').substring(2)

      // setDisplayFullImage(`/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${partNum}V1.jpg`)

      // if (catentry.fullImage) {
      //   const img_path = catentry.fullImage.replace("hclstore","wcsstore")
      //   setDisplayFullImage(img_path);
      // } else {
      //   setDisplayFullImage("");
      // }
      // if (catentry.images) {
      //   const carouselImageList:any = []
      //   catentry.images.map((image:any, index)=>{
      //     if(index!=0 && index < 6){
      //       carouselImageList.push(image.thumbnail.replace("hclstore/ExtendedSitesCatalogAssetStore","wcsstore"))
      //     }

      //   })
      //   setCarouselImages(carouselImageList);
      // } else {
      //   setCarouselImages([]);
      // }

      /**
       * This behavior for attachment is:
       * Product level attachments will be pushed down to its underlying SKUs which do not have its own attachment
       * SKUs can have its own attachment override and Search can also index those SKU level attachment overrides, instead of inheriting from the product
       * however,  these SKU level attachment overrides will not be "rolled" back up to the product level
       */
      if (catentry.attachments?.length > 0) {
        setAttachmentsList(catentry.attachments);
      } else if (productInfo?.attachments?.length > 0) {
        //Fallback to product's attachments, this may not needed.
        setAttachmentsList(productInfo.attachments);
      } else {
        setAttachmentsList([]);
      }
    }
  };

  /**
   * Get product details based upon the available attributes
   * @param productInfo
   * @param attr
   */
  const initializeProduct = (productInfo: any, attr?: any) => {
    let newSelection = { ...currentSelection };

    if (productInfo) {
      setDisplayInfo(productInfo);

      //Setea la informacion del producto electronico
      setDisplayInfoElectronico(productInfo);

      if (productInfo.items?.length > 0) {
        newSelection.sku = productInfo.items;
      }
      newSelection.seoUrl = productInfo.seo.href;
      setCurrentSelection(newSelection);
    }
  };

  /**
   *Get the inventory details of the product
   * @param catentryId
   */
  const getInventory = async (skus: any[]) => {
    let productSKUIds: any = [];
    const inventory: string[] = [];

    skus.map((item) => {
      productSKUIds.push(item.id);
    });

    if (productSKUIds.length > 0) {
      const parameters: any = {
        ...payloadBase,
        productIds: productSKUIds,
      };

      await inventoryavailabilityService
        .getInventoryAvailabilityByProductId(parameters)
        .then((res) => {
          if (
            res.data.InventoryAvailability !== undefined &&
            res.data.InventoryAvailability.length > 0
          ) {
            const availableProducts = res.data.InventoryAvailability.filter(
              (inventory) => inventory.inventoryStatus === "Available"
            );

            availableProducts &&
              availableProducts.map((inv) => {
                inventory.push(inv.productId);

                const partNumber = skus.filter(
                  (sku) => sku.id === inv.productId
                )[0].partNumber;
                inv.partNumber = partNumber;
              });
            setProductSKUInventory(res.data.InventoryAvailability);
          }
        })
        .catch((e) => {
          console.log("Could not retrieve Inventory Details", e);
        });
    }

    return inventory;
  };

  /**
   * Get the descriptive attributes
   */
  const getDescriptiveAttributes = (attributesArray: any[]) => {
    if (attributesArray) {
      let newDescriptiveAttrList: any[] = [];
      for (const att of attributesArray) {
        if (
          att.usage === DESCRIPTIVE &&
          att.displayable &&
          att.identifier !== ATTR_IDENTIFIER.PickUp &&
          !att.identifier.startsWith(ATTR_IDENTIFIER.RibbonAd)
        ) {
          newDescriptiveAttrList.push(att);
        }
      }
      setDisplayDescriptiveAttrList(newDescriptiveAttrList);
    }
  };

  /**
   * Set the product offer price and display price
   * @param priceArray
   */
  const setDisplayPrices = (priceArray: any[]) => {
    if (priceArray) {
      let offer: string = "0";
      let list: string = "0";
      for (const price of priceArray) {
        if (price.usage === OFFER && price.value !== "") {
          offer = price.value;
        } else if (price.usage === DISPLAY && price.value !== "") {
          list = price.value;
        }
      }
      setDisplayOfferPrice(parseFloat(offer));
      setDisplayListPrice(parseFloat(list));
    }
  };

  /**
   * availability from custom service.
   *
   * @param skuAndQuantities
   * @returns
   */
  async function handleAvailability(skuAndQuantities) {
    //console.log("inventario => ", skuAndQuantities);
    getAvailabilityUtil.setStoreId(storeId);
    return await getAvailabilityUtil.handleAvailability(
      skuAndQuantities,
      payloadBase,
      contract
    );
  }

  /**
   *Add the selected product to the shopping cart
   **/
  const addToCart = async () => {
    //console.log("click");
    //var productAvailable = false;
    //check if product is available

    if (!sizeFlag && loginStatus) {
      setIsLoading(true);
      let skuAndQuantities = new Map();
      if (Number.isInteger(selectedSKUQuantity) && selectedSKUQuantity > 0) {
        skuAndQuantities = new Map(
          skuAndQuantities.set(selectedSKU, selectedSKUQuantity)
        );
      } else {
        let addToCartMap = new Map();
        addToCartMap = skuAndQuantities;
        addToCartMap.delete(selectedSKU);
        skuAndQuantities = new Map(addToCartMap);
      }
      await handleAvailability(skuAndQuantities).then((res) => {
        if (skuAndQuantities && skuAndQuantities.size > 0) {
          const availableProducts = res.availableProducts;
          const unavailableProducts = res.unavailableProducts;

          let errorMsg: any = {
            errorMessage:
              "Los productos con sku: " +
              unavailableProducts.partnumber +
              " no se encuentran disponibles",
          };
          if (unavailableProducts.partnumber.length > 0) {
            dispatch(errorActions.VALIDATION_ERROR_ACTION(errorMsg));
            setInventoryAvailableFlag(false);
            setIsLoading(false);
          }
          if (availableProducts.partnumber.length > 0) {
            if (isCart) {
              if (
                skuAndQuantities.get(availableProducts.data[0].sku) >
                availableProducts.data[0].currentlyAvailable
              ) {
                dispatch(
                  errorActions.VALIDATION_ERROR_ACTION({
                    errorMessage:
                      "Solo contamos con " +
                      availableProducts.data[0].currentlyAvailable +
                      " piezas disponibles. No hay inventario suficiente para comprar " +
                      skuAndQuantities.get(availableProducts.data[0].sku) +
                      " unidades de " +
                      availableProducts.data[0].sku,
                  })
                );
                setIsLoading(false);
              } else {
                setAvailableProductsState(availableProducts);
                const orderItemId = rowData.orderItemId;
                let payload = {
                  ...payloadBase,
                  orderItemId: orderItemId,
                  fetchCatentries: true,
                  skipSnackbar: true,
                };
                dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
                //GA360
                if (mySite.enableGA) {
                  AsyncCall.sendRemoveFromCartEvent(rowData, {
                    enableUA: mySite.enableUA,
                    enableGA4: mySite.enableGA4,
                  });
                }
              }
            } else {
              if (orderItems.length === 0) {
                let error = false;
                availableProducts.data.forEach((item, index) => {
                  //console.log("item => ", item);
                  let partnumber = availableProducts.partnumber[index];
                  let quantity = availableProducts.quantity[index];
                  if (
                    item.sku == partnumber &&
                    item.currentlyAvailable < quantity
                  ) {
                    error = true;
                    dispatch(
                      errorActions.VALIDATION_ERROR_ACTION({
                        errorMessage:
                          "Solo contamos con " +
                          item.currentlyAvailable +
                          " piezas disponibles. No hay inventario suficiente para comprar " +
                          quantity +
                          " unidades de " +
                          partnumber,
                      })
                    );
                  }
                  setIsLoading(false);
                });
                if (!error) {
                  dispatch(orderActions.ADD_ITEM_ACTION(availableProducts));
                  setIsLoading(false);
                }
              } else {
                //console.log("orderItems => ", orderItems);

                let error = false;
                var isNotUpdate = false;
                availableProducts.data.forEach((item, index) => {
                  let partnumber = availableProducts.partnumber[index];
                  let quantity = availableProducts.quantity[index];
                  let isInList = true;
                  orderItems.map((orderItem, orderIndex) => {
                    /*console.log("item.sku == orderItem.partNumber => ", (item.sku == orderItem.partNumber));*/
                    if (item.sku == orderItem.partNumber) {
                      /*console.log("existe en carrito");*/
                      isNotUpdate = false;
                      if (
                        parseInt(orderItem.quantity) + parseInt(quantity) >
                        item.currentlyAvailable
                      ) {
                        error = true;
                        dispatch(
                          errorActions.VALIDATION_ERROR_ACTION({
                            errorMessage:
                              "Solo contamos con " +
                              item.currentlyAvailable +
                              " piezas disponibles. No hay inventario suficiente para comprar " +
                              quantity +
                              " unidades de " +
                              partnumber,
                          })
                        );
                        setIsLoading(false);
                      } else {
                        //console.log("actualiza cantidad carrito");
                        onQuantityUpdate(
                          parseInt(orderItem.quantity) +
                            parseInt(quantity) +
                            "",
                          orderItem
                        );
                        setIsLoading(false);
                      }
                    } else {
                      //console.log("no existe en carrito");
                      //console.log("item.sku == orderItem.partNumber => ", (item.sku == orderItem.partNumber));
                      isNotUpdate = true;
                      isInList = false;
                    }
                  });
                  /*console.log("ISINLIST => ", isInList);*/

                  if (isNotUpdate) {
                    if (parseInt(quantity) > item.currentlyAvailable) {
                      error = true;
                      dispatch(
                        errorActions.VALIDATION_ERROR_ACTION({
                          errorMessage:
                            "Solo contamos con " +
                            item.currentlyAvailable +
                            " piezas disponibles. No hay inventario suficiente para comprar " +
                            quantity +
                            " unidades de " +
                            partnumber,
                        })
                      );
                      setIsLoading(false);
                    } else {
                      /*console.log("se agrega cantidad carrito");*/
                      dispatch(orderActions.ADD_ITEM_ACTION(availableProducts));
                      setIsLoading(false);
                    }
                  }
                });
              }
              return;
            }
          }
        } else {
          let parameters: any = {
            errorMessage: "Ocurrió un error al obtener el inventario",
          };
          dispatch(errorActions.VALIDATION_ERROR_ACTION(parameters));
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
      let parameters: any = {
        errorMessage: "",
      };

      if (!loginStatus) {
        parameters.errorMessage = "Debes iniciar sesión";
        handleLogin();
      }
      if (sizeFlag) {
        parameters.errorMessage = "Seleccione una talla.";
      } else {
        parameters.errorMessage = "Producto no disponible.";
      }
      dispatch(errorActions.VALIDATION_ERROR_ACTION(parameters));
      setIsLoading(false);
    }
  };

  /**
   * Dispatch quantity update action for order item
   * @param item The selected order item
   */
  function onQuantityUpdate(quantityString: string, item: any) {
    /*console.log("upodate item => ", quantityString +" - "+ item)*/
    if (item) {
      try {
        const quantity = parseInt(quantityString);
        if (quantity > 0) {
          let payload = {
            ...payloadBase,
            quantity: quantity.toString(),
            orderItemId: item.orderItemId,
            fetchCatentries: true,
          };
          dispatch(orderActions.UPDATE_ITEM_ACTION(payload));
          dispatch(
            successActions.HANDLE_SUCCESS_MESSAGE_ACTION({
              key: "Se ha añadido el artículo al carrito. ",
              link: {
                url: CART,
                textKey: t("success-message.ViewCart"),
              },
            })
          );
        }
      } catch (e) {
        console.log("Could not parse quantity");
      }
    }
  }

  /**
   *Get product information from part number
   */
  const getProductDetails = () => {
    let parameters: any = {
      storeId: storeId,
      partNumber: productPartNumber,
      contractId: contract,
      catalogId: catalogIdentifier,
      ...payloadBase,
    };
    productsService
      .findProductsUsingGET(parameters)
      .then((productData: any) => {
        const productDetails = productData.data.contents.filter(
          (prodData) => prodData.partNumber === productPartNumber
        );
        if (productDetails && productDetails.length > 0) {
          let categoryIdentifier: string = EMPTY_STRING;
          const inputCatentryData = productDetails[0];
          getProduct(inputCatentryData);
          if (inputCatentryData?.attachments?.length > 0) {
            setAttachmentsList(inputCatentryData.attachments);
          }
          if (location?.state?.categoryId) {
            categoryIdentifier = location.state.categoryId;
          } else {
            let parentCatalogGroupID = productDetails[0].parentCatalogGroupID;
            if (parentCatalogGroupID) {
              categoryIdentifier =
                StoreUtil.getParentCategoryId(parentCatalogGroupID);
            }
          }
          if (categoryIdentifier && categoryIdentifier.length > 0) {
            let parameters: any = {
              categoryId: categoryIdentifier,
              contractId: contract,
              currency: defaultCurrencyID,
              storeId: storeId,
              productName: productDetails[0].name
                ? productDetails[0].name
                : EMPTY_STRING,
              ...payloadBase,
            };
            dispatch(
              catalogActions.getProductListForPDPAction({
                parameters: parameters,
              })
            );
          }
        }
      })
      .catch((e) => {
        console.log("Could not retrieve product details page informarion", e);
      });
  };

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (mySite) {
      cleanProductInfo();
      getProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, productPartNumber]);

  function compare(a, b) {
    // console.log(a.colonia);
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  React.useEffect(() => {
    if (currentSelection?.sku && currentSelection.sku.length > 0) {
      getTallas(currentSelection.sku);

      let productImages: any = [];
      if (productNumber !== "") {
        //setshowWindow(true);
        const partNum = productNumber
          .split("-")
          .slice(0, 2)
          .join("-")
          .substring(2);
        const prodImages: any = [
          `${partNum}V1.jpg`,
          `${partNum}V2.jpg`,
          `${partNum}V3.jpg`,
          `${partNum}V4.jpg`,
          `${partNum}V5.jpg`,
        ];

        function imageExists(url) {
          var http = new XMLHttpRequest();

          http.open("HEAD", url, false);
          http.send();

          return http.status;
        }

        prodImages.map((img) => {
          const url = `/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/thumbnail/${img}`;
          const exist = 200;
          if (exist === 200) {
            productImages.push(window.urlBaseImg + url);
          }
        });
        if (productImages.length > 0) {
          setCarouselImages(productImages);
          setSelectedThumbnail(
            window.urlBaseImg +
              `/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${prodImages[0]}`
          );
        } else {
          setSelectedThumbnail(noImageProduct);
        }

        setShowLoading(false);
        setshowWindow(true);
      }
    }
  }, [currentSelection?.sku]);

  const getTallas = async (skus) => {
    const inventory = await getInventory(skus);
    const tallas: {
      partNumber: string;
      talla: string;
      value: number;
      id: string;
    }[] = [];

    skus.map((sku) => {
      if (!sku.buyable || !inventory.includes(sku.id)) return;
      const talla = sku.attributes.filter(
        (attribute) => attribute.identifier === "Talla"
      );

      if (talla[0].values) {
        tallas.push({
          partNumber: sku.partNumber,
          talla: talla[0].values[0].value,
          value: parseFloat(talla[0].values[0].value),
          id: sku.id,
        });
      }
    });

    const tallasRopa = ["u", "ech", "ch", "m", "g", "eg"];

    function ordenarTallasRopa(valores) {
      const tallasOrdenadas: any = [];

      tallasRopa.forEach(function (key) {
        const tallaRopa = valores.filter(
          (val) => val.talla.toLowerCase() === key
        )[0];

        if (tallaRopa) {
          tallaRopa.talla = tallaRopa.talla.toUpperCase();
          tallasOrdenadas.push(tallaRopa);
        }
      });

      return tallasOrdenadas;
    }

    if (tallas.length > 0) {
      if (isNaN(tallas[0].value)) {
        setAllTallas(ordenarTallasRopa(tallas));
      } else {
        setAllTallas(tallas.sort(compare));
      }
    }
    else{
      setAllTallas([])
    }
  };

  useEffect(() => {
    if (addItemActionTriggered) {
      //GA360
      if (mySite.enableGA) {
        AsyncCall.sendAddToCartEvent(
          { cart, currentSelection },
          { enableUA: mySite.enableUA, enableGA4: mySite.enableGA4 }
        );
      }
      setAddItemActionTriggered(false);
      if (!isCart) {
        setIsLoading(false);
        setshowWindow(false);
      }
    }
  }, [cart, currentSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCart && isLoading && !isAddingItems) {
      //console.log("addItemCartAction => ", availableProductsState)
      availableProductsState.skipSnackbar = true;
      dispatch(orderActions.ADD_ITEM_ACTION(availableProductsState));
      setIsAddingItems(true);
      setUpdateFlag(true);
    }

    if (isCart && isLoading && isAddingItems) {
      setIsAddingItems(false);
      setIsLoading(false);
      setshowWindow(false);
    }
  }, [orderItems, cart, mySite]);

  function cleanProductInfo() {
    setDisplayFullImage("");
    setDisplayName("");
    setDisplayListPrice(0);
    setInfoElectronico(DEFAULT_INFO_ELECTRONICO)
    setDisplayLongDesc("");
    setCarouselImages([]);
    setSelectedThumbnail("");
    setAllTallas(null)
    setCustomProductCode("")
  }

  const IMAGE_NOT_FOUND =
    "https://media.istockphoto.com/vectors/black-linear-photo-camera-like-no-image-available-vector-id1055079680?k=20&m=1055079680&s=612x612&w=0&h=ujFxkvnp-VclErGByAsr2RYLJObedAtK7NNLgNJY_8A=";

  const [showLoading, setShowLoading] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState<any>(
    displayFullImage ? displayFullImage : IMAGE_NOT_FOUND
  );

  const [selectedSKU, setSelectedSKU] = useState("");
  const [selectedSKUId, setSelectedSKUId] = useState<any>(0);
  const [selectedSKUQuantity, setSelectedSKUQuantity] = useState(1);
  const [atributosValidos, setAtributosValidos] = useState<any>([]);

  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openModalReg, setOpenModalReg] = useState(false);

  function openQuickInfo() {
    setshowWindow(true);
  }
  //load on start
  useEffect(() => {
    props.callbackToParent.current = openQuickInfo;
    setShowLoading(false);
  }, []);

  useEffect(() => {
    setSelectedThumbnail(displayFullImage);
  }, [displayFullImage]);

  useEffect(() => {
    if (productPartNumber !== "") {
      setShowLoading(true);
    }
  }, [productPartNumber]);

  function handleProductSKU(e) {
    /*console.log("onChange => ", e.target.value)*/
    window.productQI = e.target.value;
    setSelectedSKU(e.target.value);
    setSelectedSKUId(e.target.value);
    setInventoryAvailableFlag(true);
    if (e.target.value != 999) {
      // setSelectedSKUId(e.target.selectedIndex);
      setSizeFlag(false);
    } else {
      setSelectedSKUQuantity(0);
      setSizeFlag(true);
    }
  }

  function selectUnitallaElectronico(catentry: any){
    let talla = ""
    catentry.items.forEach(item =>{
      talla = item.partNumber
    })
    window.productQI = talla;
    setSelectedSKU(talla);
    setSelectedSKUId(talla);
    setInventoryAvailableFlag(talla !== "");
    setSizeFlag(false); //No tiene que mostrar error de talla
  }

  function handleProductSKUQuantityChange(e) {
    if (selectedSKUId === "999") {
      setSelectedSKUQuantity(0);
      return;
    }
    else if(isElectronico){
      setSelectedSKUQuantity(1);
    }

    const quantity = productSKUInventory.filter(
      (psku) => psku.partNumber === selectedSKUId
    )[0].availableQuantity;

    if (!quantity) {
      setSelectedSKUQuantity(0);
    }

    if (e.target.valueAsNumber === 0) {
      setSelectedSKUQuantity(1);
    }

    if (
      e.target.valueAsNumber >= 1 &&
      e.target.valueAsNumber <= parseInt(quantity)
    ) {
      setSelectedSKUQuantity(e.target.valueAsNumber);
    }

    if (e.target.valueAsNumber > parseInt(quantity)) {
      setSelectedSKUQuantity(parseInt(quantity));
    }

    if (isNaN(e.target.valueAsNumber)) {
      setSelectedSKUQuantity(0);
    }
  }

  function handleProductSKUQuantityBlur(e) {
    if (isNaN(e.target.valueAsNumber)) {
      setSelectedSKUQuantity(1);
    }
  }

  function handleDisplayImage(img) {
    setSelectedThumbnail(img.replace("/thumbnail", "/default"));
  }

  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
    setOpenModalReg(false);

    setshowWindow(false);
    setShowLoading(false);

    //TODO, DANIEL
  };

  useEffect(() => {
    if (!showWindow) {
      setSelectedSKUQuantity(1);
    }
  }, [showWindow]);

  // Obtienen el nombre, price usage
  const getRibbonInfo = (prodData) => {
    let arrayAtributes: any = [];
    prodData &&
      prodData.attributes.map((attribute: any, index: number) => {
        if (attribute.storeDisplay === "true") {
          if (
            !attribute.name.includes("DPT_") &&
            !attribute.name.includes("DPS_")
          ) {
            arrayAtributes.push(attribute);
            // hay que mapear los atributos y cada uno su valor será .values[0].value
          }
        } else if (attribute.name === "DPD_Producto Ganador") {
          setProductoGanador(attribute);
        }
      });

    if (prodData?.attributes && prodData.attributes.length > 0) {
      const resultPriceOferta = prodData.price.filter(
        (price) => price.usage === "Display"
      )[0];
      let precioNormal = resultPriceOferta
        ? parseFloat(resultPriceOferta.value).toFixed(2)
        : null;

      setNormalPrice(precioNormal);
    }
    setAtributosValidos(arrayAtributes);
  };

  /* 
  * Consulta el codigo del articulo
  */
  const getProductCode = async (catentryId)=> {
    const url = `/wcs/resources/store/${mySite.storeID}/GetCatentryField4/byId/${catentryId}`;
    //console.log("url GetCatentryField4",url)
    await Axios.get(url).then((data) => {
      //console.log("success GetCatentryField4",data.data?.field4);
      if(data.data?.field4 !== null)
        setCustomProductCode(data.data?.field4);
    }).catch((error) => {
      console.debug("error GetCatentryField4", error);
    });
  }  

  const handleSwitchModalLogin = () => {
    setOpenModalReg(!openModalReg);
  };
  const handleCloseModalLogin = () => setOpenModalLogin(false);

  return (
    <Portal>
      <StyledLoadingContainer
        id="loadingWrapper"
        className={showLoading ? "" : "hidden"}>
        <CircularProgress color="secondary" />
      </StyledLoadingContainer>
      ({allTallas !== null && <StyledQuickInfoContainer
        id="quickInfoWrapper"
        className={showWindow ? classes.responsiveContainer : "hidden"}
        onClick={(e) => {
          if (e.target["id"] == "quickInfoWrapper") {
            setshowWindow(false);
            setShowLoading(false);
          }
        }}>
        <div id="quickInfoContainer" className={classes.responsiveQIC}>
          <StyledCloseQuickInfo>
            <GrFormClose
              id="closeQuickInfo"
              size={30}
              className={classes.responsiveClose}
              onClick={() => {
                setshowWindow(false);
                setShowLoading(false);
              }}
            />
          </StyledCloseQuickInfo>
          <StyledSpacer height="15px" />
          <div className={classes.informationContainer + `${isElectronico && smWidth ? " containerElectronico" : ""}`}>
            <div className="quickInfoGalleryDisplay">
              <div className="productImage" style={{ position: "relative" }}>
                {selectedThumbnail !== "" && (
                  <img
                    className={classes.responsiveIMG}
                    id="quickInfoMainImage"
                    alt={displayShortDesc}
                    src={selectedThumbnail}
                    onError={(e) => {
                      e.currentTarget.src = noImageProduct;
                      if (productPartNumber !== "") {
                        setshowWindow(true);
                        setShowLoading(false);
                      }
                    }}
                    title=""
                  />
                )}
                {/* RIBBONS */}
                {atributosValidos.length === 1 && !smWidth
                  ? atributosValidos.map((attribute: any) => (
                      <Box
                        className={
                          attribute.identifier === "DPD_Oferta"
                            ? classes.dpdOferta
                            : attribute.identifier === "Nuevo"
                            ? classes.nuevo
                            : classes.default
                        }
                        border={1}>
                        <Typography className={classes.txtOneRibbon}>
                          {attribute.identifier === "Nuevo"
                            ? attribute.identifier
                            : attribute.values[0].value}
                        </Typography>
                      </Box>
                    ))
                  : atributosValidos &&
                    atributosValidos.length > 1 &&
                    !smWidth && (
                      <>
                        <Box>
                          <Box
                            className={
                              atributosValidos[0]?.identifier === "DPD_Oferta"
                                ? classes.dpdOfertaUp
                                : atributosValidos[0]?.identifier === "Nuevo"
                                ? classes.nuevoUp
                                : classes.defaultUp
                            }
                            border={1}>
                            <Typography className={classes.txtRibbon}>
                              {atributosValidos[0]?.identifier === "Nuevo"
                                ? atributosValidos[0]?.identifier
                                : atributosValidos[0]?.values[0]?.value}
                            </Typography>
                          </Box>
                          <Box
                            className={
                              atributosValidos[1]?.identifier === "DPD_Oferta"
                                ? classes.dpdOfertaDown
                                : atributosValidos[1]?.identifier === "Nuevo"
                                ? classes.nuevoDown
                                : classes.defaultDown
                            }
                            border={1}>
                            <Typography className={classes.txtRibbon}>
                              {atributosValidos[1]?.identifier === "Nuevo"
                                ? atributosValidos[1]?.identifier
                                : atributosValidos[1]?.values[0]?.value}
                            </Typography>
                          </Box>
                        </Box>
                        {/* } */}
                      </>
                    )}
                {productoGanador && !smWidth && (
                  <Box
                    style={{
                      bottom: 0,
                    }}>
                    <img
                      src={
                        "https://dpjye2wk9gi5z.cloudfront.net/wcsstore/Dpdirecto/images/insignia-dpd-150x150.png"
                      }
                      alt="icono-producto-ganador"
                      className={classes.productoGanadorRibbon}
                      style={{
                        position: "absolute",
                        right: 20,
                        bottom:
                          atributosValidos && atributosValidos.length === 1
                            ? 165
                            : atributosValidos && atributosValidos.length > 1
                            ? 160
                            : 145,
                        width: 75,
                        marginTop: 10,
                        marginRight: 0,
                        marginLeft: 0,
                      }}></img>
                  </Box>
                )}

                {/* RIBBONS */}
              </div>
              <div
                className={
                  "productCarouselContainer " + classes.hideResponsive
                }>
                <div className="productCarousel">
                  {allTallas?.length > 0 &&
                    carouselImages &&
                    carouselImages.map((prodImage, index) => {
                      const imgName: string = selectedThumbnail.substring(
                        selectedThumbnail.lastIndexOf("/") + 1,
                        selectedThumbnail.length
                      );
                      const imgItem: string = prodImage.substring(
                        prodImage.lastIndexOf("/") + 1,
                        prodImage.length
                      );

                      return (
                        <img
                          onClick={() => handleDisplayImage(prodImage)}
                          src={prodImage}
                          key={index}
                          onError={(event) =>
                            (event.currentTarget.style.display = "none")
                          }
                          alt="short desc"
                          className={
                            imgName === imgItem ? "img-selected" : "img-style"
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div
              className={
                "quickInfoProductContent " + classes.responsiveDescription
              }>
              <div className="productDetails">
                <div className={"productPartNumber " + classes.hideResponsive}>
                  Código del artículo: {productPartNumber}
                </div>
                <div
                  className={
                    "productSeparator " + classes.hideResponsive
                  }></div>
                <StyledTypography
                  variant="h4"
                  itemProp="name"
                  className={"productTitle " + classes.responsiveTittle}
                  style={{
                    fontFamily: "Fjalla One",
                    fontSize: 28,
                    fontWeight: 300,
                  }}>
                  {displayName.toUpperCase()}{" "}
                </StyledTypography>

                <StyledTypography
                  variant="h5"
                  className={"productPrice  " + classes.hideResponsive}
                  style={{ fontFamily: "Fjalla One" }}>
                  {isElectronico && infoElectronico 
                  ?
                    <div style={{paddingBottom:"20px"}}>
                      <ProductoElectronico {...{prices: {...infoElectronico.prices, offerDpVale: displayOfferPrice}}} />
                    </div>
                  : displayOfferPrice > 0 ? (
                    // <span className={"productPrice  " + classes.hideResponsive}>
                    <div
                      style={{
                        color:
                          displayListPrice > displayOfferPrice ? "red" : "",
                      }}>
                      {displayListPrice > 0 &&
                      displayListPrice > displayOfferPrice
                        ? "OFERTA:"
                        : "PRECIO:"}
                      &nbsp;
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(Number(displayOfferPrice))}
                      &nbsp;MXN
                    </div>
                  ) : // </span>
                  null}
                </StyledTypography>
                <StyledTypography
                  variant="h5"
                  className={"productPrice  " + classes.hideResponsive}
                  style={{
                    fontFamily: "Fjalla One",
                    fontSize: 14,
                    color: "#000",
                    textDecoration: "line-through",
                  }}>
                  {objOferta && displayListPrice > displayOfferPrice ? (
                    <span
                      className="product-price"
                      style={{
                        fontSize: 14,
                        color: "#000",
                        textDecoration: "line-through",
                      }}>
                      <div>
                        PRECIO:&nbsp;
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(Number(objOferta.precioNormal))}
                        &nbsp;MXN
                      </div>
                    </span>
                  ) : objOferta === undefined &&
                    displayListPrice > displayOfferPrice ? (
                    <span
                      className="product-price"
                      style={{
                        fontSize: 14,
                        color: "#000",
                        textDecoration: "line-through",
                      }}>
                      <div>
                        PRECIO:&nbsp;
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(Number(displayListPrice))}
                        &nbsp;MXN
                      </div>
                    </span>
                  ) : null}
                </StyledTypography>
                {/* Custom product code */}
                {!smWidth && customProductCode !== "" &&
                  <div className={"productPartNumber " + classes.hideResponsive} style={{"margin":"0px 0 20px"}}>
                    {t("productDetail.CODE", {
                      code: customProductCode,
                    })}
                  </div>
                }
                {/* <div className={"productPrice  " + classes.hideResponsive}>
                {"Tú precio: $"+displayListPrice}
                {"Tú precio: $"+displayOfferPrice}
              </div> */}

                {/* Informacion del electronico en mobile */}
                {isElectronico && infoElectronico && smWidth &&
                  <div style={{paddingBottom:"20px"}}>
                    <ProductoElectronico {...{prices: {...infoElectronico.prices, offerDpVale: displayOfferPrice}}} />
                  </div>
                }

                {/* Codigo en mobile */}
                {!isElectronico && smWidth && 
                <>
                  <div className={classes.divCodigo}>
                    Código: {productPartNumber}
                  </div>
                  
                  <div className={classes.divPrices}>
                    {displayOfferPrice < displayListPrice
                      ? new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(Number(displayOfferPrice))
                      : new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(Number(displayListPrice))}

                    {/* ${displayListPrice} */}
                  </div>
                </>
                }
                <div className={"productDescription " + classes.hideResponsive}>
                  {" "}
                  {parse(displayLongDesc)}{" "}
                </div>
                <div className={"productWarranty " + classes.hideResponsive}>
                  {" "}
                  <BsShieldFillCheck /> Garantía en{" "}
                  <b>devoluciones y cambios de 30 días</b>{" "}
                  {!isElectronico &&
                    <StyledLinkQI href="/cambios-devoluciones">
                      ver detalles.
                    </StyledLinkQI>
                  }
                  {" "}
                </div>
                <div
                  className={
                    "productSeparator " + classes.hideResponsive
                  }></div>
                {/* Informacion del producto */}
                <div
                  className={`${(currentSelection && !isElectronico)? "quickInfoCartContent" : "quickInfoCartContentElec"}  ` + classes.hideResponsive +  `${allTallas?.length === 0 ? ' noInventoryItem' : ''}`}>
                  <> { allTallas === null ? null : allTallas?.length > 0 ? <> {
                  (currentSelection && !isElectronico) && (
                    <>
                      <StyledDiv className="productSizes">
                        <StyledSelect
                          className={classes.dropDownTalla}
                          id="sizes"
                          value={selectedSKUId}
                          onChange={(e) => handleProductSKU(e)}>
                          <option
                            key={"999"}
                            value={"999"}
                            // onLoad={(e) => handleProductSKU}
                          >
                            {"TALLA"}
                          </option>
                          {allTallas?.map((val) => {
                            return (
                              <option
                                key={val.partNumber}
                                value={val.partNumber}>
                                {val.talla}
                              </option>
                            );
                          })}
                        </StyledSelect>
                        <StyledInput
                          type="number"
                          min="1"
                          value={selectedSKUQuantity}
                          onChange={(e) => handleProductSKUQuantityChange(e)}
                          onBlur={(e) => handleProductSKUQuantityBlur(e)}
                        />
                      </StyledDiv>
                      <div className="productAddToCart">
                        {loginStatus ? (
                          <StyledAddToCardButton
                            className="product-add-to-cart"
                            id={`product_add_to_cart_${productPartNumber}`}
                            onClick={addToCart}
                            disabled={!inventoryAvailableFlag || !buyableFlag}>
                            {isLoading ? (
                              <CircularProgress color="inherit" />
                            ) : isCart ? (
                              "ACTUALIZAR ARTÍCULO"
                            ) : (
                              "AÑADIR AL PEDIDO ACTUAL"
                            )}
                          </StyledAddToCardButton>
                        ) : (
                          <StyledAddToCardButton
                            className="product-add-to-cart"
                            id={`product_add_to_cart_${productPartNumber}`}
                            onClick={handleOpenModalLogin}>
                            {t("productDetail.SignIn")}
                          </StyledAddToCardButton>
                        )}
                      </div>
                   </>
                  )}
                  {/* Seccion inferior de agregar al carrito */}
                  {(isElectronico) && (
                    <>
                      <div className="productAddToCart">
                      <StyledDiv className="productSizes" style={{gap:10}}>
                      <StyledInput
                          type="hidden"
                          value={1}
                        />

                        {loginStatus ? (
                          <StyledAddToCardButton
                            className={`product-add-to-cart`}
                            id={`product_add_to_cart_${productPartNumber}`}
                            style={{marginTop:"8px", height: "43px",}}
                            onClick={addToCart}
                            disabled={!inventoryAvailableFlag || !buyableFlag}>
                            {isLoading ? (
                              <CircularProgress color="inherit" />
                            ) : isCart ? (
                              "ACTUALIZAR ARTÍCULO"
                            ) : (
                              "AÑADIR AL PEDIDO ACTUAL"
                            )}
                          </StyledAddToCardButton>
                        ) : (
                          <StyledAddToCardButton
                            className="product-add-to-cart"
                            id={`product_add_to_cart_${productPartNumber}`}
                            onClick={handleOpenModalLogin}
                            style={{ fontFamily: "Fjalla One" }}>
                            {t("productDetail.SignIn")}
                          </StyledAddToCardButton>
                        )}
                      </StyledDiv>
                      </div>
                    </>
                  )}
                  </>
                  : 
                    <ProductoAgotado style={{marginTop:"20px"}}/>
                }</>
                </div>
                <StyledSpacer height="20px" />
                {/* Muestra la guia de talla si no es electronico */}
                {!isElectronico
                  ? (<div className={"productMoreInfo " + classes.hideResponsive}>
                      Para más información consulta nuestra{" "}
                      <StyledLinkQI href="/guia-tallas">
                        guía de tallas
                      </StyledLinkQI>{" "}
                      o{" "}
                      <StyledLinkQI href={currentSelection.seoUrl}>
                        revisa la información completa de este producto
                      </StyledLinkQI>
                    </div>) 
                  : (<>
                      Para más información consulta{" "}
                      <StyledLinkQI href={currentSelection.seoUrl}>
                        la información completa de este producto
                      </StyledLinkQI>
                    </>)
                }
              </div>
            </div>
          </div>
          <Divider className={classes.divider} />
          
          {/* Detalles del producto en mobile */}
          {smWidth && (
            allTallas === null ? null : allTallas?.length > 0 ?
            (!isElectronico && 
            <Grid className={classes.responsiveQICBottom}>
              <Grid className={classes.responsiveTalla}>Talla:</Grid>
              <Box display="flex" height={40} alignItems="flex-start">
                <Box flexGrow={1}>
                  <StyledSelect
                    style={{
                      width: "100%",
                      margin: 0,
                      height: 40,
                      color: "#000",
                    }}
                    id="sizes"
                    value={selectedSKUId}
                    onChange={(e) => handleProductSKU(e)}>
                    <option
                      key={"999"}
                      value={"999"}
                      // onLoad={(e) => handleProductSKU}
                      style={{ color: "#000" }}>
                      {"TALLA"}
                    </option>
                    {allTallas?.map((val) => {
                      return (
                        <option
                          key={val.partNumber}
                          value={val.partNumber}
                          style={{ color: "#000" }}>
                          {val.talla}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </Box>
                <Box ml={2}>
                  <StyledInput
                    style={{ width: 50, margin: 0, padding: 0, height: 40 }}
                    type="number"
                    min="1"
                    value={selectedSKUQuantity}
                    onChange={(e) => handleProductSKUQuantityChange(e)}
                    onBlur={(e) => handleProductSKUQuantityBlur(e)}
                    className={classes.responsiveQty}
                  />
                </Box>
              </Box>
            </Grid>)
            : 
            <div style={{marginTop:"20px"}}><ProductoAgotado/></div>
          )
          }
         

          {/* Boton de agregar al carrito mobile smWidth */}
          {smWidth && allTallas?.length > 0 &&
          <StyledAddToCardButton
            className={"product-add-to-cart " + classes.responsiveButtonCart}
            id={`product_add_to_cart_${productPartNumber}`}
            onClick={addToCart}
            disabled={!inventoryAvailableFlag || !buyableFlag || sizeFlag}>
            {isCart ? "ACTUALIZAR ARTÍCULO" : "AÑADIR AL PEDIDO ACTUAL"}
          </StyledAddToCardButton>
          }
          
        </div>
      </StyledQuickInfoContainer>})
      <LoginModal
        openModalLogin={openModalLogin}
        handleCloseModalLogin={handleCloseModalLogin}
        openModalReg={openModalReg}
        handleSwitchModalLogin={handleSwitchModalLogin}
      />
    </Portal>
  );
}

QuickInfoDetailsWidget.propTypes = {
  productNumber: PropTypes.any,
  displayQuickInfo: PropTypes.any,
  callbackToParent: PropTypes.any,
  isCart: PropTypes.any,
  rowData: PropTypes.any,
  setUpdateFlag: PropTypes.any,
  infoElectronicos: PropTypes.any, //Lista de productos con informacion de electronicos
};

export default QuickInfoDetailsWidget;
