/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import { dimensions } from "../../../themes/variables";

const buttonSize = dimensions.pagination.button;

/**
 * Styled component on @material-ui
 * @see https://material-ui.com/api/pagination/
 */
const StyledPagination = styled(({ ...props }) => <Pagination {...props} />)`
  ${({ theme }) => `
  //margin: 20px 0;

  ul {
    li:first-child,
    li:last-child {
      button {
        border: 1px solid ${theme.palette.action.disabled};
      }
    }
  }

  li {
    width: 40px;
  }

  .MuiMenuItem-root {
    color: ${theme.palette.text.primary};
  }

  .MuiPaginationItem-rounded {
    border: 1px solid ${theme.palette.action.disabled};
    height: ${buttonSize}px;
    width: ${buttonSize}px;

    &.Mui-selected {
      border: 0;
      background: ${theme.palette.primary.main} !important;
      color: white;
    }
  }

  .MuiPaginationItem-rounded:hover {
    background: ${theme.palette.primary.main} !important;
    color: white;
  }

  &.headPagination .MuiPagination-ul li {
    width: 28px;
  }

  &.headPagination .MuiPaginationItem-rounded{
    border: 0px;
    height: 10px;
    width: 10px;
    font-weight: 100;

    &.Mui-selected {
      border: 0;
      background: transparent !important;
      color: ${theme.palette.primary.main} !important;
    }
  }

  &.headPagination .MuiPaginationItem-rounded:hover {
    background: transparent !important;
    color: ${theme.palette.primary.main} !important;
  }
  `}
`;

export { StyledPagination };
