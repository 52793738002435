/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import Axios, { Canceler } from "axios";
import axios from "axios";

import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../../../_foundation/hooks/useSite";
import {
  localStorageUtil,
  storageSessionHandler,
  storageStoreIdHandler,
} from "../../../../../_foundation/utils/storageUtil";
import { SKIP_WC_TOKEN_HEADER } from "../../../../../_foundation/constants/common";
//Custom libraries
import { HOME, SIGNIN } from "../../../../../constants/routes";
import { EMPTY_STRING } from "../../../../../constants/common";
import addressUtil from "../../../../../../src/utils/addressUtil";
//Redux
import * as userAction from "../../../../../redux/actions/user";
import { RESET_ERROR_ACTION } from "../../../../../redux/actions/error";
import { passwordExpiredErrorSelector } from "../../../../../redux/selectors/error";
import { loginStatusSelector } from "../../../../../redux/selectors/user";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
//UI
import {
  StyledButton,
  StyledTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";
import { StyledTextField } from "../../../../elements/text-field";
import Divider from "@material-ui/core/Divider";
//GA360
import AsyncCall from "../../../../../_foundation/gtm/async.service";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import visibilityIcon from "../../../../../img/visibilityIcon.svg";
import showPasswordIcon from "../../../../../img/showPasswordIcon.svg";
import { genericErrorSelector } from "../../../../../redux/selectors/error";
import ModalContext from "../../../../../_foundation/context/ModalContext";
import { CircularProgress } from "@material-ui/core";

interface SignInContext {
  cid: string;
  redirectRoute?: string;
  showRegistrationPage: any;
  handleCloseModalLogin: any;
}
const TextLogin = styled.span`
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  color: #000000;
`;

const LoginLabels = styled.span`
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 160%;
  font-weight: 300;
`;

const ForgotLabel = styled.span`
  font-size: 13px;
  font-family: Roboto, sans-serif;
  color: #000;
  text-decoration: underline;
  font-weight: 100;
  margin-top: 10px;
`;

const LoginButton = styled.button`
  background: #fd4364;
  color: #fff;
  border-radius: 0;
  min-width: 240px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  line-height: 40px;
  :disabled {
    background: #fd4364a6;
  }
  :enabled {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const styledLoginModal = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "10px",
    marginBottom: "20px",
    marginTop: "25px",
  },
  divItem: {
    marginTop: "30px",
  },

  divSepBot: {
    paddingBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      borderBottom: "1px solid #e9e9e9",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  box: {
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  btnContinue: {
    "&:hover": {
      background: "#00B6D1",
      border: "2px solid #00B6D1 !important",
    },
  },
  btnCancel: {
    "&:hover": {
      background: "#009EB7 !important",
      border: "2px solid #009EB7 !important",
      color: "white !important",
    },
  },
  loginTextBox: {
    "& > .MuiOutlinedInput-root": {
      margin: "0px !important",
      width: "240px",
      height: "45px",
      borderRadius: "0px",
      border: "1px solid #bfbfbf",
      backgroud: "#FFFFFF !important",
      fontFamily: "Roboto",
      "font-weight": "300 !important",
      borderRadious: "0px !important",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
  },
  loginTextBoxPass: {
    margin: "0px !important",
    width: "240px",
    height: "45px",
    borderRadius: "0px",
    border: "1px solid #bfbfbf",
    backgroud: "#FFFFFF !important",
  },
  divInput: {
    marginBottom: "20px",
  },
  divLabelLogin: {
    marginBottom: "7px",
  },
  divForgot: {
    marginTop: "10px",
  },
  "@global": {
    fieldset: {
      display: "none",
    },
  },
  showPassword: {
    position: "absolute",
    marginTop: "8px",
    marginLeft: "190px",
    cursor: "pointer",
    zIndex: 10,
  },
  divPassword: {},
  iconEye: {
    color: "#000",
    width: "12px",
    marginRight: "10px",
  },
  iconEye2: {
    color: "#000",
    padding: "0px",
    marginRight: "10px",
    width: "20px",
  },
  border: {
    "& div": {
      borderWidth: "1px",
      borderStyle: "groove",
    },
  },
}));

function SignInLayout({ cid, showRegistrationPage, ...props }: SignInContext) {
  const error: any = useSelector(genericErrorSelector);
  const classesLogin = styledLoginModal();
  const widgetName = getDisplayName(SignInLayout);
  const passwordExpiredError: any = useSelector(passwordExpiredErrorSelector);
  const redirectRoute = props.redirectRoute ? props.redirectRoute : HOME;
  const loginStatus = useSelector(loginStatusSelector);
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logonInputProps, setLogonInputProps] = useState<any>({});
  const [email, setEmail] = useState<string>(
    window.user ? window.user : EMPTY_STRING
  );
  const [logonPassword, setLogonPassword] = useState<string>(
    window.logonPassword ? window.logonPassword : EMPTY_STRING
  );
  const [logonPasswordNew, setLogonPasswordNew] =
    useState<string>(EMPTY_STRING);
  const [logonPasswordVerify, setLogonPasswordVerify] =
    useState<string>(EMPTY_STRING);
  const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING);
  const isB2B: boolean = mySite?.isB2B ? mySite.isB2B : false;
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordActual, setShowPasswordActual] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] = useState(false);
  const handleCloseModalLogin = props.handleCloseModalLogin;
  const { setIsOpen, setOpenModal } = useContext(ModalContext);

  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  /**
   * Form validation function
   * Return true when all mandatory field has been entered and are valid
   * else false
   */

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordActual = () => {
    setShowPasswordActual(!showPasswordActual);
  };

  const handleShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };

  const handleShowPasswordNewConfirm = () => {
    setShowPasswordNewConfirm(!showPasswordNewConfirm);
  };

  const canContinue = (isUpdate = false): boolean => {
    var flag: boolean = false;
    if (errorMessageKey) {
      if (
        (email.trim() !== EMPTY_STRING || isUpdate) &&
        logonPassword !== EMPTY_STRING &&
        logonPasswordNew !== EMPTY_STRING &&
        logonPasswordVerify !== EMPTY_STRING
      ) {
        const isEmail = isB2B ? true : addressUtil.validateEmail(email);
        flag =
          isEmail && logonPasswordNew.trim() === logonPasswordVerify.trim();
      }
    } else {
      if (email.trim() !== EMPTY_STRING && logonPassword !== EMPTY_STRING) {
        flag = isB2B ? true : addressUtil.validateEmail(email);
      }
    }
    // console.log(flag);
    return flag;
  };
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();

  const handleSubmitLogin = (props: any) => {
    setIsOpen(true);
    window.user = email;
    window.logonPassword = logonPassword;
    window.resetPass = true;
    setOpenModal(true);

    let number = Math.random();
    let numberToString = number.toString();
    let lastCharacter = numberToString[numberToString.length - 1];
    // console.log(random, "Math.random()");

    props.preventDefault();

    async function handleLogin() {
      try {
        const response = await dispatch(
          userAction.LOGIN_REQUESTED_ACTION({
            body: {
              logonId: email,
              logonPassword,
            },
            ...payloadBase,
          })
        );

        console.log("Respuesta:", response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    handleLogin();
  };
  const handleLogonAndChangePasswordSubmit = (props: any) => {
    setLoading(true);
    props.preventDefault();

    dispatch(
      userAction.LOGON_AND_CHANGE_PASSWORD_ACTION({
        body: {
          logonId: window.user,
          logonPassword,
          logonPasswordNew,
          logonPasswordVerify,
        },
        [SKIP_WC_TOKEN_HEADER]: true,
        skipErrorSnackbar: true,
        ...payloadBase,
      })
    );
    // console.log(...payloadBase,"payloadBase")
  };

  const goToSignInPage = () => {
    dispatch(RESET_ERROR_ACTION());
    history.push("/");
  };

  useEffect(() => {
    console.debug(window.user, "window");
  }, [logonPasswordVerify]);

  useEffect(() => {
    if (mySite) {
      if (mySite.isB2B) {
        setLogonInputProps({
          maxLength: 10,
          type: "text",
        });
      } else {
        setLogonInputProps({
          maxLength: 10,
          type: "text",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  useEffect(() => {
    //     console.log(passwordExpiredError,"passwordExpiredError en useEffect")
    //     console.log(passwordExpiredError.errorKey,"passwordExpiredError en useEffect")
    //     console.log(passwordExpiredError.errorCode,"passwordExpiredError en useEffect")
    // console.log(window.resetPass," window.resetPass"  )
    // console.log(mySite, "mySite")

    if (
      passwordExpiredError.errorKey &&
      passwordExpiredError.errorCode &&
      window.resetPass
    ) {
      // console.log(errorMessageKey,"errormessagekey")
      setErrorMessageKey(
        "error-message." +
          passwordExpiredError.errorKey +
          "_" +
          passwordExpiredError.errorCode
      );
      setLogonPassword(EMPTY_STRING);
      setLogonPasswordNew(EMPTY_STRING);
      setLogonPasswordVerify(EMPTY_STRING);
      // console.log(errorMessageKey,"errormessagekey")
    } else {
      setErrorMessageKey(EMPTY_STRING);
      window.user = email;
      window.logonPassword = "";
      setLogonPassword(EMPTY_STRING);
      // console.log(errorMessageKey,"errormessagekey desde else")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordExpiredError]);

  useEffect(() => {
    return () => {
      //dispatch(RESET_ERROR_ACTION());
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loginStatus === true) {
    localStorage.setItem(window.user, "0");
    window.resetPass = false;
    //GA360
    if (mySite.enableGA) {
      AsyncCall.sendFormCompletionEvent("Sign In", {
        enableUA: mySite.enableUA,
        enableGA4: mySite.enableGA4,
      });
    }
    return <Redirect to={redirectRoute} />;
  } else {
    return (
      <>
        {errorMessageKey ? (
          <>
            <StyledTypography
              component="h1"
              variant="h4"
              className="bottom-margin-1">
              {t("SignIn.ChangPassword")}
            </StyledTypography>
            {/*<StyledTypography component="p" className="error">
              {t(errorMessageKey)}
            </StyledTypography>*/}
            <form
              noValidate
              name="changePasswordForm"
              onSubmit={handleLogonAndChangePasswordSubmit}>
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPasswordActual ? "text" : "password"}
                maxLength="100"
                label={t("SignIn.CurrentPassword")}
                onChange={(e) => setLogonPassword(e.target.value)}
                value={logonPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classesLogin.iconEye}>
                      <IconButton
                        aria-label="close"
                        onClick={handleShowPasswordActual}
                        className={classesLogin.iconEye2}
                        tabIndex={-1}>
                        {!showPasswordActual ? (
                          <img src={visibilityIcon} />
                        ) : (
                          <img src={showPasswordIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classesLogin.border}
              />
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t("SignIn.NewPassword")}
                type={showPasswordNew ? "text" : "password"}
                placeholder={EMPTY_STRING}
                name="logonPasswordNew"
                maxLength="100"
                value={logonPasswordNew}
                onChange={(e) => setLogonPasswordNew(e.target.value)}
                className={classesLogin.border}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classesLogin.iconEye}>
                      <IconButton
                        aria-label="close"
                        onClick={handleShowPasswordNew}
                        className={classesLogin.iconEye2}
                        tabIndex={-1}>
                        {!showPasswordNew ? (
                          <img src={visibilityIcon} />
                        ) : (
                          <img src={showPasswordIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t("SignIn.PasswordVerify")}
                type={showPasswordNewConfirm ? "text" : "password"}
                placeholder={EMPTY_STRING}
                name="logonPasswordVerify"
                value={logonPasswordVerify}
                maxLength="100"
                onChange={(e) => setLogonPasswordVerify(e.target.value)}
                error={
                  logonPasswordVerify.trim().length > 0 &&
                  logonPasswordNew.trim() !== logonPasswordVerify.trim()
                }
                helperText={
                  logonPasswordVerify.trim().length > 0 &&
                  logonPasswordNew.trim() !== logonPasswordVerify.trim()
                    ? t("SignIn.Msgs.PasswordNotMatch")
                    : EMPTY_STRING
                }
                className={classesLogin.border}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classesLogin.iconEye}>
                      <IconButton
                        aria-label="close"
                        onClick={handleShowPasswordNewConfirm}
                        className={classesLogin.iconEye2}
                        tabIndex={-1}>
                        {!showPasswordNewConfirm ? (
                          <img src={visibilityIcon} />
                        ) : (
                          <img src={showPasswordIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledBox mt={2}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <StyledButton
                    color={"primary"}
                    type="submit"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setLoading(true);
                    // }}
                    disabled={!canContinue(true)}
                    className={
                      !canContinue(true) ? null : classesLogin.btnContinue
                    }>
                    {t("SignIn.Confirm")}
                  </StyledButton>
                )}
                <StyledButton
                  color="secondary"
                  style={{ float: "right" }}
                  onClick={() => goToSignInPage()}
                  className={classesLogin.btnCancel}>
                  {t("SignIn.Cancel")}
                </StyledButton>
              </StyledBox>
            </form>
          </>
        ) : (
          <>
            <Grid container className={classesLogin.divSepBot}>
              <TextLogin>INICIA SESIÓN</TextLogin>
            </Grid>
            <form onSubmit={handleSubmitLogin} noValidate>
              <Grid className={classesLogin.divInput}>
                <Grid className={classesLogin.divLabelLogin}>
                  <LoginLabels>Celular o número de distribuidor </LoginLabels>
                </Grid>
                <StyledTextField
                  type="tel"
                  inputmode="numeric"
                  pattern="[0-9]"
                  autoFocus
                  className={classesLogin.loginTextBox}
                  variant="outlined"
                  required
                  fullWidth
                  name="email"
                  id={"Input_Distributor_Num"}
                  onChange={(e) => {
                    let regex = /^[0-9]*$/;
                    if (regex.test(e.target.value)) {
                      setEmail(e.target.value);
                      // setLogonid(e.target.value)
                    }
                  }}
                  value={email}
                  inputProps={logonInputProps}
                  error={isB2B ? false : !addressUtil.validateEmail(email)}
                  helperText={
                    !addressUtil.validateEmail(email) && !isB2B
                      ? t("SignIn.Msgs.InvalidFormat")
                      : EMPTY_STRING
                  }
                />
              </Grid>
              <Grid className={classesLogin.divInput}>
                <Grid className={classesLogin.divLabelLogin}>
                  <LoginLabels>Contraseña</LoginLabels>
                </Grid>
                <Grid className={classesLogin.divPassword}>
                  <StyledTextField
                    className={classesLogin.loginTextBox}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    autoComplete="current-password"
                    onChange={(e) => setLogonPassword(e.target.value)}
                    value={logonPassword}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classesLogin.iconEye}>
                          <IconButton
                            aria-label="close"
                            onClick={handleShowPassword}
                            className={classesLogin.iconEye2}
                            tabIndex={-1}>
                            {!showPassword ? (
                              <img src={visibilityIcon} />
                            ) : (
                              <img src={showPasswordIcon} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid className={classesLogin.divForgot}>
                  <Link to={"/forgot-password"} onClick={handleCloseModalLogin}>
                    <ForgotLabel>Olvidé mi contraseña</ForgotLabel>
                  </Link>
                </Grid>
              </Grid>
              <StyledBox>
                <LoginButton
                  type="submit"
                  disabled={!canContinue()}
                  id={"Btn_Login"}
                  className={"login-process-button vertical-margin-2"}>
                  {t("INICIAR SESIÓN")}
                </LoginButton>
                {!isB2B && (
                  <>
                    <Divider className="vertical-margin-2" />
                    <StyledTypography
                      variant="body1"
                      className="bottom-margin-1  top-margin-3">
                      {t("SignIn.noAccount")}
                    </StyledTypography>
                    <StyledButton
                      color="secondary"
                      onClick={() => showRegistrationPage(false)}
                      className="login-process-button  bottom-margin-1">
                      {t("SignIn.registerNow")}
                    </StyledButton>
                  </>
                )}
              </StyledBox>
            </form>
          </>
        )}
      </>
    );
  }
}

export { SignInLayout };
