
/**
 * Custom getAvailability util
 */
//Standard libraries

import getAvailabilityService from "../apis/transaction/getavailability.service";

let _storeId:string = "";

const getAvailabilityUtil = {
  setStoreId(storeId:string) {
      _storeId = storeId;
  },

  /**
   * Look if the current product is avaialble or not.
   * 
   * @param serviceResponse the getAvailability service response
   * @returns a boolean
   */
  async isAvailable(serviceResponse: any): Promise<any> {
    let available = false;
    let availableData: any = []
    try{
      if (serviceResponse.data?.response[0].buyable === true) {
        available = true;
        availableData.push({available:true},serviceResponse.data?.response[0])
      }
      if (serviceResponse.data?.response[0].buyable === false) {
        available = false;
        availableData.push({available:false},serviceResponse.data?.response[0])
      }
    }catch(err){
      available = false;
      availableData.push({available:false})
    }

    //return Promise.resolve(available);
    return Promise.resolve(availableData);
  },

  async getAvailabilityBySku(sku: string) {
    let serviceResp = await getAvailabilityService.getAvailabilityBySku({
        storeId: _storeId,
        sku
      });
    return getAvailabilityUtil.isAvailable(serviceResp);
  },

  async handleAvailability(skuAndQuantities:any, payloadBase:any, contract:any) {
    const products = {
      partnumber: Array.from(skuAndQuantities.keys()),
      quantity: Array.from(skuAndQuantities.values()),
      contractId: contract,
      ...payloadBase,
    };

    const availableProducts = {
      partnumber: [] as any,
      quantity: [] as any,
      data: [] as any,
      contractId: contract,
      ...payloadBase,
    };

    const unavailableProducts = {
      partnumber: [] as any,
      data: [] as any,
    };

    for (let i = 0; i < products.partnumber.length; i++) {
      await this.getAvailabilityBySku(products.partnumber[i]).then((res) => {
        if (res[0].available) {
          availableProducts.partnumber.push(products.partnumber[i]);
          availableProducts.quantity.push(products.quantity[i]);
          availableProducts.data.push(res[1]);

        } else {
          unavailableProducts.partnumber.push(products.partnumber[i]);
          unavailableProducts.data.push(res[1]);
        }
      });
    }

    return {availableProducts, unavailableProducts};
  },

  /*
  * Functions to get available data by all skus pdp
  */
  
  async getAvailabilityAllSku(sku: string) {
    let serviceResp = await getAvailabilityService.getAvailabilityBySku({
        storeId: _storeId,
        sku
      });
    if(serviceResp.data) {
      return serviceResp.data.response;
    }
  },

  async handleAvailabilityAllSkus (skuAndQuantities:any, payloadBase:any, contract:any) {
      let skus = Array.from(skuAndQuantities.keys()).join(",");
      let partnumber = Array.from(skuAndQuantities.keys());
      let quantity =  Array.from(skuAndQuantities.values());

      const availabilityProducts = {
        partnumber: [] as any,
        quantity: [] as any,
        data:  [] as any,
        contractId: contract,
        ...payloadBase,
      };

      console.time("loop");
      for (let i = 0; i < partnumber.length; i++) {
        availabilityProducts.partnumber.push(partnumber[i]);
        availabilityProducts.quantity.push(quantity[i]);
      }
      console.timeEnd("loop");

      console.time("loop");
      let data = await this.getAvailabilityAllSku(skus);
      console.timeEnd("loop");
      availabilityProducts.data = data;
      return {availabilityProducts};
  }

  /*
   * End Functions to get available data by all skus pdp 
   */

};

export default getAvailabilityUtil;
