/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, {
  createContext,
  Dispatch,
  Suspense,
  useContext,
  useState,
} from "react";
import { BrowserRouter, useHistory, Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { renderRoutes } from "react-router-config";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import DistribuidorEnProceso from "./components/custom-widgets/DistribuidorEnProceso/DistribuidorEnProceso";
//Foundation libraries
import { site } from "./_foundation/constants/site";
import { initAxios } from "./_foundation/axios/axiosConfig";
import { initSite, useSite } from "./_foundation/hooks/useSite";
import LoginGuard from "./_foundation/guard/LoginGuard";
import {
  storageSessionHandler,
  localStorageUtil,
} from "./_foundation/utils/storageUtil";
import { LOCALE, STORE_ID } from "./_foundation/constants/common";
import storeService from "./_foundation/apis/transaction/store.service";
import { PRODUCTION } from "./_foundation/constants/common";
import { DistributorForm } from "./components/widgets/distributor-form/DistributorForm";

//Custom libraries
import { ROUTE_CONFIG } from "./configs/routes";
import {
  CommerceEnvironment,
  DISCOVER_FEATURE,
  EMPTY_STRING,
} from "./constants/common";
import { Header } from "./components/header";
import { Footer } from "./components/custom/styledFooter";
import { Extensions } from "./components/extensions";
import { useCSRForUser } from "./_foundation/hooks/useCSRForUser";
import SuccessMessageSnackbar from "./components/widgets/message-snackbar/SuccessMessageSnackbar";
import ErrorMessageSnackbar from "./components/widgets/message-snackbar/ErrorMessageSnackbar";
import { IFRAME_RESIZER } from "./_foundation/constants/csr";

//espot
import EspotCuentaRegresiva from "./components//custom/espot/DpSpots";
import ContenedorSpotPopUp from "./components/custom/espot/ContenedorSpotPopUp";
//Redux
import { forUserIdSelector, loginStatusSelector } from "./redux/selectors/user";
import { numItemsSelector } from "./redux/selectors/order";
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from "./redux/actions/user";
//UI
import { StyledWrapper } from "./components/StyledUI";
import {
  StyledGrid,
  StyledProgressPlaceholder,
} from "@hcl-commerce-store-sdk/react-component";
import "./App.scss";
import { makeStyles } from "@material-ui/core";

// Context
// To show ModalProductsOnCart
import { ModalProvider } from "../src/_foundation/context/ModalContext";

// import ModalContext from "../src/_foundation/context/ModalContext";

//GA360
//UA
import GTMDLService from "./_foundation/gtm/ua/gtmDataLayer.service";
//GA4
import GA4GTMDLService from "./_foundation/gtm/ga4/gtmDataLayer.service";
import Portal from "@material-ui/core/Portal";

import TagManager from "react-gtm-module";

//Custom Styles

import "../src/components/static-pages/css/home-page.scss";
import "../src/components/static-pages/css/distribuitor.scss";
import "../src/components/static-pages/css/help-center.scss";
import "../src/components/static-pages/css/static-page.scss";

//Custom Scripts
import "../src/components/static-pages/js/help-center";
import axios from "axios";

/*const tagManagerArgs = {
  gtmId: 'GTM-MTWJ3H6'
}
TagManager.initialize(tagManagerArgs)*/

/*PayPal Plus */
declare global {
  interface Window {
    PAYPAL: any;
    userAddresData: any;
    ppp: any;
    user: any;
    logonPassword: any;
    seoHref: any;
    dpvale: any;
    productsQuantities: any;
    productQI: any;
    resetPass: boolean;
    addres: any;
    selectedPage: any;
    isBack: boolean;
    filters: any;
    clearAll: boolean;
    clearPrice: boolean;
    orderSort: any;
    changeOrder: boolean;
    paymentMethod: any;
    urlBaseImg: any;
    clearSearchBar: any;
    mesesMp: any;
    mesesCdp: any;
    onlyBbva: any;
    fbq: any;
    showDpValeAlert: any;
    dpValeAlertAlreadyShown: any;
  }
}

window.urlBaseImg = "https://dpjye2wk9gi5z.cloudfront.net";

const ScrollToTop = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);
  return <></>;
};

const linkStyles = makeStyles((theme) => ({
  marginForm: {
    paddingLeft: "2px",
    width: "100%",
    paddingRight: "2px",
    display: "flex",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "@media (min-width: 768px)": {
      paddingLeft: "34px",
      paddingRight: "34px",
    },
    "@media (min-width: 1025px)": {
      paddingLeft: "62px",
      paddingRight: "62px",
    },
  },
}));

export const loginContext = createContext(() => {});

const App: React.FC = (props: any) => {
  const theme = useTheme();
  const xsWidth = useMediaQuery(theme.breakpoints.down("md"));
  const widgetName = getDisplayName(App);
  const styleClasses = linkStyles();
  const loggedIn = useSelector(loginStatusSelector);
  const forUserId = useSelector(forUserIdSelector);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite, storeDisplayName } = useSite();
  const { i18n } = useTranslation();
  const { receiveParentMessage } = useCSRForUser();
  const CancelToken = Axios.CancelToken;
  // console.log(mySite, "mySite");

  let cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const [discover, setDiscover] = React.useState<boolean>(false);

  const setTranslate = () => {
    /**
     * language preference priority
     * 1. user context, to be implemented with language toggle
     * 2. localStorage (saved for 30 days).
     * 3. store default language.
     */
    // TODO: language toggle, update user language, read language from userContext if it is registered user.
    if (mySite) {
      const locale =
        localStorageUtil.get(LOCALE)?.split("_").join("-") ||
        CommerceEnvironment.languageMap[mySite.defaultLanguageID]
          .split("_")
          .join("-");
      if (locale !== i18n.languages[0]) {
        i18n.changeLanguage(locale);
      }
    }
  };
  initAxios(dispatch);

  /**
   * Function to check Discover is enabled for store based on storeId
   *
   * @param storeID
   */
  const isDiscoverEnabled = (storeID: string) => {
    const payload = {
      storeId: storeID,
      ...payloadBase,
    };
    storeService
      .getStoreEnabledFeaturesList(payload)
      .then((res) => {
        if (res.data && res.data.resultList) {
          setDiscover(res.data.resultList.includes(DISCOVER_FEATURE));
        }
      })
      .catch((e) => {
        let error = e.toString();
        if (
          error.indexOf("401") > -1 &&
          window.location.pathname !== process.env.REACT_APP_EXPIRED_SESSION
        ) {
          window.location.href = "" + process.env.REACT_APP_EXPIRED_SESSION;
        }
      });
  };

  React.useEffect(() => {
    if (mySite) {
      dispatch(INIT_STATE_FROM_STORAGE_ACTION({ ...payloadBase }));
      storageSessionHandler.triggerUserStorageListener(() =>
        dispatch(LISTEN_USER_FROM_STORAGE_ACTION({ ...payloadBase }))
      );
      setTranslate();
      isDiscoverEnabled(mySite.storeID);
      //GA360
      GTMDLService.initializeGTM("GTM-MTWJ3H6", "", "");
    } else {
      initSite(site, dispatch);
    }
  }, [mySite, dispatch]);

  React.useEffect(() => {
    if (forUserId) {
      window[IFRAME_RESIZER] = {
        onMessage: receiveParentMessage,
      };
    } else {
      window[IFRAME_RESIZER] = undefined;
    }
  }, [forUserId]);

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  const baseName = process.env.REACT_APP_ROUTER_BASENAME
    ? {
        basename:
          process.env.REACT_APP_ROUTER_BASENAME == "Sapphire"
            ? "/"
            : process.env.REACT_APP_ROUTER_BASENAME,
      }
    : {};

  // public url path for accessing discoverui.js file.
  const publicUrlPath = process.env.PUBLIC_URL
    ? process.env.PUBLIC_URL
    : EMPTY_STRING;

  const [openLoginModal, setOpenLoginModal] = useState(false);
  //open login modal from body components
  function handleModalLogin() {
    setOpenLoginModal(true);
  }

  const [pageLoaded, setPageLoaded] = useState(window.location.pathname);
  function hashHandler() {
    let oldHash = "";
    if (pageLoaded != "/") {
      oldHash = pageLoaded.split("/")[pageLoaded.split("/").length - 1];
      oldHash = oldHash === "" || oldHash === "home" ? "/" : oldHash;
    } else {
      oldHash = pageLoaded;
    }
    setInterval(detect, 500);

    function detect() {
      if (oldHash != window.location.pathname) {
        oldHash = window.location.pathname;
        if (oldHash != "/") {
          let path = oldHash.split("/")[oldHash.split("/").length - 1];
          setPageLoaded(path);
        } else {
          setPageLoaded(window.location.pathname);
        }
      }
    }
  }

  React.useEffect(() => {
    hashHandler();
  }, [pageLoaded]);

  if (!window.location.href.includes("searchTerm")) {
    window.clearSearchBar = "";
  }
  function moveToForm() {
    // console.log("entre aqui sin razón");
    let divForm = document.getElementById("distributor");
    if (divForm != null) {
      divForm.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    mySite && (
      <>
        {pageLoaded == "ventaporcatalogo" && loggedIn === false && (
          <div className="buton-mobile-unirte" id="content-tarjeta">
            <div
              onClick={moveToForm}
              style={{
                textAlign: "center",
                font: "normal normal normal 18px/22px Fjalla One",
                letterSpacing: "0.72px",
                color: "#FFFFFF",
              }}>
              CLICK AQUÍ PARA UNIRTE
            </div>
          </div>
        )}
        <ModalProvider>
          <loginContext.Provider value={handleModalLogin}>
            <BrowserRouter {...baseName}>
              {/* pop-up-inferior-izquierdo */}
              <ContenedorSpotPopUp />
              <StyledWrapper data-testid="app-wrapper">
                <Portal>
                  <SuccessMessageSnackbar />
                  <ErrorMessageSnackbar />
                </Portal>
                <StyledGrid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  className="full-viewport-height">
                  <StyledGrid item xs={false} className="container-header">
                    {pageLoaded == "cart" ||
                    pageLoaded == "checkout" ||
                    pageLoaded == "shipping" ||
                    pageLoaded == "order-confirmation" ? null : (
                      <EspotCuentaRegresiva
                        espotName={"CenefaCuentaRegresiva"}
                      />
                    )}{" "}
                    {/* {loggedIn && pageLoaded != "cart" && ( */}
                    {/* // <DistribuidorEnProceso />
                    // )} */}
                    <Header
                      loggedIn={loggedIn}
                      callLoginFromAnotherComponent={openLoginModal}
                      setOpenLoginFromAnotherComponent={setOpenLoginModal}
                    />
                    <LoginGuard />
                    <ScrollToTop />
                    <Helmet>
                      <meta charSet="utf-8" />
                      <title>{`${storeDisplayName}`}</title>
                      {discover && (
                        <script
                          src={`${publicUrlPath}/discover/discoverui.js?q=${Date.now()}`}
                          type="text/javascript"
                          async
                        />
                      )}
                      {window[IFRAME_RESIZER] && (
                        <script
                          src="/iframeResizer.contentWindow.min.js"
                          type="text/javascript"
                          async
                        />
                      )}
                    </Helmet>
                  </StyledGrid>
                  <StyledGrid item xs className="full-width">
                    <Suspense
                      fallback={
                        <StyledProgressPlaceholder className="vertical-padding-20" />
                      }>
                      {renderRoutes(
                        mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C
                      )}
                    </Suspense>
                  </StyledGrid>
                  <StyledGrid item xs style={{ width: "100%" }}>
                    <Footer />
                  </StyledGrid>
                  {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
                </StyledGrid>
              </StyledWrapper>
            </BrowserRouter>

            {/* cuenta regresiva */}
            <Helmet>
              <script type="text/javascript">
                {` 
                  let divCountDown = document.getElementById("countdown");

                  compruebaDivCountDown();

                    async function compruebaDivCountDown() {
                      do {
                        await timeout(1000);
                        await leerContenedorFooter();
                      } while ( elementFooter == null);
                    };

                    function timeout(ms) {
                      return new Promise(resolve => setTimeout(resolve, ms));
                    }

                    function leerContenedorFooter() {
                      elementFooter = document.getElementsByClassName("countdown");
                        document.addEventListener("DOMContentLoaded",
                        contador())
                      return false;

                    }

                  function contador(){

                    if(document.getElementById("endDate") != null){
                        var input = document.getElementById("endDate");  
                        function parseDate(dateString) {
                          var dateAndTime = dateString.split(" ");
                          var date = dateAndTime[0].split("/");
                          var time = dateAndTime[1].split(":");
                      
                          var parsedDate = new Date(date[2], date[1] - 1, date[0]);
                          parsedDate.setHours(time[0]);
                          parsedDate.setMinutes(time[1]);
                      
                          return parsedDate;
                      }
  
                    var clientDate = parseDate(input.value);
                    var end = new Date(clientDate);
                    if (input.value === "") {
                          document.getElementById("countdown").style.display = "none";
                    }	
                    setInterval(function() {
                        var now = new Date();
                        
                        var diff = end.getTime() - now.getTime();
                        
                        if (diff <= 0) {
                            
                          document.getElementById("countdown").style.display = "none";
                          clearTimeout();
                        } else {
                            var days = Math.floor(diff / (1000 * 60 * 60 * 24));
                            var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                            var minutes = Math.floor((diff / 1000 / 60) % 60);
                            var seconds = Math.floor((diff / 1000) % 60);
                            
                            document.getElementById("days").innerHTML = days
                            document.getElementById("dias").innerHTML = "D"
                            document.getElementById("hours").innerHTML = hours
                            document.getElementById("horas").innerHTML = "H"
                            document.getElementById("minutes").innerHTML = minutes
                            document.getElementById("minutos").innerHTML = "M"
                            document.getElementById("seconds").innerHTML = seconds
                            document.getElementById("segundos").innerHTML = "S"                                                
                        }
                    }, 1000);
                } }
                `}
              </script>
            </Helmet>
            <Helmet>
              <script type="text/javascript">
                {`
              var count = 0;
              var links = document.getElementsByClassName("isLink")[0];

              compruebaLinks();

              async function compruebaLinks() {
                while(links == null || links == undefined) {
                  if(count > 15) {
                    break;
                  }
                  await timeoutLinks(1000);
                  await leerLinks();
                };
              };

              function timeoutLinks(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
              }

              function leerLinks() {
                count += 1;
                links = document.getElementsByClassName("isLink")[0];
                if( links != null ) {
                    var makeLinks = Array.from(document.getElementsByClassName("isLink"));
                    makeLinks.map((item, index) => {
                      let divParent = item.parentNode;
                      let url = item.getAttribute("data-url");
                      let text = item.getAttribute("data-text");
                      let child = item.childNodes.length;
                      let linkTag = document.createElement("a");
                      //linkTag.setAttribute("key", "isLink-"index);
                      linkTag.href = (url == null) ? "#" : url;
                      linkTag.text = (text == null) ? "" : text;
                      linkTag.appendChild(item);
                      divParent.appendChild(linkTag);
                    })
                }
                return false;
              };
            `}
              </script>
            </Helmet>
            <Helmet>
              <script type="text/javascript">
                {`
                var homeSearchTerm = document.getElementById("searchTerm");
                compruebaSpot();
              
                async function compruebaSpot() {
                  do {
                    await timeoutSpot(1000);
                    await leerContenedorSpotPopUp();
                  } while ( homeSearchTerm == null );
                };

                function timeoutSpot(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function leerContenedorSpotPopUp() {
                  home = document.getElementsByClassName("header-branding");
                  if( home != null   ) {
                      laodSpotPopUp();
                  }
                  return false;
                };

                laodSpotPopUp() 

                function laodSpotPopUp() {
                  let close = document.getElementById("cerrar");
                  close.addEventListener("click", closeModalSpot,false);
                  moveToCatalogPage()            
                }

                function moveToCatalogPage(){
                  let linksSpot = Array.from(document.getElementsByClassName("btn-link-catalogo"));
                  linksSpot.map((element,index) => {
                    let idbtnSpot = element.getAttribute("id");
                    let urlSpot = element.getAttribute("data-url");
                    let a_tag_spot = document.createElement("a");
                    a_tag_spot.setAttribute('target',"_blank");
                    a_tag_spot.setAttribute('class',"btn-link-catalogo-1");
                    a_tag_spot.href = urlSpot;
                    element.appendChild(a_tag_spot);
                  }); 
                } 

                 function closeModalSpot(){
                   let modalPopUp = document.getElementById("content-tarjeta");
                  modalPopUp.style.display = "none";
                 }                
        
              `}
              </script>
            </Helmet>
            <Helmet>
              <script type="text/javascript">
                {`
              let close = document.getElementById("cerrar");
              close.addEventListener("click", closeModal,false);
              function closeModal(){
                let modal = document.getElementById("content-tarjeta");
                modal.style.display = "none";
              }

              var link = document.getElementById("link_popup");
              var os = getMobileOperatingSystem();
              if( os == "Android" ) {
                link.href = "https://play.google.com/store/apps/details?id=mx.grupodp.dpdirecto";
              }
              if( os == "iOS" ) {
                link.href = "https://apps.apple.com/us/app/dpdirecto/id1479483636";
              }

              function getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                if (/android/i.test(userAgent)) {
                    return "Android";
                }
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }
                return "unknown";
              }
            `}
              </script>
            </Helmet>
            {pageLoaded == "/" ? (
              <Helmet>
                <script type="text/javascript">
                  {`
                var home = document.getElementById("promo_slider_desktop");
                var brand = document.getElementById("slider_brands_content");
                var fecha = new Date();
                var newfecha = fecha.setSeconds(fecha.getSeconds() + 20);

                comprueba();
              

                async function comprueba() {
                  do {
                    await timeout(1000);
                    await leerContenedor();
                  } while ( home == null || brand == null);
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function leerContenedor() {
                  home = document.getElementById("promo_slider_desktop");
                  brand = document.getElementById("slider_brands_content");
                  if( home != null && brand != null ) {
                      setLinksDesktopPromotional();
                      setLinksMobilePromotional();
                      setLinksBrands();
                      createLinks();
                      createCatalogLinkHome();
                      createAppLinksHome();
                      addLinksCatalogStores();
                      createLinksBottomCards();
                      laodSpot();
                  }
                  return false;
                };

                function laodSpot() {
                  setInterval(checkModal, 1000);
                  let close = document.getElementById("cerrar");
                  close.addEventListener("click", closeModal,false);
                  moveToCatalogPage()    
                }

                function moveToCatalogPage(){
                  let links = Array.from(document.getElementsByClassName("btn-link-catalogo"));
                  let elements = document.querySelectorAll(".btn-link-catalogo");
                  links.map((element,index) => {
                    let idbtn = element.getAttribute("id");
                    let url = element.getAttribute("data-url");
                    let a_tag = document.createElement("a");
                    a_tag.setAttribute('target',"_blank");
                    a_tag.setAttribute('class',"btn-link-catalogo-1");
                    a_tag.href = url;
                    element.appendChild(a_tag);
                  }); 
                } 

                function checkModal(){
                  let now = new Date().getTime();
                  let distanceQuitar = newfecha - now;
                  if(distanceQuitar <= 0){
                    closeModal();
                  }
                }

                function closeModal(){
                  let modal = document.getElementById("content-tarjeta");
                  modal.style.display = "none";
                }

                function setLinksDesktopPromotional() {
                  let promotionals = Array.from(document.getElementsByClassName("promo-slider"));
                  let psd = document.getElementById("promo_slider_desktop");
                  let div_swiper = document.createElement("div");
                  let div_wrapper = document.createElement("div");
                  let div_pagination = document.createElement("div");
  
                  psd.innerHTML = "";
                  div_swiper.className = "swiper mySwiper";
                  div_wrapper.id = "desktop_promotional_swiper_wrapper";
                  div_wrapper.className = "swiper-wrapper";
                  div_pagination.className = "swiper-pagination";
                  
                  promotionals.map((element, index) => {
                    element.style.display = "block";
                    let div_slider = document.createElement("div");
                    let img_link = document.createElement("a");
                    let url_a = element.getAttribute("data-url");
                    
                    div_slider.className = "swiper-slide";
                    img_link.href = url_a;
                    img_link.appendChild(element);
                    div_slider.appendChild(img_link);
                    div_wrapper.appendChild(div_slider);
                  });
  
                  div_swiper.appendChild(div_wrapper);
                  div_swiper.appendChild(div_pagination);
                  psd.appendChild(div_swiper);

                  sliderDesktopPromotional();
                }
                
                function setLinksMobilePromotional() {
                  let mobile_promotionals = Array.from(document.getElementsByClassName("promo-slider-mobile"));
                  let psm = document.getElementById("promo_slider_mobile");
                  let div_swiper_m = document.createElement("div");
                  let div_wrapper_m = document.createElement("div");
                  let div_pagination_m = document.createElement("div");
                  
                  psm.innerHTML = "";
                  div_swiper_m.className = "swiper promotional_mobile";
                  div_wrapper_m.id = "mobile_promotional_swiper_wrapper";
                  div_wrapper_m.className = "swiper-wrapper";
                  div_pagination_m.className = "swiper-pagination";
  
                  mobile_promotionals.map((element_m, index_m) => {
                    element_m.style.display = "block"
                    let div_slider_m = document.createElement("div");
                    let img_link_m = document.createElement("a");
                    let url_a_m = element_m.getAttribute("data-url");
  
                    div_slider_m.className = "swiper-slide";
                    img_link_m.href = url_a_m;
                    img_link_m.appendChild(element_m);
                    div_slider_m.appendChild(img_link_m);
                    div_wrapper_m.appendChild(div_slider_m);
                  });
  
                  div_swiper_m.appendChild(div_wrapper_m);
                  div_swiper_m.appendChild(div_pagination_m);
                  psm.appendChild(div_swiper_m);

                  sliderMobilePromotional();
                }

                function setLinksBrands() {
                  let brandsElements = Array.from(document.getElementsByClassName("img100"));
                  let bs = document.getElementById("slider_brands_content");
                  let div_swiper = document.createElement("div");
                  let div_wrapper = document.createElement("div");
                  
                  bs.innerHTML = "";
                  div_swiper.className = "swiper swiper-container-brands";
                  div_wrapper.id = "brands_swiper_wrapper";
                  div_wrapper.className = "swiper-wrapper";
                  
                  brandsElements.map((element, index) => {
                    let div_slider = document.createElement("div");
                    let brand_link = document.createElement("a");
                    let url_brand = element.getAttribute("data-url");
                    
                    div_slider.className = "swiper-slide";
                    brand_link.href = url_brand;
                    brand_link.appendChild(element);
                    div_slider.appendChild(brand_link);
                    div_wrapper.appendChild(div_slider);
                  });
                  
                  div_swiper.appendChild(div_wrapper);
                  bs.appendChild(div_swiper);

                  sliderBrands();
                }

                function sliderDesktopPromotional() {
                  var swiper = new Swiper(".mySwiper", {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      loop: true,
                      autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                      },
                      pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }
                  });
                }

                function sliderMobilePromotional() {
                  var swiper_mobile = new Swiper(".promotional_mobile", {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      loop: true,
                      autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                      },
                      pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }
                  });
                }

              function sliderBrands() {
                var swiper_brands = new Swiper('.swiper-container-brands', {
                  slidesPerView: 5,
                  spaceBetween: 10,
                  loop: true,
                  autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                  },
                  breakpoints: {
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 10
                    },
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    },
                    768: {
                      slidesPerView: 8,
                      spaceBetween: 10
                    },
                    1024: {
                      slidesPerView: 11,
                      spaceBetween: 10
                    }
                  }
                });
              }

              function createCatalogLinkHome() {
                  
                  let catalogLinks = Array.from(document.getElementsByClassName("btnImgGallery"));
                  let catalogContent = document.getElementsByClassName("cards-galery")[0];
                  catalogLinks.map((catalogLink, index) => {
                    let url = catalogLink.getAttribute("data-url");
                    let a_tag = document.createElement("a");
                    a_tag.href = url;
                    a_tag.appendChild(catalogLink);

                    let div_catalog = document.createElement("div");
                    div_catalog.className = "card-galery";
                    div_catalog.appendChild(a_tag);
                    catalogContent.appendChild(div_catalog);
                  })
              }

              function createAppLinksHome() {
                let div = document.getElementsByClassName("download-app-text")[0];
                let links = Array.from(document.getElementsByClassName("button-download-app"));

                links.map((element, index) => {
                    let url = element.getAttribute("data-url");
                    let link = document.createElement("a");
                    link.href = url;
                    link.appendChild(element);
                    div.appendChild(link);
                });
              }

              function addLinksCatalogStores() {
                let store_catalogs = Array.from(document.getElementsByClassName("img-catalog-stores"));
                let div_content = document.getElementById("store_catalog_content");

                store_catalogs.map((store_catalog, index) => {
                  let link_store = document.createElement("a");
                  link_store.href = store_catalog.getAttribute("data-url");
                  link_store.appendChild(store_catalog);

                  let div_store = document.createElement("div");
                  div_store.className = "catalog";
                  div_store.appendChild(link_store);
                  div_content.appendChild(div_store);
                });
              }

              function createLinks() {
                let links = Array.from(document.getElementsByClassName("btnlinkhome"));
                let elements = document.querySelectorAll(".btnlinkhome");
                links.map((element,index) => {
                  let idbtn = element.getAttribute("id");
                  let url = element.getAttribute("data-link");
                  let text = element.getAttribute("data-text");
                  let a_tag = document.createElement("a");
                  element.setAttribute('key',index);
                  if (idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == false) {
                    a_tag.addEventListener('click', openRegister);
                  } else {
                    a_tag.href = url;
                  }

                  if(idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == true) {
                    a_tag.href = "/dashboard";
                    a_tag.text = "MI CUENTA";
                  } else {
                    a_tag.text = text;
                  }
                  a_tag.setAttribute('key',"btn-"+index);
                  element.appendChild(a_tag);
                }); 
              }

              function createLinksBottomCards() {
                let links = Array.from(document.getElementsByClassName("btn-distribuidor"));
                links.map((element,index) => {
                    let a_tag = document.createElement("a");
                    let idbtn = element.getAttribute("id");
                    let url = element.getAttribute("data-url");
                    let text = element.getAttribute("data-text");
                    
                    element.setAttribute('key',"btn-bottomcard-"+index);
                    if(idbtn == "btn_partner") {
                      if(${loggedIn} == false) {
                        a_tag.href= "javascript:void(0)"
                        a_tag.addEventListener('click', openRegister);
                      } else {
                        a_tag.href = "/dashboard";
                      }
                    } else {
                        a_tag.href = url;
                    }
                    a_tag.style.cursor = "pointer";
                    
                    if(${loggedIn == true} && idbtn == "btn_partner") {
                      a_tag.text = "MI CUENTA";
                    } else {
                      a_tag.text = text;
                    }
                    
                    a_tag.setAttribute('key',"btn-"+index);
                    element.appendChild(a_tag);
                });
              }

              function openRegister() {
                let btn_register = document.getElementById("btn_register");
                let btn_menu_mobile = document.getElementById("btn_menu_mobile");

                if(btn_register != null) {
                  btn_register.click();
                } else {
                  btn_menu_mobile.click();
                  let menu_mobile = document.getElementById("menu_mobile");
                  menu_mobile.style.display = "none";
                  let btn_register_mobile = document.getElementById("btn_register_mobile");
                  btn_register_mobile.click();
                }
              }
              `}
                </script>
              </Helmet>
            ) : pageLoaded == "ventaporcatalogo" ? (
              <>
                <Helmet>
                  <script type="text/javascript">
                    {`
              var brand = document.getElementById("slider_brands_content");
              var preguntasFreuentes = document.getElementById("preguntas-frecuentes-distribuidor")
              var testimonios = document.getElementsByClassName("testimonialSlider");
             elementoTitulo = document.getElementsByClassName("title-unete");

             compruebaDistribuidor();  
             compruebaElemento();
             async function compruebaElemento() {
              do {
                await timeout(1000);
                await leerElemento();
              } while ( elementoTitulo[0] == null);
            };

                    async function compruebaDistribuidor() {
                      do {
                        await timeout(1000);
                        await leerContenedorDistribuidor();
                      } while ( testimonios[0] == null || brand == null || preguntasFreuentes == null );
                    };

                    function timeout(ms) {
                      return new Promise(resolve => setTimeout(resolve, ms));
                    }

                    function leerElemento(){
                      elementoTitulo = document.getElementsByClassName("title-unete");
                      if(elementoTitulo[0] != null) {
                        setTimeout(()=>{            
                          setLinksDesktopPromotional();
                          setLinksMobilePromotional();
                          checkButtonInView();
                        }, 1000)
                      } 
                      return false
                    }
      
                    function leerContenedorDistribuidor() {
                      testimonios = document.getElementsByClassName("testimonialSlider");
                      brand = document.getElementById("slider_brands_content");
                      preguntasFreuentes = document.getElementById("preguntas-frecuentes-distribuidor")
                      if(testimonios[0] != null && brand != null ) {
                          createLinks();
                          createAppLinks();
                          setLinksBrands();
                          createHideShowFunction();
                          loadSwiperClients();
                      }
                      return false;
                    };

                    function setLinksBrands() {
                      let brandsElements = Array.from(document.getElementsByClassName("img100"));
                      let bs = document.getElementById("slider_brands_content");
                      let div_swiper = document.createElement("div");
                      let div_wrapper = document.createElement("div");
                      
                      bs.innerHTML = "";
                      div_swiper.className = "swiper swiper-container-brands";
                      div_wrapper.id = "brands_swiper_wrapper";
                      div_wrapper.className = "swiper-wrapper";
                      
                      brandsElements.map((element, index) => {
                        let div_slider = document.createElement("div");
                        let brand_link = document.createElement("a");
                        let url_brand = element.getAttribute("data-url");
                        
                        div_slider.className = "swiper-slide";
                        // console.log(url_brand, "url_brand")

                        if(url_brand != null){
                        brand_link.href = url_brand;}
                        brand_link.appendChild(element);
                        div_slider.appendChild(brand_link);
                        div_wrapper.appendChild(div_slider);
                      });
                      
                      div_swiper.appendChild(div_wrapper);
                      bs.appendChild(div_swiper);
    
                      sliderBrands();
                    }


                    function setLinksDesktopPromotional() {
                      if (document.getElementsByClassName("promo-slider") == null){
                        setLinksDesktopPromotional()
                      }

                      let promotionals = Array.from(document.getElementsByClassName("promo-slider"));
                      let psd = document.getElementById("promo_slider_desktop");
                      let div_swiper = document.createElement("div");
                      let div_wrapper = document.createElement("div");
                      let div_pagination = document.createElement("div");
      
                      psd.innerHTML = "";
                      div_swiper.className = "swiper mySwiper height-100";
                      div_wrapper.id = "desktop_promotional_swiper_wrapper";
                      div_wrapper.className = "swiper-wrapper";
                      div_pagination.className = "swiper-pagination";
                      
                      promotionals.map((element, index) => {
                        element.style.display = "block";
                        let div_slider = document.createElement("div");
                        let img_link = document.createElement("a");
                        let url_a = element.getAttribute("data-url");
                        if(url_a === "#distributor"){
                          url_a = "javascript:moveToForm()"
                        }
                        
                        div_slider.className = "swiper-slide";
                        img_link.href = url_a;
                        img_link.appendChild(element);
                        div_slider.appendChild(img_link);
                        div_wrapper.appendChild(div_slider);
                      });
      
                      div_swiper.appendChild(div_wrapper);
                      div_swiper.appendChild(div_pagination);
                      psd.appendChild(div_swiper);
    
                      sliderDesktopPromotional();
                    }
                    
                    function createHideShowFunction(){
                      let foundButtons = Array.from(document.querySelectorAll(".icono-hide-show"));
                      let foundButton = document.querySelector(".btn-hide-show-1");
                      if(foundButtons != null || foundButtons != undefined || foundButtons.length != 0) 
                      {
                        foundButtons.filter((button)=> {
                          button.addEventListener("click", function(e) {
                            // console.log(button, "button")
                            let classNa = button.className.match("btn-hide-show").input;
                            let number = classNa.split('')[classNa.length - 1];
                            let div = document.querySelector(".respuesta-distribuidor-" + number);
                            let iconoToggle = document.querySelector(".btn-hide-show-" + number);
                            let iconoToggleImg = document.querySelector(".btn-hide-show-" + number + " svg");
                            if (div?.className?.includes("hideAnswer")){
                              // console.log(div?.className, "div.className en el if")
                              console.log(classNa?.className, "classna"
                              )
                              // console.log(iconoToggle?.className?.includes("icono-hide-show"), "iconoToggle en el if")
                              iconoToggleImg.style.transition = "transform 0.6s ease";
                              iconoToggleImg.style.transform = "rotate(180deg)";
                              div?.classList?.remove("hideAnswer", "clase-hide")
                              div?.classList?.toggle("showAnswer","clase_display")
                              div.style.maxHeight = div.scrollHeight + "px";

                            } else{
                              // console.log(div?.className, "div.className en el else")
                              // console.log(iconoToggle?.className, "iconoToggle en el else")
                              iconoToggleImg.style.transform = "rotate(0deg)";
                               div?.classList?.remove("showAnswer","clase_display")
                               div?.classList?.toggle("hideAnswer", "clase-hide")
                               div.style.maxHeight = null;
                             }
                          }, false)}
                        )
                      }
                      return null;
                    }

                    function toggleClassRotate(e) {
                      if (this.className.includes("rotate")) {
                        this.classList.remove("rotate");
                      } else {
                        this.classList.add("rotate");
                      }
                    }

                    function setLinksMobilePromotional() {
                      if (document.getElementsByClassName("promo-slider-mobile") == null){
                        setLinksMobilePromotional()
                      }
                      let mobile_promotionals = Array.from(document.getElementsByClassName("promo-slider-mobile"));
                      let psm = document.getElementById("promo_slider_mobile");
                      let div_swiper_m = document.createElement("div");
                      let div_wrapper_m = document.createElement("div");
                      let div_pagination_m = document.createElement("div");
                      
                      psm.innerHTML = "";
                      div_swiper_m.className = "swiper promotional_mobile";
                      div_wrapper_m.id = "mobile_promotional_swiper_wrapper";
                      div_wrapper_m.className = "swiper-wrapper";
                      div_pagination_m.className = "swiper-pagination";
      
                      mobile_promotionals.map((element_m, index_m) => {
                        element_m.style.display = "block"
                        let div_slider_m = document.createElement("div");
                        let img_link_m = document.createElement("a");
                        let url_a_m = element_m.getAttribute("data-url");
                        if(url_a_m === "#distributor"){
                          url_a_m = "javascript:moveToForm()"
                        }
      
                        div_slider_m.className = "swiper-slide";
                        img_link_m.href = url_a_m;
                        img_link_m.appendChild(element_m);
                        div_slider_m.appendChild(img_link_m);
                        div_wrapper_m.appendChild(div_slider_m);
                      });
      
                      div_swiper_m.appendChild(div_wrapper_m);
                      div_swiper_m.appendChild(div_pagination_m);
                      psm.appendChild(div_swiper_m);
    
                      sliderMobilePromotional();
                    }

                    function sliderBrands() {
                      var swiper_brands = new Swiper('.swiper-container-brands', {
                        slidesPerView: 5,
                        spaceBetween: 10,
                        loop: true,
                        autoplay: {
                          delay: 2500,
                          disableOnInteraction: false
                        },
                        breakpoints: {
                          320: {
                            slidesPerView: 3,
                            spaceBetween: 10
                          },
                          640: {
                            slidesPerView: 5,
                            spaceBetween: 10
                          },
                          768: {
                            slidesPerView: 8,
                            spaceBetween: 10
                          },
                          1024: {
                            slidesPerView: 11,
                            spaceBetween: 10
                          }
                        }
                      });
                    }

                    function sliderDesktopPromotional() {
                      var swiper = new Swiper(".mySwiper", {
                          slidesPerView: 1,
                          spaceBetween: 0,
                          loop: true,
                          autoplay: {
                            delay: 10000,
                            disableOnInteraction: false,
                          },
                          pagination: {
                            el: ".swiper-pagination",
                            clickable: true,
                          },
                          navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                          }
                      });
                    }
    
                    function sliderMobilePromotional() {
                      var swiper_mobile = new Swiper(".promotional_mobile", {
                          slidesPerView: 1,
                          spaceBetween: 0,
                          loop: true,
                          autoplay: {
                            delay: 10000,
                            disableOnInteraction: false,
                          },
                          pagination: {
                            el: ".swiper-pagination",
                            clickable: true,
                          },
                          navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                          }
                      });
                    }

                    function loadSwiperClients() {
                      var swiper_testimonial = new Swiper(".testimonialSlider", {
                        slidesPerView: 3,
                        spaceBetween: 5,
                        watchOverflow: true,
                        a11y: false,
                        breakpoints: {
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 5
                          },
                          550: {
                            slidesPerView: 1,
                            spaceBetween: 5
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 5
                          },
                          1024: {
                            slidesPerView: 3,
                            spaceBetween: 5
                          },
                          1280: {
                            slidesPerView: 3,
                            spaceBetween: 5
                          },
                          1920: {
                            slidesPerView: 3,
                            spaceBetween: 5
                          }
                        },
                        navigation: {
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        },
                        pagination: {
                          el: ".swiper-pagination",
                          clickable: true,
                        },
                      });
                    }

                    function createLinks() {
                      if (document.getElementById("btn-distribuidor-0") == null) {
                        let links = document.getElementsByClassName("btn-distribuidor");
                        if(links.length == 0) {
                          createLinks();
                        }
                          Array.from(links).map((element,index) => {
                          element.setAttribute('key',"link-socio-"+index);
                          let a_tag = document.createElement("a");
                          let idbtn = element.getAttribute("id");
                          let url = element.getAttribute("data-url");
                          let text = element.getAttribute("data-text");

                          if(idbtn == "btn_partner") {
                              if(${loggedIn} == false) {
                                a_tag.addEventListener('click', openRegister);
                              } else {
                                a_tag.href = "/dashboard";
                              }
                            } else if(idbtn == "btn_benefit_distribuidor") {
                                a_tag.addEventListener('click', moveToDiv);
                            } else if(idbtn == "btn_banner_distribuidor" || idbtn == "btn_como_ser_distribuidor") {
                                a_tag.classList.toggle("btn-comenzar")
                                a_tag.addEventListener("click", () => moveToForm());
                            } else {
                                a_tag.href = url;
                            }
                            a_tag.style.cursor = "pointer";
                            if(${
                              loggedIn == true
                            } && (idbtn == "btn_partner")) {
                              a_tag.text = "MI CUENTA";  
                            } else {
                              a_tag.text = text;
                            }
                            a_tag.setAttribute('id',"btn-distribuidor-"+index);
                            a_tag.setAttribute('key',"btn-"+index);
                            element.appendChild(a_tag);
                        });
                      }
                    }
                    

                    function createLinksBottomCards() {
                      if (document.getElementById("btn-bottom-card-0") == null) {
                        let links = document.getElementsByClassName("btn-partner-content");

                        if(links.length <= 0) {
                          setTimeout(createLinksBottomCards(), 100);
                        }

                        Array.from(links).map((element,index) => {
                          element.setAttribute('key',"link-socio-"+index);
                          let linkButtom = document.createElement("a");
                          let idbtn = element.getAttribute("id");
                          let url = element.getAttribute("data-url");
                          let text = element.getAttribute("data-text");
                          
                          if(idbtn == "btn_partner") {
                            if(${loggedIn} == false) {
                              linkButtom.addEventListener('click', openRegister);
                              linkButtom.text = text;
                            } else if(${loggedIn} == true) {
                              linkButtom.text = "MI CUENTA";
                              linkButtom.href = "/dashboard";
                                
                            }
                          } else {
                              linkButtom.text = text;
                              linkButtom.href = url;
                          }
                          linkButtom.style.cursor = "pointer";
                          linkButtom.setAttribute('id', "btn-bottom-card-"+index);
                          linkButtom.setAttribute('key',"btn-bottom-card-"+index);
                          element.appendChild(linkButtom);
                        });
                      }
                    }

                    function openRegister() {
                      let btn_register = document.getElementById("btn_register");
                      let btn_menu_mobile = document.getElementById("btn_menu_mobile");

                      if(btn_register != null) {
                        btn_register.click();
                      } else {
                        btn_menu_mobile.click();
                        let menu_mobile = document.getElementById("menu_mobile");
                        menu_mobile.style.display = "none";
                        let btn_register_mobile = document.getElementById("btn_register_mobile");
                        btn_register_mobile.click();

                      }
                  }
                  
                  function moveToDiv() {
                      let div = document.getElementById("be_a_distribuitor");
                      if( div != null ) {
                          div.scrollIntoView({behavior: 'smooth'});
                      }       
                  }

                  function moveToForm() {
                    // console.log("moveToForm App.tsx script");
                    let divForm = document.getElementById("distributor");
                    if(divForm != null) {
                      divForm.scrollIntoView({behavior: 'smooth'});
                      setTimeout(() => {
                        document.getElementById("nombre").focus();
                      }, 1000);
                    }
                  }
                  
                  function createAppLinks() {
                      let div = document.getElementsByClassName("download-app-text")[0];
                      let links = Array.from(document.getElementsByClassName("button-download-app"));
                      links.map((element, index) => {
                          let link = document.createElement("a");
                          let url = element.getAttribute("data-url");
                          link.href = url;
                          link.appendChild(element)
                          div.appendChild(link);
                      });
                  }

                  function addFormScript() {
                    let divForm = document.getElementById("formulario-distribuidor-prueba");
                    let form = Array.from(document.getElementsById("distributor"));
                    if (form != null) {
                    }
                  }


                
                  function checkVisible(elm) {
                    var rect = elm.getBoundingClientRect();
                    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
                  }

                  function checkButtonInView() {
                    const box = document.querySelector('#distributor');
                  
                    document.addEventListener('scroll', function () {
                      const element = document.getElementById("content-tarjeta");
                      if(window.innerWidth < 768) {
                        checkVisible(box) ?
                          element.style.display = "none" :
                          element.style.display = "flex";
                      } else {
                        element.style.display = "none"
                      }
                    }, {
                        passive: true
                    });
                  }
                  
                `}
                  </script>
                </Helmet>
              </> // prueba-cc2a61b452b88269a64a
            ) : pageLoaded == "distribuidor-registrado" ? (
              <>
                <Helmet>
                  <script type="text/javascript">
                    {`
              var brand = document.getElementsByClassName("banner-distribuidor");
              // console.log(brand)

                                    compruebaDistribuidorRegistrado()

                                    async function compruebaDistribuidorRegistrado() {
                                      do {
                                        await timeout(1000);
                                        await leerContenedorDistribuidorRegistrado();
                                      } while ( brand[0] == null  );
                                    };

                                    function timeout(ms) {
                                      return new Promise(resolve => setTimeout(resolve, ms));
                                    }

                                    function leerContenedorDistribuidorRegistrado() {
                                      // console.log(brand, "brand")
                                      button = document.getElementsByClassName("socio-header");
                                      preguntasFreuentes = document.getElementById("preguntas-frecuentes-distribuidor")
                                      if(button[0] != null ) {
                                          createLinks();
                                      }
                                      return false;
                                    };

            function createLinks() {
                      let links = Array.from(document.getElementsByClassName("btnlinkhome"));
                      let elements = document.querySelectorAll(".btnlinkhome");
                      links.map((element,index) => {
                        let idbtn = element.getAttribute("id");
                        let url = element.getAttribute("data-link");
                        let text = element.getAttribute("data-text");
                        let a_tag = document.createElement("a");
                        a_tag.setAttribute('class',"text-continuar-afiliado");
                        element.setAttribute('class',"btn-continuar-afiliado");
                        if (idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == false) {
                          a_tag.addEventListener('click', openRegister);
                        } else {
                          a_tag.href = url;
                        }
      
                        if(idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == true) {
                          a_tag.href = "/dashboard";
                          a_tag.text = "MI CUENTA";
                        } else {
                          a_tag.text = text;
                        }
                        a_tag.setAttribute('key',"btn-"+index);
                        element.appendChild(a_tag);
                      }); 
                    }
                    `}
                  </script>
                </Helmet>
                <Helmet>
                  <script type="text/javascript">
                    {`
                    var emailSocio = document.getElementById("correoSocio");
                    compruebaDataSocio();
                    async function compruebaDataSocio() {
                      do {
                        await timeout(1000);
                        await cambiarDataSocio();
                        await createHideShowFunction();
                      } while (emailSocio == null);
                    };

                    function cambiarDataSocio() {
                      // console.log(emailSocio, "emailSocio")
                      const dataSocio = localStorage.getItem("HCSSapphire-Dpdirecto-dataSocio");

                      emailSocio = document.getElementById("correoSocio");

                      if(emailSocio && dataSocio) {
                        const dataParseSocio = JSON.parse(dataSocio);
                        emailSocio.innerText = dataParseSocio.emailSocio;

                        const numSocio = document.getElementById("numSocio");
                        numSocio.innerText = dataParseSocio.numSocio;

                        const nombreSocio = document.getElementById("nombreSocio");
                        nombreSocio.innerText = dataParseSocio.nombreSocio;
                      }
                    }

                    function createHideShowFunction(){
                      let foundButtons = Array.from(document.querySelectorAll(".icono-hide-show"));
                      let foundButton = document.querySelector(".btn-hide-show-1");
                      if(foundButtons != null || foundButtons != undefined || foundButtons.length != 0) 
                      {
                        foundButtons.filter((button)=> {
                          button.addEventListener("click", function(e) {
                            // console.log(button, "button")
                            let classNa = button.className.match("btn-hide-show").input;
                            let number = classNa.split('')[classNa.length - 1];
                            let div = document.querySelector(".respuesta-distribuidor-" + number);
                            let iconoToggle = document.querySelector(".btn-hide-show-" + number);
                            if (div?.className?.includes("hideAnswer")){
                              // console.log(div?.className, "div.className en el if")
                              // console.log(classNa?.className, "classna")
                              // console.log(iconoToggle?.className?.includes("icono-hide-show"), "iconoToggle en el if")
                              div?.classList?.remove("hideAnswer", "clase-hide")
                              div?.classList?.toggle("showAnswer","clase_display")
                              div.style.maxHeight = div.scrollHeight + "px";

                            } else{
                              // console.log(div?.className, "div.className en el else")
                              // console.log(iconoToggle?.className, "iconoToggle en el else")
                               div?.classList?.remove("showAnswer","clase_display")
                               div?.classList?.toggle("hideAnswer", "clase-hide")
                               div.style.maxHeight = null;
                             }
                          }, false)}
                        )
                      }
                      return null;
                    }
                  `}
                  </script>
                </Helmet>
              </>
            ) : pageLoaded == "centro-de-ayuda" ? (
              <Helmet>
                <script type="text/javascript">
                  {`
                var sp_hc = document.getElementById("centroAyuda");
                compruebaCentroDeAyuda();

                async function compruebaCentroDeAyuda() {
                  do {
                    await timeout(1000);
                    await leerContenedorCentroDeAyuda();
                  } while (sp_hc == null);
                };

                function leerContenedorCentroDeAyuda() {
                  sp_hc = document.getElementById("centroAyuda");
                  
                  if(sp_hc != null) {
                      loadSwiperHC();
                  }
                  return false;
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function loadSwiperHC() {
                  var swiper_hc = new Swiper(".help_center_swiper", {
                    slidesPerView: 5,
                    spaceBetween: 10,
                    breakpoints: {
                      320: {
                        slidesPerView: 1.5,
                        spaceBetween: 10
                      },
                      550: {
                        slidesPerView: 1.5,
                        spaceBetween: 10
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                      1024: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      },
                      1280: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      },
                      1920: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      }
                    }
                  });
                }
              `}
                </script>
              </Helmet>
            ) : pageLoaded == "contactanos" ? (
              <Helmet>
                <script type="text/javascript">
                  {`
                var sp_contactanos = document.getElementById("sp_contactanos");
                compruebaContactanos();
                async function compruebaContactanos() {
                  do {
                    await timeout(1000);
                    await leerContenedorContactanos();
                  } while ( sp_contactanos == null);
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function leerContenedorContactanos() {
                  sp_contactanos = document.getElementById("sp_contactanos");
                  
                  if(sp_contactanos != null) {
                      setPhone();
                      setEmail();
                  }
                  return false;
                };

                function setPhone() {
                  let phone = document.getElementsByClassName("contaco-tel")[0];
                  phone.innerHTML = "<a href='tel:" + phone.getAttribute("data-tel") + "'>" + phone.getAttribute("data-formated") + "</a>" + phone.getAttribute("data-text");
                }

                function setEmail() {
                  let email = document.getElementsByClassName("contacto-email")[0];
                  let link_email = document.createElement("a");
                  link_email.href="mailto:"+email.getAttribute("data-email");
                  link_email.text = email.getAttribute("data-email");
                  email.appendChild(link_email);
                }
              `}
                </script>
              </Helmet>
            ) : pageLoaded == "cart" ||
              pageLoaded == "checkout" ||
              pageLoaded == "shipping" ||
              pageLoaded == "order-confirmation" ? (
              <Helmet>
                <script type="text/javascript">
                  {`
                  let modal = document.getElementById("content-tarjeta");
                  modal.style.display = "none";
                `}
                </script>
              </Helmet>
            ) : null}
          </loginContext.Provider>
        </ModalProvider>
      </>
    )
  );
};

export default App;
