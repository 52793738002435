/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { dimensions } from "../../../themes/variables";


const CustomTextField = (props: any) => (
  <TextField {...props} variant="outlined" />
);

const StyledTextField = styled(CustomTextField)`
  ${({ theme }) => `  
  .Mui-focused fieldset {     
    border: 1px solid #c4c4c4 !important;
  }
  .MuiInputLabel-outlined.Mui-disabled{
    color: #5E6977 !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
    font-size: 14px !important;  
    height:40px;

    .MuiInputBase-input{
      padding: 0 28px 0 10px !important;
    }
    &:hover {
      &:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
        border-color: #c4c4c4;
      }
      .MuiInputLabel-outlined {
        color: ${theme.palette.primary.main};
      }
    }

    legend {
      width: 0px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }

  &.MuiOutlinedInput-input {
    font-weight: 300;
    color: ${theme.palette.text.primary};
    height:40px;
    margin: 0px !important;
    width: 226px;
    font-weight: 300 !important;
    border-radious: 0px !important;
  }

  .MuiInputLabel-outlined {
    transform: none;
    position: relative;
    margin-bottom: ${theme.spacing(1)}px;
    font-weight: 500;
    color: ${theme.palette.text.secondary};
    z-index: unset;
    pointer-events: unset;

    &.Mui-focused {
      color: ${theme.palette.text.primary};
    }

    &.Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
  }

  .MuiFormHelperText-contained {
    margin: 3px 0 0;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    color: ${theme.palette.text.disabled};

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.text.disabled};
      }
    }

    .MuiOutlinedInput-input {
      color: ${theme.palette.text.disabled};
      margin: 0px !important;
      width: 226px;
      height: 40px;
      font-family: Roboto;
      font-weight: 300 !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.text.disabled};
    }
  }

  label.Mui-required {
    .MuiInputLabel-asterisk {
      display: none;
    }
  }
  `}
  &.addressForm input{
    height:40px;
    width:100%;
    padding: 0 28px 0 10px;
  }

  &.addressForm {
    width:100%;
  }
  &.customPlaceHolder input::placeholder{
    font-family: Roboto;
    font-weight: 300;
    font-size:13.5px;
    color: #000000;
  }
  &.mercadoPagoForm {
    margin-top: 8px;
  }
  &.mercadoPagoForm input{
    font-family: Roboto;
    font-weight: 300;
    font-size:13.5px;
    color: #000000;
    outline: 'none'
  }
  &.mercadoPagoForm input:focus{
    outline: 'none'
  }
  &.mercadoPagoForm .MuiFormHelperText-root{
    text-align: center;
  }
  
`;

export { StyledTextField };
