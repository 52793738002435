/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Custom libraries
import { PAGINATION_CONFIGS } from "../../configs/catalog";
//Redux
import * as ACTIONS from "../action-types/catalog";
import initStates from "./initStates";

/**
 * Catalog reducer
 * handles states used by catalog related components
 * @param state State object managed by catalog reducer
 * @param action The dispatched action
 */
const catalogReducer = createReducer(initStates.catalog, (builder) => {
  const pageDefaultOffset: number = PAGINATION_CONFIGS.pageDefaultOffset;

  builder.addCase(ACTIONS.SHOW_FILTER_MOBIL, (state, action: AnyAction) => {
    const payload = action.payload;
    if (payload.showModal) {
      state.showModal = true;
    } else {
      state.showModal = false;
    }
  });

  builder.addCase(
    ACTIONS.PRODUCT_LIST_GET_REQUESTED,
    (state, action: AnyAction) => {
      console.trace("catalog console.log");

      const payload = action.payload;
      if (payload.states) {
        console.log(payload.states, "payload.states");
        console.log(state.facets, "console.log(state.facets)");
        if (payload.states["selectedFacets"] !== undefined) {
          state.selectedFacets = payload.states.selectedFacets;
          console.log(state.selectedFacets, "state.selectedFacets");
        }
        if (payload.states["selectedFacetLimits"] !== undefined) {
          state.selectedFacetLimits = payload.states.selectedFacetLimits;
          console.log(state.selectedFacetLimits, "state.selectedFacetLimits");
        }

        if (payload.states["minPrice"] !== undefined) {
          state.selectedFacetPrices.min = payload.states.minPrice;
          console.log(
            state.selectedFacetPrices.min,
            " state.selectedFacetPrices.min"
          );
        }

        if (payload.states["maxPrice"] !== undefined) {
          state.selectedFacetPrices.max = payload.states.maxPrice;
          console.log(
            state.selectedFacetPrices.max,
            "state.selectedFacetPrices.max"
          );
        }
        if (payload.states["selectedPageOffset"] >= 0) {
          state.selectedPageOffset = payload.states.selectedPageOffset;
          console.log(state.selectedPageOffset, "state.selectedPageOffset ");
        }
        if (payload.states["selectedSortOption"] !== undefined) {
          state.selectedSortOption = payload.states.selectedSortOption;
          console.log(state.selectedSortOption, "state.selectedSortOption");
        }
        if (payload.states["selectedPageLimitOption"] !== undefined) {
          state.selectedPageLimitOption =
            payload.states.selectedPageLimitOption;
          console.log(
            state.selectedPageLimitOption,
            "state.selectedPageLimitOption"
          );
        }
      } else {
        state.selectedFacetLimits = [];
        state.selectedFacets = {};
        state.selectedFacetPrices.min = -1;
        state.selectedFacetPrices.max = -1;
        state.selectedPageOffset = pageDefaultOffset;
        state.selectedSortOption = "5";
        state.selectedPageLimitOption = 24;
      }

      // console.log(payload.states["maxPrice"], "payload.states[maxPrice]");
      // console.log(payload.states["minPrice"], "payload.states[minPrice]");
      console.log(payload, "payload ");
      console.log(state, "state en  ACTIONS.PRODUCT_LIST_GET_REQUESTED ");
      console.log(window.location.search, "window.location.search. ");
    }
  );

  builder.addCase(
    ACTIONS.PRODUCT_LIST_GET_SUCCESS,
    (state, action: AnyAction) => {
      const response = action.response;
      console.log(response, "response ACTIONS.PRODUCT_LIST_GET_SUCCESS ");
      if (response["facets"] && response["facets"].length > 0) {
        console.log(
          response["facets"],
          "response ACTIONS.PRODUCT_LIST_GET_SUCCESS "
        );
        let newFacetPrice = null;
        response["facets"].forEach((facet: any) => {
          if (facet.value.startsWith("price_")) {
            newFacetPrice = facet;
          }
        });
        state.facets = response.facets;
        state.facetPrice = newFacetPrice;
      } else {
        state.facets = state.facetPrice ? [state.facetPrice] : [];
      }

      state.productList = response["contents"] ? response.contents : [];
      state.productListTotal = response.total;
      if (response["metaData"]) {
        state.priceMode = response.metaData.price;
      }
      state.breadcrumbs = response["breadCrumbTrailEntryView"]
        ? response.breadCrumbTrailEntryView
        : [];
    }
  );

  builder.addCase(
    ACTIONS.PRODUCT_LIST_RESET_REQUESTED,
    (state, action: AnyAction) => {
      state.productList = [];
      state.productListTotal = -1;
      state.facets = null;
      state.facetPrice = null;
      state.selectedFacets = {};
      state.selectedFacetLimits = [];
      state.selectedFacetPrices = { min: -1, max: -1 };
      state.selectedPageOffset = 1;
      state.selectedSortOption = "5";
      state.breadcrumbs = [];
    }
  );

  builder.addCase(
    ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
    (state, action: AnyAction) => {
      const payload = action.payload;
      const response = action.response;

      state.breadcrumbs = response["breadCrumbTrailEntryView"]
        ? response.breadCrumbTrailEntryView
        : [];
      if (
        payload.parameters.productName !== undefined &&
        state.breadcrumbs.length > 0
      ) {
        state.breadcrumbs = state.breadcrumbs.concat({
          label: payload.parameters.productName,
        });
      }
    }
  );
});

export default catalogReducer;
