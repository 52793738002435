/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import React, { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
//UI
import { StyledGrid } from "../../elements/grid";
//Custom libraries
import { ProductCardLayout } from "../../../components/widgets/product-card";
import styled from "styled-components";
import QuickInfoDetailsWidget from "../../custom-widgets/QuickInfo/QuickInfoDetailsWidget";
import { StyledBox } from "@hcl-commerce-store-sdk/react-component";
import { Zoom } from '@material-ui/core'
import { makeStyles } from "@material-ui/core";
import { useSite } from "../../../_foundation/hooks/useSite";
import {DEFAULT_INFO_ELECTRONICO, InfoElectronico} from "../../commerce-widgets/product-information/ProductoElectronico";
import { Electronicos } from "../../commerce-widgets/product-information/Electronicos";
interface CardProps {
  fontSize?: any;
  margin?: any;
  color?: any;
}

const StyledProductCardContainer = styled.div<CardProps>`
  width: 100%;
  max-width: 236px;
  margin:0px 10px 40px;
`;

const linkStyles = makeStyles((theme) => ({
  Container:{
    marginLeft: "20px",
    '@media (max-width: 896px)' : {
      marginLeft: "0px"
    }
  },
  itemContainer:{
    '@media (max-width: 896px)' : {
    }
  },
}));
/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */

export const ProductContext = createContext((code: any) => {});

export default function useProductCardWidget(props: any) {
  const productListTotal = props.productListTotal;
  const productList = props.productList;
  const categoryId = props.categoryId;
  const { t } = useTranslation();
  const [productCode, setproductCode] = useState("");
  const [action, setAction] = useState(0);
  const [infoElectronicos, setInfoElectronicos] = useState<Map<string,InfoElectronico>>();
  const callbackToChild = useRef<any>(null);
  const classes = linkStyles();
  const { mySite } = useSite();

  useEffect(() => {
    if (productList.length > 0) {
      getInfoElectronicos()
    }
  },[productList])
  
  /**
   * Recupera la informacion de los productos electronicos
   */
  async function getInfoElectronicos(){
    if (productList && productList?.length > 0) {
      //Coleccion temporal de productos electronicos
      let newInfoElectronicos : Map<string,InfoElectronico> = new Map()
      productList.forEach((product) => {
        const newElectronico = {
          isElectronico: undefined,
          hasOfferDeContado: product["x_prices.field1"] ? true : false,
          prices: {
            offerDpVale: undefined,
            offerDeContado: undefined,
            strikedDeContado: product["x_prices.field1"]
          }
        }
        newInfoElectronicos.set(product.id, newElectronico)
      })
      await Electronicos(mySite?.storeID, newInfoElectronicos,setInfoElectronicosCallback)
    }
  }

  function setInfoElectronicosCallback(newInfoElectronicos: Map<string,InfoElectronico>){
    setInfoElectronicos(newInfoElectronicos)
  }

  const handler = (code: any) => {
    if (code !== productCode) {
      setproductCode(code);
      setAction(action + 1);
    } else {
      if (callbackToChild !== null) {
        callbackToChild.current();
      }
    }
  };

  let timeIn = 0
  return (
    <>
      <ProductContext.Provider value={handler}>
        <QuickInfoDetailsWidget
          productNumber={productCode}
          displayQuickInfo={action}
          callbackToParent={callbackToChild}
          infoElectronicos={infoElectronicos}
        />
        <StyledGrid
          container
          spacing={0}
          direction="row"
          className={classes.Container}>
          {infoElectronicos && productListTotal > 0 ? (
            productList?.map((product: any, index: number) => {
              
              timeIn = timeIn + 250
              return (
                <StyledGrid item key={product.id} sm={3} xs={6} id="griditemdata" className={classes.itemContainer}>
                  {/* <StyledBox key={product.id}> */}
                    {/* <Zoom in={true}> */}
                      <StyledGrid id="containerid" style={{margin: "0px 10px 20px"}}>
                        <ProductCardLayout product={product} categoryId={categoryId} infoElectronico={infoElectronicos?.has(product.id) ? infoElectronicos.get(product.id)! : DEFAULT_INFO_ELECTRONICO} />
                      </StyledGrid>
                    {/* </Zoom> */}
                  {/* </StyledBox> */}
                </StyledGrid>
            )
          })
          ) : (
            <>
              {productListTotal === 0 && (
                <StyledGrid item xs={12} style={{marginLeft: "16px"}}>
                  {t("ProductGrid.Labels.noProductsFoundForFilter")}
                </StyledGrid>
              )}
            </>
          )}
        </StyledGrid>
      </ProductContext.Provider>
    </>
  );
}
