import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

import { numItemsSelector } from "../../../../redux/selectors/order";
import ModalContext from "../../../../_foundation/context/ModalContext";

const styles = makeStyles((theme) => ({
  container: {
    zIndex: 500,
  },
  title: {
    padding: "0 20px",
    textAlign: "center",
    fontSize: "20px",
    familyFont: "Fjalla One",
    marginBottom: "20px",
    fontWeight: "normal",
    fontFamily: "Fjalla One, Regular !important",
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
    },
  },
  text: {
    textAlign: "center",
    padding: "0 2.5rem 0 2.5rem",
    fontSize: "15px",
    marginBottom: "2rem",
  },
  containerModal: {
    background: "#00000029",
    opacity: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  modalContent: {
    margin: "0 2rem 0 2rem",
    borderRadius: "5px",
    background: "white",
    position: "relative",
    overflowY: "auto",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "500px",
    padding: "30px 10px",
    border: "none !important",
    outline: "none !important",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
  },
  iconClose: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: "1rem",
    paddingRight: "1rem",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  containerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  firstButton: {
    fontFamily: "Fjalla One, Regular !important",
    background: "#F1F1F1 0% 0% no-repeat padding-box",
    padding: "10px 1.5rem",
    marginRight: "0px",
    color: "black",
    marginBottom: "10px",
    textAlign: "center",
    width: "60%",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0px",
      marginRight: "10px",
      width: "max-content",
    },
  },
  secondButton: {
    fontFamily: "Fjalla One, Regular !important",
    background: "#F44817 0% 0% no-repeat padding-box",
    color: "#fff",
    padding: "10px 1.5rem",
    marginLeft: "0px",
    width: "60%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10px",
      width: "max-content",
    },
  },
}));

export interface Cta {
  id: number;
  to: string;
  text: string;
  variant: "primary" | "secondary";
}

interface ModalProductsOnCartProps {
  title: string;
  text: string;
  ctas: Cta[];
}

const ModalProductsOnCart = ({
  title,
  text,
  ctas,
}: ModalProductsOnCartProps) => {
  const {
    setIsOpen,
    isOpen,
    setCounterClicks,
    counterClicks,
    setCloseSesion,
    closeSesion,
    modalPhone,
    setModalPhone,
  } = useContext(ModalContext);

  const handleCloseSesion = () => {
    console.log("entro aqui");
    setCloseSesion(true);
    setIsOpen(false);
  };

  const classes = styles();

  const handleStay = () => {
    setIsOpen(false);
    // console.log(isOpen, "handleStay");
    setCounterClicks(counterClicks + 1);
    // console.log(counterClicks, "handleStay");
    setCloseSesion(false);
    // console.log(closeSesion, "handleStay");
  };
  // console.log(modalPhone, "MODALPHONE");
  // console.log(isOpen, "MODALPHONE");
  // console.log(closeSesion, "closeSesion");

  const numItems = useSelector(numItemsSelector);

  return (
    <>
      {isOpen && numItems >= 1 && modalPhone === false ? (
        <Modal
          open={isOpen}
          onClose={handleStay}
          className={classes.containerModal}>
          <Box className={classes.modalContent}>
            {/* X close */}
            <IconButton
              aria-label="close"
              onClick={handleStay}
              className={classes.iconClose}>
              <CloseIcon />
            </IconButton>
            {/* Tilte */}
            <StyledTypography variant="h5" className={classes.title}>
              {title}
            </StyledTypography>
            {/* Text */}
            <StyledTypography variant="body1" className={classes.text}>
              {text}
            </StyledTypography>
            {/* Buttons */}
            {ctas?.length > 0 ? (
              <div className={classes.containerButtons}>
                <Link
                  to={ctas[0].to}
                  className={classes.firstButton}
                  onClick={handleStay}>
                  {ctas[0].text}
                </Link>
                {ctas[1] && (
                  <Link
                    to={ctas[1].to}
                    className={classes.secondButton}
                    onClick={closeSesion ? handleCloseSesion : handleStay}>
                    {ctas[1].text}
                  </Link>
                )}
              </div>
            ) : null}
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export { ModalProductsOnCart };
