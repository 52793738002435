/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LinkWrapper = (props: any) => <Link {...props} />;

const StyledLink = styled(LinkWrapper)`
  ${({ theme }) => `
    color: #1565c0;

    svg {
      color: #1565c0;
    }

    &:hover {
      color: #1565c0;

      svg {
        color: #1565c0;
      }
    }

    &.disabled {
        color: #1565c0;
    }
  `}
`;

export { StyledLink };
