import { SET_NOMBRE_COMPONENTE } from './../../action-types/componente';
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/componente";
import * as WORKERS from "../workers/componente";

export function* watchSaga() {
  yield takeLatest(ACTIONS.SET_NOMBRE_COMPONENTE, WORKERS.setNombreComponente);
}
