/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const palette = {
  emerald: {
    main: "#2e3047",
    light: "#2e3047",
    dark: "#2e3047",
  },
  sapphire: {
    main: "#1bb0aa",
    light: "#1bb0aa",
    dark: "#1bb0aa",
  },
  text: {
    primary: "#43484D",
    secondary: "#5E6977",
    disabled: "#B5BECA",
    highlight: "#f44336",
    alert: "#C10C0D",
    expandedMenu: "#FFFFFF",
  },
  border: {
    main: "#cccccc",
    hover: "#F0F4F7",
    alert: "#C10C0D",
  },
  divider: {
    dark: "#4C5256",
  },
  background: {
    default: "FFFFFF",
    paper: "#FFFFFF",
    transparency: "CC",
  },
  disabled: {
    background: "#F0F4F7",
  },
};
