/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { Link, useLocation } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
//UI
import { SnackbarOrigin } from "@material-ui/core";
import {
  StyledSnackbar,
  StyledAlert,
} from "@hcl-commerce-store-sdk/react-component";

import { makeStyles } from "@material-ui/core/styles";

const styledMUI = makeStyles((theme) => ({
  marginBottom: {
    [theme.breakpoints.down("md")]: {
      bottom: "85px !important",
    },
  },
}));

interface MessageSnackbarProperty {
  handleAutoClose: Function;
  anchorOrigin: SnackbarOrigin;
  handleClose: Function;
  message: string | null;
  severity: "success" | "info" | "warning" | "error" | undefined;
  linkAction?: { url: string; text: string };
  autoHideDuration?: number;
  ClickAwayListenerProps?: any;
}
const MessageSnackbar = ({
  anchorOrigin,
  handleClose,
  handleAutoClose,
  message,
  severity,
  linkAction,
  autoHideDuration,
  ClickAwayListenerProps,
}: MessageSnackbarProperty) => {
  const location = useLocation();
  const open = !!message;
  let clickAway = {};
  if (ClickAwayListenerProps) {
    clickAway = { ClickAwayListenerProps };
  }
  const MessageToDisplay = () => {
    return (
      <>
        {linkAction === null || linkAction === undefined ? (
          message && HTMLReactParser(message)
        ) : (
          <span>
            {message && HTMLReactParser(message)}
            <Link to={linkAction.url}>{linkAction.text}</Link>
          </span>
        )}
      </>
    );
  };

  const onClose = () => {
    if (open) {
      handleClose();
    }
  };
  // Funntion to autoclose error and success message after 5 seconds
  // const autoClose = () => {
  //   handleAutoClose();
  //   console.log("autoClose");
  // };
  // React.useEffect(() => {
  //   if (open) {
  //     autoClose();
  //   }
  // }, [open]);

  React.useEffect(() => {
    onClose();
  }, [location]);

  const classes = styledMUI();
  return (
    <>
      <StyledSnackbar
        className={classes.marginBottom}
        open={open}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        {...clickAway}>
        <StyledAlert
          variant="filled"
          severity={severity}
          onClose={() => {
            onClose();
          }}>
          <MessageToDisplay />
        </StyledAlert>
      </StyledSnackbar>
    </>
  );
};

export default MessageSnackbar;
