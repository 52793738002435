import React, { createContext, useReducer, useState } from "react";

// Context Modal Products on Cart
const ModalContext = createContext();

//Provider
const ModalProvider = ({ children }) => {
  const [counterClicks, setCounterClicks] = useState(0);
  const [notAvailableProducts, setNotAvailableProducts] = useState([]);
  const [fullInfo, setFullInfo] = useState(false);
  const [clicksModalLevelStatus, setClicksModalLevelStatus] = useState(0);
  const [tarjetaClubProtocolData, setTarjetaClubProtocolData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [closeSesion, setCloseSesion] = useState(false);
  const [logonIdEmail, setLogonIdEmail] = useState("");
  const [levelStatus, setLevelStatus] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [linkHeader, setLinkHeader] = useState(false);

  const [v2loginIdentity, setV2loginIdentity] = useState();
  const [modalPhone, setModalPhone] = useState(false);
  const [statusDevolucion, setStatusDevolucion] = useState(null);

  //Funciones
  const handleCounterClicks = () => {
    setCounterClicks(counterClicks + 1);
  };

  const handleResetCounter = () => {
    setCounterClicks(0);
  };

  const data = {
    closeSesion,
    setCloseSesion,
    isOpen,
    setIsOpen,
    counterClicks,
    setCounterClicks,
    handleCounterClicks,
    handleResetCounter,
    logonIdEmail,
    setLogonIdEmail,
    notAvailableProducts,
    setNotAvailableProducts,
    tarjetaClubProtocolData,
    setTarjetaClubProtocolData,
    fullInfo,
    setFullInfo,
    levelStatus,
    setLevelStatus,
    clicksModalLevelStatus,
    setClicksModalLevelStatus,
    openModal,
    setOpenModal,
    loadingTable,
    setLoadingTable,
    modalPhone,
    setModalPhone,
    v2loginIdentity,
    setV2loginIdentity,
    linkHeader,
    setLinkHeader,
    statusDevolucion,
    setStatusDevolucion,
  };

  return <ModalContext.Provider value={data}>{children}</ModalContext.Provider>;
};

export { ModalProvider };
export default ModalContext;
