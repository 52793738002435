/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import { StyledPaper } from "../paper";

const StyledPDPContainer = styled(({ ...props }) => <StyledPaper {...props} />)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px ${theme.spacing(3)}px;
    max-width:1280px;
    margin: 0 auto;
    box-shadow:none;

    @media (min-width: 320px) and (max-width: 599px) {
      padding: 0px 12px;
      margin-top: 15px;
    }

    .custom-product-name {
      font-family: "Fjalla One";
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .product-name {
      margin-top: ${theme.spacing(2)}px;
      font-size: 28px;
      font-weight: 300;
      line-height: 32px;
      color: rgb(51,51,51)
    }

    hr.separador {
      border-width: 0px;
      border-bottom: 1px solid #e9e9e9;
      width: 96%;
      margin: -10px 24px 15px 24px;
      @media (min-width:320px) and (max-width:1024px) {
        margin: 0px 24px 15px -10px;
        width:100%;
      }
    }

    .product-sku,
    .product-color,
    .product-quantity,
    .product-availability {
      margin: ${theme.spacing(2)}px 0 ${theme.spacing(1)}px;
      font-weight: bold;
    }

    .product-shortDescription {
      margin: ${theme.spacing(1)}px 0 ${theme.spacing(2)}px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: rgb(77,77,77);
      display: none;
    }

    .product-price-container {
      margin-bottom: ${theme.spacing(2)}px;
    }

    .textalign {
      display:block;
      width:7em;
      text-align:right;
    }

    .MuiCardHeader-subheader{
      height: 3em;
    }

    .attachment{
      display: inline;
    }

    .product-attachment {
      vertical-align: middle;
      background-color: #F2F2F2;
    }

    .selectType{
      -webkit-appearance: none;
      -moz-appearance: none;
      border:0px;
    }

    input:focus, select:focus, textarea:focus, form:focus, button:focus {
      outline:0;
    }

    .product-price {
      margin-right: ${theme.spacing(2)}px;
      font-size: 22px;
      color: #009894;
      font-weight: 400;
      display: block;
      line-height: 1em;
      font-family: 'Fjalla One', sans-serif !important;
      text-transform: uppercase;
    }

    #modelCodeLimit {
      border-left: 1px solid #B4B4B4;
      margin: auto;
      margin-left: 6px;
      margin-right: 6px;
      height: 15px;
      clear: both;
      float: none;
    }

    .sku-original .sku {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      margin: 0;
      display: block;
      line-height: 15px;
      margin-bottom: 5px;
      font-family: Roboto, HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      text-transform: none;
    }

    .strikethrough {
      color: ${theme.palette.text.secondary};
      text-decoration: line-through;
      font-size: 0.9em;
    }

    .add2cart-pdp-container{
      text-align: center;
    }
    .add2cart-container-electronico{
      border-top: 1px solid #e3e3e3;
      padding-top: 6px;
      margin-top: 10px;
    }

    .add2cart-container-electronico .add2cart-pdp-container{
      text-align: left;
    }

    @media (max-width: 600px) {
      .add2cart-container-electronico .add2cart-pdp-container{
        text-align: center !important;
      }
    }

    .add2cart-pdp-container-electronico button{
      margin: 0 !important;
    }

    .product-add-to-cart {
      margin: ${theme.spacing(2)}px 0;
    }

    .product-imageB2B,
    .product-image {
      text-align: left;
      position: relative;
    }
  `}
`;

export { StyledPDPContainer };
