/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import chevronDown from "../../../img/chevron-down.png";

const StyledSelect = styled.select.attrs((props: any) => props)`
  @media only screen and (min-width: 1280px) {
   
    ${(props) => (props.addressList ? " max-width: 280px !important;" : "")}
  }

  &.addressForm {
    width: 100%;
    max-width: 1000px !important;
  }
  
  &.CustomBirthdayDrop{
    color: rgb(51, 51, 51);
    font-weight: 300 !important;
    font-family: Roboto, HelveticaNeue-Light;
  }

  max-width: 360px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #d5d5d5;
  border-radius: 0px;
  height: 42px;
  font-family: Arial;
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  background: url(${chevronDown}) no-repeat scroll right 10px top 17px #fff;
  background-size: 10px auto;
  padding: 0 28px 0 10px;
  &.selectAddress{
    width: calc(100%);
    font-size: 14px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    padding: 0 28px 0 10px;
    .MuiInput-input {
      padding: 8px 8px;
      padding-right: 24px;
    } 
    @media only screen and (max-width: 900px) {
      width: 95% !important;
    } 
    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }    
  }
  &.selectAddressError{
    border-color: #d90101;
  }
  &.Roboto{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
  }

  &.headFilter{
    font-weight: 600;
    width: auto;
  }

  &.headFilterPage{
    font-weight: 600;
    width: 50px;
  }
`;
/*
const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
  ${({ theme }) => `
    .MuiOutlinedInput-input {
      padding: 0 ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(4)}px;
      height: ${dimensions.searchBar.height}px;
      line-height: ${dimensions.searchBar.height}px;
      color: ${theme.palette.text.primary};

      &.Mui-disabled {
        color: ${theme.palette.action.disabled};
        background:  ${theme.palette.action.disabledBackground};
      }
    }

    .MuiSelect-icon {
      top: 50%;
      transform: translateY(-50%);
      color: ${theme.palette.text.secondary};
      width: 20px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 10px;
      border-color: ${theme.palette.text.disabled};
    }

    .MuiInputLabel-animated {
      transition: none;
    }
  `}
  &.selectAddress{
    width: calc(100% - 15px);
    font-size: 14px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    .MuiInput-input {
      padding: 8px 8px;
      padding-right: 24px;
    }    
  }
  &.Roboto{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
  }
`;*/

export { StyledSelect };
