/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

const CustomContainer = (props: any) => <Container maxWidth="lg" {...props} />;

const StyledContainer = styled(CustomContainer)`
  ${({ theme }) => `
    .fullWidth {
      @media (min-width:320px) and (max-width:1024px) {
        padding: 0px !important;
      }
    }
    
    &.expanded-menu {
      width:inherit;
      height:${theme.spacing(5)}px;
      text-align: center;

      &.MuiContainer-root {
        width:inherit;
        margin:0;
        padding:0;
      }
    }
    

  `}
  &.full-layout{
          padding:0px 5px;
        }
  .product-listings{
   max-width: 1096px;
  }
  .bottom-layout{
    justify-content: center;
  }
  

  .breadcrumb-fix-size-product-listing {
    // width:100%;
    // max-width:1475px !important;
    // margin-left: 32px;
    // @media (min-width:320px) and (max-width:1024px) {
    //   margin-left: 0px;
    // }
  }

  .MuiTypography-caption {
    width:100%;
    max-width:1065px !important;
    margin-left: 32px;
    border-bottom: 1px solid #B5BECA;
    @media (min-width:320px) and (max-width:1024px) {
      margin-left: 12px;
      width:94%;
    }
    .MuiTypography-colorTextSecondary{
      @media (min-width: 320px) and (max-width: 1024px){
        width: 65%;
      }
    }
  }

  .breadcrumb-divider {
    display: none;
    width:100%;
    max-width:1065px !important;
    margin-left: 32px;
    @media (min-width:320px) and (max-width:1024px) {
      display: none;
      margin-left: 0px;
    }
  }

  .breadcrumb-fix-size-product-details {
    width: 100%;
    max-width: 1280px !important;
    padding-left: 0px;

    div:first-child {
      max-width: 1200px !important;
    }

    @media (min-width:320px) and (max-width:1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }


`;

export { StyledContainer };
