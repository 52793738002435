
/**
 * Custom validaSocio
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { ValidaSocioApi } from "./transaction/validaSocio-api";
import { site } from "../../constants/site";

const validaSocioApiInstance = new ValidaSocioApi(
  undefined,
  site.transactionContext
);
const validaSocioService = {
  /**
   * Validates noSocio.
   *
   * `@method`
   * `@name validaSocioService`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} noSocio (required)` The child property of `Parameters`.The store identifier.
   */
   validate(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      noSocio
    } = parameters;
    return validaSocioApiInstance.validaSocio(
      storeId,
      noSocio
    );
  }
};

export default validaSocioService;
