/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import axios, { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import { V2UrlResourceApi } from "@hcl-commerce-store-sdk/typescript-axios-es";
import { createBrowserHistory } from "history";

const urlResourceApiInstance = new V2UrlResourceApi(
  undefined,
  site.searchContext
);

const history = createBrowserHistory();

const urlsService = {
  /**
   * Gets Response for V2.0 API Seo url data
   * `@method`
   * `@name Urls#getV2URLResourcesUsingGET`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {array} id ` The list of category IDs.
   ** `@property {array} identifier ` The list of category identifiers.
   ** `@property {integer} storeId (required)` The store ID.
   */
  getV2URLResourcesUsingGET(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, identifier, ...options } = parameters;
    const validateSession = () => {
      axios
        .get(
          `/search/resources/api/v2/urls?storeId=${storeId}&identifier=${parameters.identifier}&langId=-5`
        )
        .then((res) => {
          return res;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            //history.push("/"+process.env.REACT_APP_EXPIRED_SESSION);
            if (
              window.location.pathname !== process.env.REACT_APP_EXPIRED_SESSION
            ) {
              console.log("window.location.pathname", window.location.pathname);
              console.log(
                "process.env.REACT_APP_EXPIRED_SESSION",
                process.env.REACT_APP_EXPIRED_SESSION
              );
              localStorage.removeItem("HCSSapphire-Sapphire-currentUser");
              window.location.href = "" + process.env.REACT_APP_EXPIRED_SESSION;
              //history.push(""+process.env.REACT_APP_EXPIRED_SESSION);
              //window.location.reload();
            }
          }
        });
    };
    validateSession();
    return axios.get(
      `/search/resources/api/v2/urls?storeId=${storeId}&identifier=${parameters.identifier}&langId=-5`
    );
  },
};

export default urlsService;
