import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  toPathString,
  createRequestFunction,
} from "../../common";
// @ts-ignore
import { STG_PATH, RequestArgs, BaseAPI } from "../../base";

interface Productavailability {
  code?: string;
  description?: string;
  reasonCode?: string;
}
/**
 * InventoryAvailabilityApi - axios parameter creator
 * @export
 */
export const ProductAvailabilityApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Gets inventory details for the specified product by its sku.
     * @summary Get details by part number
     * @param {string} storeId The store identifier.
     * @param {string} sku The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    productAvailabilityGetAvailabilityApiBySku: async (
      storeId: string,
      sku: string
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeId' is not null or undefined
      assertParamExists(
        "productAvailabilityGetAvailabilityApiBySku",
        "storeId",
        storeId
      );
      // verify required parameter 'sku' is not null or undefined
      assertParamExists(
        "productAvailabilityGetAvailabilityApiBySku",
        "sku",
        sku
      );
      const localVarPath =
        `/store/{storeId}/inventory/GetAvailability?responseFormat=json&sku={sku}`
          .replace(`{${"storeId"}}`, String(storeId))
          .replace(`{${"sku"}}`, String(sku));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions };
      const localVarHeaderParameter = {} as any;

      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets inventory details for the specified orderId.
     * @summary Get details by part number
     * @param {string} storeId The store identifier.
     * @param {string} orderId The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    productAvailabilityGetAvailabilityApiByOrderId: async (
      storeId: string,
      orderId: string
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeId' is not null or undefined
      assertParamExists(
        "productAvailabilityGetAvailabilityApiByOrderId",
        "storeId",
        storeId
      );
      // verify required parameter 'sku' is not null or undefined
      assertParamExists(
        "productAvailabilityGetAvailabilityApiByOrderId",
        "orderId",
        orderId
      );
      const localVarPath =
        `/store/{storeId}/inventory/GetAvailability?responseFormat=json&orderId={orderId}&removeItemsFlag=0`
          .replace(`{${"storeId"}}`, String(storeId))
          .replace(`{${"orderId"}}`, String(orderId));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions };
      const localVarHeaderParameter = {} as any;

      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InventoryAvailabilityApi - functional programming interface
 * @export
 */
export const GetAvailabilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProductAvailabilityApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets inventory details for the specified product by its sku.
     * @summary Get details by part number
     * @param {string} storeId The store identifier.
     * @param {string} sku The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    async productAvailabilityGetAvailabilityApiBySku(
      storeId: string,
      sku: string
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Productavailability>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productAvailabilityGetAvailabilityApiBySku(
          storeId,
          sku
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        STG_PATH,
        configuration
      );
    },
    /**
     * Gets inventory details for the specified orderId.
     * @summary Get details by part number
     * @param {string} storeId The store identifier.
     * @param {string} orderId The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    async productAvailabilityGetAvailabilityApiByOrderId(
      storeId: string,
      orderId: string
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Productavailability>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.productAvailabilityGetAvailabilityApiByOrderId(
          storeId,
          orderId
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        STG_PATH,
        configuration
      );
    },
  };
};

/**
 * GetAvailabilityApi - factory interface
 * @export
 */
export const GetAvailabilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GetAvailabilityApiFp(configuration);
  return {
    /**
     * Gets inventory details for the specified product by its sku.
     * @summary Get details by part number
     * * @param {string} storeId The store identifier.
     * @param {string} sku The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    productAvailabilityGetAvailabilityApiBySku(
      storeId: string,
      sku: string
    ): AxiosPromise<Productavailability> {
      return localVarFp
        .productAvailabilityGetAvailabilityApiBySku(storeId, sku)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets inventory details for the specified orderId.
     * @summary Get details by part number
     * * @param {string} storeId The store identifier.
     * @param {string} orderId The product sku.
     * @throws {RequiredError}
     * @memberof InventoryAvailabilityApi
     */
    productAvailabilityGetAvailabilityApiByOrderId(
      storeId: string,
      orderId: string
    ): AxiosPromise<Productavailability> {
      return localVarFp
        .productAvailabilityGetAvailabilityApiByOrderId(storeId, orderId)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetAvailabilityApi - object-oriented interface
 * @export
 * @class InventoryAvailabilityApi
 * @extends {BaseAPI}
 */
export class GetAvailabilityApi extends BaseAPI {
  /**
   * Gets inventory details for the specified product by its sku.
   * @summary Get details by part number
   * * @param {string} storeId The store identifier.
   * @param {string} sku The product sku.
   * @throws {RequiredError}
   * @memberof InventoryAvailabilityApi
   */
  public productAvailabilityGetAvailabilityApiBySku(
    storeId: string,
    sku: string
  ) {
    return GetAvailabilityApiFp(this.configuration)
      .productAvailabilityGetAvailabilityApiBySku(storeId, sku)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets inventory details for the specified orderId.
   * @summary Get details by part number
   * * @param {string} storeId The store identifier.
   * @param {string} orderId The product sku.
   * @throws {RequiredError}
   * @memberof InventoryAvailabilityApi
   */
  public productAvailabilityGetAvailabilityApiByOrderId(
    storeId: string,
    orderId: string
  ) {
    return GetAvailabilityApiFp(this.configuration)
      .productAvailabilityGetAvailabilityApiByOrderId(storeId, orderId)
      .then((request) => request(this.axios, this.basePath));
  }
}
