import { useLocation } from "react-router";
import { useMemo } from "react";

import { useSite } from "../../../_foundation/hooks/useSite";
import { paramCase } from "change-case";
import { useTranslation } from "react-i18next";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget";
import SpotPopUp from "./SpotPopUp";

const ContenedorSpotPopUp = () => {
  return (
    <div className="content-tarjeta" id="content-tarjeta">
      <SpotPopUp espotName={"PopupInferiorIzquierdo"} />
    </div>
  );
};

export default ContenedorSpotPopUp;
