import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios, { Canceler } from "axios";
import { useSite } from "../../../../_foundation/hooks/useSite/useSite";
import { useSelector, useDispatch } from "react-redux";
import { OK } from "http-status-codes";
import {
  StyledButton,
  StyledLink,
  StyledProgressPlaceholder,
} from "@hcl-commerce-store-sdk/react-component";

import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { StyledTextField, StyledGrid } from "../../../../components/elements";
import { storageSessionHandler } from "../../../../_foundation/utils/storageUtil";
import personService from "../../../../_foundation/apis/transaction/person.service";
import * as successActions from "../../../../redux/actions/success";
import * as errorActions from "../../../../redux/actions/error";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import ModalContext from "../../../../_foundation/context/ModalContext";

const styles = makeStyles((theme) => ({
  container: {
    zIndex: 500,
  },
  title: {
    padding: "0 20px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
    },
  },
  text: {
    textAlign: "center",
    padding: "0 20px",
    fontSize: "15px",
    marginBottom: "2rem",
  },
  containerModal: {
    background: "none !important",
    //  background: "#00000029",
    opacity: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  modalContent: {
    margin: "0 2rem 0 2rem",
    borderRadius: "5px",
    background: "white",
    position: "relative",
    overflowY: "auto",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "500px",
    padding: "30px 0px",
    border: "none !important",
    outline: "none !important",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
  },
  iconClose: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: "1rem",
    paddingRight: "1rem",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  containerButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  firstButton: {
    fontFamily: "Fjalla One, Regular !important",
    background: "rgb(253, 67, 100) 0% 0% no-repeat padding-box",
    border: "2px solid rgb(253, 67, 100)",
    color: "#fff",
    padding: "10px 1.5rem",
    marginRight: "0px",
    marginTop: "10px",
    textAlign: "center",
    width: "40%",
    "&:hover": {
      backgroundColor: "rgb(252,42,78)",
      border: "2px solid rgb(252,42,78)",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
      marginLeft: "5px",
    },
  },
  secondButton: {
    width: "40%",
    fontFamily: "Fjalla One, Regular !important",
    background: "#F4F4FE 0% 0% no-repeat padding-box",
    border: "2px solid #003DED",
    color: "#1121FF",
    padding: "10px 1rem",
    marginLeft: "0px",
    textAlign: "center",
    "&:hover": {
      background: "#0232F7 0% 0% no-repeat padding-box",
      color: "white",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "5px",
    },
  },
}));

export interface Cta {
  id: number;
  to: string;
  text: string;
  variant: "primary" | "secondary";
}

interface ModalRegistratePhoneProps {
  title?: string;
  text?: string;
  ctas?: Cta[];
  openModal?: any;
  setOpenModal?: any;
  setPhone?: any;
  handleSubmit?: any;
  fromHeaderComponent?: any;
}

const ModalRegistratePhone = ({
  openModal,
  setOpenModal,
  setPhone,
  fromHeaderComponent,
}: ModalRegistratePhoneProps) => {
  const { setModalPhone, isPhoneLinked, modalPhone, setIsPhoneLinked } =
    useContext(ModalContext);
  const dispatch = useDispatch();

  const [nickName, setNickName] = useState<string>("");
  const [minutesTime, setMinutesTime] = useState<any>(0);
  const [name, setName] = useState<string>("");
  const [successPost, setSuccessPost] = useState<any>();
  const [successPut, setSuccessPut] = useState<any>();
  const [lastname, setLast] = useState<string>("");
  const [lastname2, setLast2] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [gender, setGender] = useState<string>("M");
  const [responseData, setResponseData] = useState<any>();
  // const [submit, setSubmit] = useState(false);
  const [codeSms, setCodeSms] = useState<string>("");
  const [openModalSms, setOpenModalSms] = useState(false);

  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
  const [registratePhone, setRegistratePhone] = useState("");
  const [isOpenModalRegPhone, setIsOpenModalRegPhone] = useState(
    openModal ? openModal : true
  );

  const CustomButtonStyled = styled.button.attrs(
    (props: { color: string }) => props
  )`
    color: white;
    background-color: ${(props) => (props.color ? props.color : "#0232f7")};
    font-family: Roboto;
    font-weight: 300;
    line-height: 23px;
    padding: 10px;
    vertical-align: middle;
    width: auto;
    height: 44px;
    border-width: 0px;
    &:hover {
      background-color: rgba(253, 67, 100, 0.65);
    }
    font-size: 1em !important;
    cursor: pointer;
  `;

  const classes = styles();
  const { mySite } = useSite();
  let countDownDate;
  let countDownInterval;

  const handleClose = () => {
    console.log(fromHeaderComponent, "fromHeaderComponent");
    if (fromHeaderComponent) {
      localStorage.setItem("addAfter", "true");
      setModalPhone(false);
      setIsOpenModalRegPhone(false);
    }
    setIsOpenModalRegPhone(false);
    setModalPhone(false);
    if (setOpenModal) {
      setOpenModal(false);
    }
    console.log("entro a handleClose");
  };

  async function handleGetData() {
    const storeID = mySite.storeID;
    const parameters: any = {
      responseFormat: "application/json",
      storeId: storeID,
      body: {
        gender: gender,
        nickname: nickName,
        firstName: name,
        lastName: lastname,
        phone1: registratePhone,
        email1: email,
        attributes: [
          {
            contactInfoAttrValue: lastname2,
            contactInfoAttrKey: "addressField1",
          },
        ],
        dateOfBirth:
          year != "" && month != "" && day != ""
            ? year + "-" + month + "-" + day
            : "",
      },
    };
    await personService
      .findPersonBySelf(parameters)
      .then((res) => {
        if (res.status === OK) {
          console.log(res.data, "res.data");
          setResponseData(res.data);
          setEmail(res.data.email1);

          if (res?.data?.attributes[0]) {
            setLast2(res?.data?.attributes[0].contactInfoAttrValue);
          } else if (res?.data?.attributes[0]) {
            setLast2(res?.data?.contact[0].attributes[0].value);
          }

          if (res?.data?.dateOfBirth) {
            setDay(res.data.dateOfBirth.substring(8, 10));
            setYear(res.data.dateOfBirth.substring(0, 4));
            setMonth(res.data.dateOfBirth.substring(5, 7));
          } else {
            setDay("");
            setYear("");
            setMonth("");
          }

          if (res?.data?.gender) {
            setGender(res.data.gender);
          }

          const successMessage = {
            key: res.data.dateOfBirth.substring(5, 7),
          };
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    handleGetData();
    setModalPhone(true);
  }, []);
  useEffect(() => {
    if (successPut === OK) {
      setModalPhone(false);
      if (setPhone) {
        setPhone(registratePhone);
      }
      handleCloseModal();
      handleClose();
    }
  }, [successPut]);
  useEffect(() => {
    if (successPost === OK) {
      const successMessage = {
        key: "Datos Actualizados",
      };
      dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
      setIsPhoneLinked(true);
      if (setPhone) {
        setPhone(registratePhone);
      }
      setModalPhone(false);
      handleCloseModal();
      handleClose();
    }
  }, [successPost]);

  const verificationCode = () => {
    let url = `/wcs/resources/store/${mySite.storeID}/sms/phone-verification/validate-code`;
    let body = {
      phoneNumber: `52${registratePhone}`,
      verificationCode: codeSms,
      formOrigin: "Registro",
    };
    Axios.post(url, body)
      .then((response) => {
        console.log(response, "responseSMS");
        if (response.data.response.escenario === 1) {
          handleRegistratePhone();
        } else {
          dispatch(
            errorActions.VALIDATION_ERROR_ACTION({
              errorCode: null,
              errorKey: null,
              errorMessage: response.data.response.message,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error, "error");
        const errorMessage = error.response.data.message;
        dispatch(
          errorActions.VALIDATION_ERROR_ACTION({
            errorKey: null,
            errorCode: null,
            errorMessage,
          })
        );
      });
  };

  const handleRegistratePhone = () => {
    console.log(responseData, "response");
    let saveExtDataUrl = `wcs/resources/store/${mySite.storeID}/member/phone/login`;
    const token = currentUser?.WCTrustedToken;
    const trustedToken = currentUser?.WCToken;
    let headers = {
      "Content-type": "application/json; charset=UTF-8",
      WCToken: trustedToken,
      WCTrustedToken: token,
    };

    if (currentUser?.isPhoneLinked === "false" && isPhoneLinked === false) {
      console.log("entro post");
      Axios.post(
        saveExtDataUrl,
        { telefono: registratePhone },
        { headers: headers }
      )
        .then((response) => {
          console.log("response wcService, params", response);
          const parameters: any = {
            responseFormat: "application/json",
            storeId: mySite.storeID,
            body: {
              gender: responseData?.gender,
              nickname: responseData?.nickName,
              firstName: responseData?.firstName,
              lastName: responseData?.lastname,
              phone1: registratePhone,
              email1: responseData?.email1,
              attributes: [
                {
                  contactInfoAttrValue: responseData?.attributes[0]
                    ? responseData?.attributes[0]?.contactInfoAttrValue
                    : responseData?.attributes[0]
                    ? responseData?.contact[0]?.attributes[0]?.value
                    : "",
                  contactInfoAttrKey: "addressField1",
                },
              ],
              dateOfBirth: responseData?.dateOfBirth
                ? responseData?.dateOfBirth?.substring(0, 4) +
                  "-" +
                  responseData?.dateOfBirth?.substring(5, 7) +
                  "-" +
                  responseData?.dateOfBirth?.substring(8, 10)
                : "",
            },
          };
          console.log(parameters, "parameters handlesubmit");
          personService
            .updatePerson(parameters)
            .then((res) => {
              if (res.status === OK) {
                console.log(res, "res.data modalRegistrate");
                setSuccessPost(res.status);
              }
            })
            .catch((err) => {
              setModalPhone(false);
            });
        })
        .catch((e) => {
          setModalPhone(false);
          console.log(e, "error post");
        });
    } else {
      console.log("entro put");
      Axios.put(
        saveExtDataUrl,
        { telefono: registratePhone },
        { headers: headers }
      )
        .then((response) => {
          console.log("response wcService, params", response);
          let parameters: any = {
            responseFormat: "application/json",
            storeId: mySite.storeID,
            body: {
              gender: responseData?.gender,
              nickname: responseData?.nickName,
              firstName: responseData?.firstName,
              lastName: responseData?.lastname,
              phone1: registratePhone,
              email1: responseData?.email1,
              attributes: [
                {
                  contactInfoAttrValue: responseData.attributes[0]
                    ? responseData?.attributes[0].contactInfoAttrValue
                    : responseData?.attributes[0]
                    ? responseData?.contact[0]?.attributes[0]?.value
                    : "",
                  contactInfoAttrKey: "addressField1",
                },
              ],
              dateOfBirth: responseData.dateOfBirth
                ? responseData?.dateOfBirth?.substring(0, 4) +
                  "-" +
                  responseData?.dateOfBirth?.substring(5, 7) +
                  "-" +
                  responseData?.dateOfBirth?.substring(8, 10)
                : "",
            },
          };
          console.log(parameters, "parameters handlesubmit");
          personService
            .updatePerson(parameters)
            .then((res) => {
              if (res.status === OK) {
                setSuccessPut(res.status);
                const successMessage = {
                  key: "Datos Actualizados",
                };
                dispatch(
                  successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage)
                );
              }
            })
            .catch((err) => {
              setModalPhone(false);
            });
        })
        .catch((e) => {
          setModalPhone(false);
          console.log(e, "error post");
        });
    }
  };

  const handleSendSMS = async() => {
    let dataSMS = {
      phoneNumber: `52${registratePhone}`,
      formOrigin: "Registro",
      plataforma: "sitio",
      sender: "dpdirecto",
    };
    let urlSMS = `/wcs/resources/store/${mySite?.storeID}/sms/phone-verification/send-sms`;
    try{
      await checkPhoneExistsCommerce(registratePhone);
      await checkPhoneExistsWApp(email,registratePhone);
      await sendSMSRequest(urlSMS,dataSMS);
    }catch (e) {
      // setIsLoadingButton(false);
    }
  };

  const checkPhoneExistsCommerce = async (phone)=> {
    const url = `/wcs/resources/store/${mySite?.storeID}/member/phone/login/validate/${phone}`;
    await Axios.get(url).catch((error) => {
      // setIsLoadingButtonModal(false);
      console.log(error.response.data.errors, "error");
      const errorMessage = error.response.data.errors[0].errorKey === '_ERR_TEL_ALREADY_REGISTERED_IMPROVED' ? "El número de télefono ya se encuentra registrado" : "No pudimos verificar tú télefono intenta de nuevo" || "No pudimos verificar tú télefono intenta de nuevo"; 
      dispatch(
        errorActions.VALIDATION_ERROR_ACTION({
          errorKey: null,
          errorCode: null,
          errorMessage,
        })
      );
      // setClickConfirmarOTP(false);
      throw error;
    });
  }

  const checkPhoneExistsWApp = async (correo, phone)=> {
    const url = `${mySite.storeCfg.userData.base_url_webapp}api/v1/dpdirecto/afiliacion/disponibilidad?correo=${encodeURI(correo)}&telefono=${encodeURI(phone)}`;
    const { data } = await Axios.get(url).catch((error) => {
      // setIsLoadingButtonModal(false);
      console.log(error, "error");
      const errorMessage =  "No pudimos verificar tú télefono intenta de nuevo"; 
      dispatch(
        errorActions.VALIDATION_ERROR_ACTION({
          errorKey: null,
          errorCode: null,
          errorMessage,
        })
      );
      // setClickConfirmarOTP(false);
      throw error;
    });
    if(data.data.telefono === "no disponible"){
      let errorMessage = "El número de télefono ya se encuentra registrado";
      dispatch(
        errorActions.VALIDATION_ERROR_ACTION({
          errorKey: null,
          errorCode: null,
          errorMessage,
        })
      );
      throw new Error();
    }
  }

  const sendSMSRequest = (urlSMS, dataSMS) => {
    Axios.post(urlSMS, dataSMS)
      .then((response) => {
        console.log(response, "responseSMS");
        if (response.data.response.escenario === 1) {
          setIsOpenModalRegPhone(false);
          setOpenModalSms(true);
          countDownDate = 60;
          countDownInterval = setInterval(timer, 1000);
          timer();
        } else if (
          response.data.response.escenario === 2 ||
          response.data.response.escenario === "-1"
        ) {
          dispatch(
            errorActions.VALIDATION_ERROR_ACTION({
              errorCode: null,
              errorKey: null,
              errorMessage:
                response.data.response.message +
                " " +
                response.data.response.error,
            })
          );
        } else if (response.data.response.escenario === 3) {
          // console.log(
          //   response.data.response.message,
          //   "response.data.response.message"
          // );
          dispatch(
            errorActions.VALIDATION_ERROR_ACTION({
              errorCode: null,
              errorKey: null,
              errorMessage: response.data.response.message,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error, "error");
        const errorMessage = error.response.data.message;
        dispatch(
          errorActions.VALIDATION_ERROR_ACTION({
            errorKey: null,
            errorCode: null,
            errorMessage,
          })
        );
      });
  }
  const handleCloseModal = () => {
    // console.log("handkeCloseModal");
    setOpenModalSms(false);
    setCodeSms("");
    setIsOpenModalRegPhone(true);
  };

  function timer() {
    countDownDate--;
    if (countDownDate > 0) {
      let minutes = countDownDate / 60;
      minutes = minutes || 0;
      let seconds = countDownDate % 60;
    } else clearInterval(countDownInterval);
    setMinutesTime(countDownDate);
  }

  return (
    <>
      <Modal
        open={isOpenModalRegPhone}
        onClose={handleClose}
        className={classes.containerModal}
        style={{ background: "pink" }}>
        <Box className={classes.modalContent}>
          {/* Tilte */}
          <StyledGrid style={{ padding: "6% 0px" }}>
            <StyledTypography variant="h5" className={classes.title}>
              AHORA PUEDES INICIAR SESIÓN CON TU NÚMERO DE TELÉFONO{" "}
            </StyledTypography>
            {/* Text */}
            <StyledTypography variant="body1" className={classes.text}>
              Registra tu teléfono para iniciar sesión,{" "}
              <span style={{ fontWeight: 500 }}>
                {" "}
                puedes agregarlo o cambiarlo desde "Mi cuenta" cuando quieras
              </span>
            </StyledTypography>
            <StyledGrid
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "0px 20px",
              }}>
              <StyledTextField
                value={registratePhone}
                inputProps={{
                  maxLength: 10,
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                style={{ width: "100%", textAlign: "center" }}
                onChange={(event) => {
                  if (/^[0-9]*$/.test(event.target.value)) {
                    // setPhone(event.target.value);
                    setRegistratePhone(event.target.value);
                  } else {
                    event.preventDefault();
                    return false;
                  }
                }}
                placeholder={"Teléfono"}></StyledTextField>
            </StyledGrid>
            <StyledGrid
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                padding: "0px 20px",
              }}>
              <CustomButtonStyled
                color={
                  registratePhone.length === 10
                    ? "#FF2757"
                    : "rgba(253, 67, 100, 0.65)"
                }
                disabled={registratePhone.length === 10 ? false : true}
                style={{ fontFamily: "Fjalla One", width: "100%" }}
                onClick={() => {
                  handleSendSMS();
                }}>
                REGISTRAR TELÉFONO
              </CustomButtonStyled>
            </StyledGrid>
            <StyledGrid
              onClick={() => {
                handleClose();
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                cursor: "pointer",
                color: "#1BB0AA",
                textDecoration: "underline",
                fontWeigth: 600,
              }}>
              Agregar después
            </StyledGrid>
          </StyledGrid>
        </Box>
      </Modal>
      {openModalSms && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "none",
            }}>
            <Modal
              id={"openModalSMSCode"}
              open={openModalSms}
              onClose={handleCloseModal}
              className={classes.containerModal}>
              <Box className={classes.modalContent}>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseModal}
                  className={classes.iconClose}>
                  <CloseIcon />
                </IconButton>
                <StyledTypography variant="h5">
                  <div style={{ textAlign: "center", fontWeight: 600 }}>
                    {"¡Ya casi terminas!"}
                  </div>
                </StyledTypography>
                <StyledTypography
                  variant="body1"
                  style={{
                    marginTop: "10px",
                    padding: "5%",
                    textAlign: "center",
                  }}>
                  Ingresa el código que acabamos de enviar al celular que
                  registraste
                </StyledTypography>
                <StyledGrid style={{ padding: "0% 5%" }}>
                  <StyledTextField
                    required
                    name="codeSms"
                    fullWidth
                    className={"smsCode"}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setCodeSms(e.target.value);
                    }}
                    value={codeSms}
                    type={"text"}
                    tabIndex={8}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                </StyledGrid>
                <StyledGrid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {
                    <StyledGrid style={{ margin: "10px 0px" }}>
                      Te quedan{" "}
                      <span style={{ fontWeight: 600 }}>{minutesTime}</span>{" "}
                      segundos para reenviar el código
                    </StyledGrid>
                  }
                  <StyledLink
                    onClick={handleSendSMS}
                    disabled={minutesTime === 0 ? false : true}
                    style={{
                      width: "70%",
                      marginTop: "5px",
                      marginBottom: "15px",
                      color: minutesTime === 0 ? "#00B6D1" : "gray",
                      textAlign: "center",
                      cursor: minutesTime === 0 ? "pointer" : "not-allowed",
                    }}>
                    Reenviar código
                  </StyledLink>
                  <StyledButton
                    onClick={verificationCode}
                    disabled={codeSms.length === 6 ? false : true}
                    style={{
                      width: "70%",
                      marginTop: "5px",
                      borderRadius: "0px",
                    }}>
                    Confirmar
                  </StyledButton>
                </StyledGrid>
              </Box>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export { ModalRegistratePhone };
