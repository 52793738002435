import { Avatar, Box, Typography, makeStyles } from '@material-ui/core'
import React from 'react'


interface Props {
  data: any[];
  numOrderItems: number,
  handleClose: Function;
}

const styles = makeStyles((theme) => ({
  detailsBox : {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      flexDirection: 'column',
      textAlign: 'center',
    }
  },
  avatarSize: {
    width: theme.spacing(12),
    height: theme.spacing(12),    
  },
  productName: {
    fontSize: 14,
    fontWeight: 800,
    color: '#000000'
  },
  descripcion: {
    fontSize: 14,
    fontWeight: 800
  },
  productValue: {
    fontSize: 14,
  },
  precio: {
    fontSize: 14,
  }

}))

const  CustomMiniCartContent = ({data, numOrderItems, handleClose}: Props)  => {

  const classes = styles();
  /*console.log("data => ", data)*/
  return (
    <Box>
      {data.map(producto => {
        let identifierTalla;
        let identifierOferta
        if(producto?.attributes){
          identifierTalla = producto?.attributes.filter(attribute => attribute?.identifier === 'Talla');
          identifierOferta = producto?.attributes.filter(attribute => attribute?.identifier === 'Oferta');
        }       
        let talla
        let tallaValue
        let ofertaValuePorcentaje
        let valorBruto

        if(identifierTalla && identifierTalla.length > 0){
          identifierTalla = identifierTalla[0];
          talla = identifierTalla.name ? identifierTalla.name : '';
          tallaValue = identifierTalla?.values[0]?.value ? identifierTalla.values[0].value : '';

        }else{
          talla = ''
          tallaValue = ''
        }

        if(identifierOferta && identifierOferta.length > 0){
          identifierOferta = identifierOferta[0]
          ofertaValuePorcentaje = identifierOferta?.values[0]?.value ? parseFloat(identifierOferta.values[0].value).toFixed(2) : null

          if(ofertaValuePorcentaje){
            const porcentaje = 100 - ofertaValuePorcentaje
            valorBruto = (100 * parseFloat(producto.unitPrice)) /  porcentaje

          }
        }

        const partNum = producto.partNumber.split('-').slice(0, 2).join('-').substring(2)

        //const url = `wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${partNum}V1.jpg`
        var partNumber = producto.partNumber.substring(2).split("-");
        const url = window.urlBaseImg + `/wcsstore/ExtendedSitesCatalogAssetStore/images/catalog/default/${partNumber[0]}-${partNumber[1]}V1.jpg`;

        return (
          <Box display='flex' minHeight={80} >
            <Box p={1}>
              <Avatar variant="square" src={url} className={classes.avatarSize}/>
            </Box>
            <Box p={1}>
              <Typography >
                <span className={classes.productName}>{producto.name}</span>
              </Typography>
              <Box display='flex' justifyContent='space-beteewn' >
                <Box flexGrow={1}>
                  <Typography>
                    <span className={classes.descripcion}>{talla}: </span><span className={classes.productValue}> {tallaValue}</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                  <span className={classes.descripcion}>Cantidad: </span><span className={classes.productValue}>{parseInt(producto.quantity)}</span>
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' justifyContent='space-beteewn' >
                <Box flexGrow={1}>
                  <Typography style={{textDecoration: 'line-through'}} >
                  {/* {valorBruto ? 
                    <span className={classes.precio}>
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(Number(valorBruto)) }
                    </span>
                  : ''
                  } */}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    <span className={classes.precio}>
                        {producto.unitPrice ?                   
                            new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                            }).format(Number(producto.unitPrice))                  
                        : ''
                        }
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            </Box>
        )
      })}
      {numOrderItems > 0 ?
        <Box mb={1}>
          <Typography align='center'>
            Hay <b>{numOrderItems}</b> productos más en tu carrito
          </Typography>
        </Box>      
      : null
      }
    </Box>
  )
}

export default CustomMiniCartContent