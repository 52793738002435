/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { Link } from "react-router-dom";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import { makeStyles } from "@material-ui/core";

interface ThreeTierMenuProps {
  page?: any;
}

const linkStyles = makeStyles((theme) => ({
  expandedMenu:{
    fontSize: "12px !important",
    color: "black !important",
    padding: "0px 0px 12px",
    fontWeight: 800,
  },
  expandedSubMenu:{
    fontSize: "14px !important",
  },
  expandedSubMenuTier:{
    fontSize: "13px !important",
    marginBottom: "6px",
    marginTop: "6px",
    fontWeight: 300,
  },
}));

/**
 * ThreeTierMenu component
 * expanded menu three tier submenu
 * @param props
 */
const ThreeTierMenu: React.FC<ThreeTierMenuProps> = (props: any) => {
  const page = props.page ? props.page : [];
  const classes = linkStyles();

  return (
    <>
      <StyledPaper className="expanded-menu-three-tier-submenu">
        <StyledBox m={3}>
          <StyledGrid style={{borderBottom: "1px solid  rgb(27, 176, 170)"}}>
            <Link to={page.seo?.href} onClick={(event) => window.orderSort = null}>
              <StyledMenuTypography
                variant="body1"
                className={classes.expandedMenu} style={{fontWeight: "800 !important"}}>
                {"Ver departamento " + page.name}
              </StyledMenuTypography>
            </Link>
          </StyledGrid>
          {page.children && (
            <StyledBox
              display="flex"
              direction="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignContent="flex-start">
              {page.children.map((page2: any, index: number) => (
                <StyledBox mt={2} mr={5} key={page2.id}>
                  <Link key={"Link_" + page2.id} to={page2.seo?.href} onClick={(event) => window.orderSort = null}>
                    <StyledMenuTypography
                      variant="body2"
                      className={"expanded-menu-bold " + classes.expandedSubMenu}>
                      {page2.name}
                    </StyledMenuTypography>
                  </Link>
                  <ul>
                    {page2.children &&
                      page2.children.length > 0 &&
                      page2.children.map((page3: any, i: number) => (
                        <Link key={page3.id} to={page3.seo?.href} onClick={(event) => window.orderSort = null}>
                          <StyledMenuTypography
                            variant="body2"
                            className={classes.expandedSubMenuTier}>
                            {page3.name}
                          </StyledMenuTypography>
                        </Link>
                      ))}
                  </ul>
                </StyledBox>
              ))}
            </StyledBox>
          )}
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default ThreeTierMenu;
