/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import ModalContext from "../../../../src/_foundation/context/ModalContext";
//UI
import {
  StyledBox,
  StyledContainer,
  StyledGrid,
  StyledLink,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import MenubarMenuItem from "./MenubarMenuItem";
import { makeStyles } from "@material-ui/core/styles";
import EspotCuentaRegresiva from "../espot/DpSpots";

const styleContainerMenu: CSSProperties = {
  background: "#2e3047",
  height: 44,
};
const styleContainer: CSSProperties = {
  height: 44,
  display: "flex",
  justifyContent: "space-between",
};

const styleBox: CSSProperties = {
  height: 44,
  alignItems: "center",
  float: "left",
};

const styledContainerLinks: CSSProperties = {
  float: "right",
  height: "100%",
  maxWidth: "430px",
  width: "41%",
};

const styledDivLeft: CSSProperties = {
  cursor: "pointer",
  float: "left",
  marginTop: "12px",
};

const styledDivRight: CSSProperties = {
  float: "right",
  marginTop: "12px",
};

const styledLinks: CSSProperties = {
  height: "100%",
  width: "100%",
  fontSize: 15,
  color: "white",
  fontWeight: 300,
  fontFamily: "sans-serif",
};

const styledIconHelp: CSSProperties = {
  verticalAlign: "middle",
};

interface ExpandedMenuProps {
  pages?: any;
}

const styled = makeStyles((theme) => ({
  txt: {
    float: "right",
    height: "100%",
    maxWidth: 420,
    width: "40%",
    "@media (max-width: 930px)": {
      display: "none",
    },
  },
}));

/**
 * ExpandedMenu component
 * expanded menu for desktop/tablet view
 * @param props
 */
const ExpandedMenu: React.FC<ExpandedMenuProps> = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const [hovered, setHovered] = useState(false);
  const [isCreatedItemMenu, setIsCreatedItemMenu] = useState<any>(true);
  const pages = props.pages ? props.pages : [];
  const classes = styled();
  const history = useHistory();
  const allCategories = {
    id: "allCategories",
    children: pages,
    name: t("AllCategoriesExpandedMenu.AllCategoriesLabel"),
  };
  const categories = [allCategories, ...pages];
  const selectMenuItem = (cid: string) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };

  React.useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);
  React.useEffect(() => {
    setTimeout(()=> {
      setIsCreatedItemMenu(document.querySelector('.custom-div'));
    },1000)
    
  }, []);

  const handleToDistributor = () => {
    if (history.location.pathname != "/ventaporcatalogo") {
      history.push("/ventaporcatalogo");
    } else {
      return;
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  
  return (
    <StyledBox className="expanded-menu-container" style={styleContainerMenu}>
      <StyledContainer overflow="hidden" style={styleContainer}>
        <StyledBox
          style={styleBox}
          display="flex"
          direction="row"
          flexWrap="nowrap"
          justifyContent="flex-start"
          alignContent="flex-start">
            {isCreatedItemMenu && 
              <div onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} 
                  style={{paddingTop:hovered ? "11px" : "13px", paddingBottom: "16px", marginLeft: "5px", paddingLeft: "9px", marginRight: "5px",paddingRight: "9px", fontSize: "15px", fontWeight: 300, textTransform: "uppercase", borderTop: hovered ? "5px solid rgb(0, 176, 170)" : "none", height: "44.11px"}}
              >
                <EspotCuentaRegresiva espotName={"ItemMenu1"} />
              </div>}
          
          {categories.map((page: any, index: number) => {
            if (page.id !== "allCategories") {
              return (
                <MenubarMenuItem
                  key={page.id}
                  selectedMenuItem={selectedMenuItem}
                  selectMenuItem={selectMenuItem}
                  page={page}
                />
              );
            }
          })}
        </StyledBox>

        <div className={classes.txt}>
          <div style={styledDivLeft}>
            <StyledGrid
              style={styledLinks}
              onClick={handleToDistributor}
              // ={"/ventaporcatalogo"}
              className={"menu-distribuidor"}>
              ¿Te interesa la venta por catálogo?
            </StyledGrid>
          </div>
          <div style={styledDivRight}>
            <img
              alt="¿Necesitas ayuda?"
              style={styledIconHelp}
              src="https://dpjye2wk9gi5z.cloudfront.net/wcsstore/DportenisStorefrontAssetStore/Attachment/2020/centroayuda/support.svg"
            />

            <Link
              style={styledLinks}
              to={"/centro-de-ayuda"}
              className={"menu-link-help"}>
              {" ¿Necesitas ayuda?"}
            </Link>
          </div>
        </div>
      </StyledContainer>
    </StyledBox>
  );
};

export default ExpandedMenu;
