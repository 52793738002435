/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/* eslint-disable */
//Standard libraries
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  CSSProperties,
} from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";
import { Link } from "react-router-dom";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import { LOCALE } from "../../_foundation/constants/common";
import {
  localStorageUtil,
  storageSessionHandler,
  storageStoreIdHandler,
} from "../../_foundation/utils/storageUtil";
import {
  // StyledButton,
  StyledTextField,
} from "../../components/elements";
//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import MiniCart from "./MiniCart";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "../custom/header/ExpandedMenu";
import { SearchBar } from "../custom/styledSearchBar";
import AccountPopperContent from "./AccountPopperContent";
import PersonIcon from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CardMemberIcon from "@material-ui/icons/CardMembership";
//Redux
import {
  userNameSelector,
  loginStatusSelector,
  logonIdSelector,
  userIdSelector,
} from "../../redux/selectors/user";

import { numItemsSelector } from "../../redux/selectors/order";

import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";

import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
// Context
import ModalContext from "../../_foundation/context/ModalContext";
//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Hidden } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Sign from "../custom/styledSignIn/pages/SignIn";
import styled from "styled-components";

import { GrFormClose } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";

import { RegistrationLayout } from "../custom/styledSignIn/widgets/Registration";
import { ModalProductsOnCart } from "../pages/_sapphire/dashboard/ModalProductsOnCart";
import { ModalRegistratePhone } from "../pages/_sapphire/dashboard/ModalRegistratePhone";
import { Cta } from "../pages/_sapphire/dashboard/ModalProductsOnCart";

import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeaderActions,
  StyledTypography,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledLink,
  StyledPaper,
  StyledBox,
  StyledSearchBarButton,
} from "@hcl-commerce-store-sdk/react-component";

import { StyledHeader } from "../custom/header";

import logoBlanco from "../../img/dpdirecto_blanco_header.png";
import backArrow from "../../img/back_arrow.png";

import "../custom/header/dpt.css";
import "../custom/header/dpt.responsive.css";
import axios from "axios";
import EspotCuentaRegresiva from "../custom/espot/DpSpots";

const styledLinks: CSSProperties = {
  height: "100%",
  width: "100%",
  fontSize: "0.98em",
  color: "white",
};

const styledIconHelp: CSSProperties = {
  verticalAlign: "middle",
};

interface HeaderProps {
  loggedIn: boolean;
  callLoginFromAnotherComponent: boolean;
  setOpenLoginFromAnotherComponent: any;
}

const TextHeader = styled.span`
  color: #000;
  font-size: 18px;
  margin-top: 0;
  font-family: Fjalla One, sans-serif;
  margin-bottom: 40px;
  text-transform: uppercase;
  line-height: 46px;
`;

const styledLoginModal = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "25px",
    marginTop: "25px",
  },
  divItem: {
    marginTop: "30px",
  },
  divItemHead: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #e9e9e9",
    },
  },
  divItemHeadReg: {
    [theme.breakpoints.up("sm")]: {
      margin: "0px 60px 25px 60px",
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  box: {
    outline: "none !important",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "40px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "690px",
      top: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      top: "50%",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  boxRegistro: {
    display: "block",
    overflowY: "auto",
    maxHeight: "90vh",
    paddingLeft: "10px",
    paddingRight: "5px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    left: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "690px",
      top: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "50%",
      width: "90%",
      paddingLeft: "10px",
      paddingRight: "5px",
      paddingBottom: "20px",
    },
  },
  divCerrar: {
    marginTop: "10px",
    justifyItems: "right",
    textAlign: "right",
  },
  divCerrarReg: {
    marginTop: "10px",
    justifyItems: "right",
    textAlign: "right",
    marginRight: "10px",
    padding: "0px !important",
  },
  textDesk: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textRes: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    borderbottom: "1px solid #C7C7C7",
  },
  divHeaderReg: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #C7C7C7",
    },
  },
  iconButton: {
    cursor: "pointer",
  },
  backArrow: {
    position: "absolute",
    paddingTop: "10px",
    verticalAlign: "center",
    color: "#000",
  },
}));

const headerStyles = makeStyles((theme) => ({
  overFlowContent: {
    "@global": {
      overflowX: "hidden !important",
    },
  },
}));

const CustomStyledSwipeableDrawer = styled(StyledSwipeableDrawer)`
  @media (min-width: 869px) and (max-width: 959px) {
    .MuiDrawer-paperAnchorRight {
      background-color: "#007985";
    }
  }
`;

const LoginModal = (props) => {
  const classesLoginModal = styledLoginModal();
  const [showSignIn, setShowSignIn] = useState(true);
  const toggleSignInRegisterpage = (flag: boolean) => {
    setShowSignIn(flag);
  };
  const openModalLogin = props.openModalLogin;
  const handleCloseModalLogin = props.handleCloseModalLogin;
  const openModalReg = props.openModalReg;
  const handleSwitchModalLogin = props.handleSwitchModalLogin;
  return (
    <>
      <Modal
        open={openModalLogin}
        onClose={handleCloseModalLogin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <StyledGrid
          container
          alignItems="center"
          style={{ outline: "none !important" }}>
          {!openModalReg && (
            <Box
              className={classesLoginModal.box}
              style={{ outline: "none !important" }}>
              <Grid item md={12} className={classesLoginModal.divCerrar}>
                <GrFormClose
                  className={classesLoginModal.iconButton}
                  size={35}
                  onClick={handleCloseModalLogin}
                />
              </Grid>
              <Grid item md={12} className={classesLoginModal.divItemHead}>
                <TextHeader className={classesLoginModal.textDesk}>
                  ENTRA A TU CUENTA DPDIRECTO O REGÍSTRATE COMO USUARIO
                </TextHeader>
                <TextHeader className={classesLoginModal.textRes}>
                  ENTRA A TU CUENTA O REGÍSTRATE
                </TextHeader>
              </Grid>
              <Sign
                switchModalLoginReg={handleSwitchModalLogin}
                handleCloseModalLogin={handleCloseModalLogin}
              />
            </Box>
          )}
          {openModalReg && (
            <Box className={classesLoginModal.boxRegistro}>
              <Grid item md={12} className={classesLoginModal.divCerrarReg}>
                <GrFormClose
                  className={classesLoginModal.iconButton}
                  size={35}
                  onClick={handleCloseModalLogin}
                />
              </Grid>
              <Grid item md={12} className={classesLoginModal.divItemHeadReg}>
                <Grid className={classesLoginModal.divHeaderReg}>
                  <Grid className={classesLoginModal.backArrow}>
                    <IoIosArrowBack
                      className={classesLoginModal.iconButton}
                      size={25}
                      onClick={handleSwitchModalLogin}
                    />
                  </Grid>
                  <TextHeader className={classesLoginModal.textDesk}>
                    CREA UNA CUENTA
                  </TextHeader>
                  <TextHeader className={classesLoginModal.textRes}>
                    CREA UNA CUENTA
                  </TextHeader>
                </Grid>
                <RegistrationLayout
                  cid="sign-in-registration-page-new-registration"
                  showSignInPage={toggleSignInRegisterpage}
                />
              </Grid>
            </Box>
          )}
        </StyledGrid>
      </Modal>
    </>
  );
};

const ProductsModal = () => {
  const styles = makeStyles((theme) => ({
    modalContent: {
      maxWidth: "500px",
      padding: "30px 10px",
      [theme.breakpoints.up("sm")]: {
        padding: "30px",
      },
    },
    iconClose: {
      position: "absolute",
      top: 0,
      right: 0,
      paddingTop: "1rem",
      paddingRight: "1rem",
      display: "flex",
      justifyContent: "end",
      alignItems: "end",
    },
  }));
  const classes = styles();

  const { setCounterClicks, isOpen, setIsOpen, counterClicks, setCloseSesion } =
    useContext(ModalContext);
  const numItems = useSelector(numItemsSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const handleStay = () => {
    setIsOpen(false);
    setCounterClicks(counterClicks + 1);
    setCloseSesion(false);
  };
  return (
    <>
      <Modal open={isOpen && numItems >= 1 && counterClicks < 2}>
        <StyledGrid
          container
          alignItems="center"
          style={{ outline: "none !important" }}>
          {isOpen && numItems >= 1 && counterClicks < 2 && (
            <Box
              className={classes.modalContent}
              style={{ outline: "none !important" }}>
              <GrFormClose
                className={classes.iconClose}
                size={35}
                onClick={handleStay}
              />
            </Box>
          )}
        </StyledGrid>
      </Modal>
    </>
  );
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  const widgetName = getDisplayName(Header);
  const classes = headerStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [counter, setCounter] = useState(0);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const userId = useSelector(userIdSelector);
  const [myAccountPopperOpen, setMyAccountPopperOpen] =
    useState<boolean>(false);
  const myAccountElRef = useRef<HTMLButtonElement>(null);

  // Context
  const {
    setCounterClicks,
    isOpen,
    setIsOpen,
    counterClicks,
    setCloseSesion,
    closeSesion,
    setLevelStatus,
    setClicksModalLevelStatus,
    linkHeader,
    setLinkHeader,
  } = useContext(ModalContext);

  const addPhoneAfter = localStorage.getItem("addAfter");

  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);
  const [fromHeaderComponent, setFromHeaderComponent] = useState<boolean>(true);

  const [languageTogglePopperOpen, setLanguageTogglePopperOpen] =
    useState<boolean>(false);
  const languageToggleElRef = useRef<HTMLButtonElement>(null);

  const { mySite } = useSite();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { firstName, lastName } = useSelector(userNameSelector);
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const logonId = useSelector(logonIdSelector);
  const userPreviousLoggedIn = useRef();
  const numItems = useSelector(numItemsSelector);
  const locale = localStorageUtil.get(LOCALE);
  const [isCreatedItemMenu, setIsCreatedItemMenu] = useState<any>();
  const isB2B = Boolean(mySite?.isB2B);
  const loggedIn = props.loggedIn;
  const isShoppingEnabled = !isB2B || (isB2B && loggedIn);

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = !useMediaQuery(theme.breakpoints.up("md"));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [openModalReg, setOpenModalReg] = React.useState(false);

  const dataForgetProductsOnCartLogIn = {
    title: `¡NO OLVIDES TERMINAR TU COMPRA!`,
    text: `Parece que dejaste productos en tu carrito. Completa tu compra mientras tu producto está en existencia.`,
    ctas: [
      {
        id: 1,
        to: "/cart",
        text: "IR AL CARRITO",
        variant: "secondary",
      } as Cta,

      {
        id: 2,
        to: "/",
        text: "SEGUIR COMPRANDO",
        variant: "primary",
      } as Cta,
    ],
  };
  const dataForgetProductsOnCartLogOut = {
    title: `¡NO OLVIDES TERMINAR TU COMPRA!`,
    text: ` Parece que dejaste productos en tu carrito. Completa tu compra mientras tu producto está en existencia.`,
    ctas: [
      {
        id: 1,
        to: "/cart",
        text: "IR AL CARRITO",
        variant: "secondary",
      } as Cta,

      {
        id: 2,
        to: "/",
        text: "CERRAR SESIÓN",
        variant: "primary",
      } as Cta,
    ],
  };

  async function getUserLevelStatus() {
    const url = `/wcs/resources/store/${mySite.storeID}/rangoCuenta/rangoCuentaSocio`;
    const data = {
      action: "rango cuenta",
      userId: userId,
      reason: "rango",
    };
    const headers = {
      "Content-type": "application/json; charset=UTF-8",
      WCTrustedToken: currentUser?.WCTrustedToken,
      WCToken: currentUser?.WCToken,
    };
    let resultado = await axios
      .post(url, data, { headers })
      .then((response) => {
        // console.log(response, "response ");
        setLevelStatus(response.data);
      })
      .catch((e) => {
        console.log("error", e.getMessage());
      });
  }

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    setLanguageTogglePopperOpen(false);
    getUserLevelStatus();
  };

  const handleMyAccountPopperClose = () => {
    setMyAccountPopperOpen(false);
  };

  const handleMiniCartClick = () => {
    // console.log(success, "success", success.key, "succes.key");
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
        // console.log(miniCartElRef.current, "minacrt.current");
      }
    }, 100);
  };

  const handleMiniCartPopperClose = () => setMiniCartPopperOpen(false);

  const handleLanguageToggleClick = () => {
    setLanguageTogglePopperOpen(true);
    setMiniCartPopperOpen(false);
    setMyAccountPopperOpen(false);
  };
  const handleLanguageTogglePopperClose = () => {
    setLanguageTogglePopperOpen(false);
  };

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payload,
      })
    );
    history.push(ROUTES.HOME);
  };

  const handleContractChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    history.push(ROUTES.HOME);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    setCloseSesion(true);
    setOpenMenu(false);
    if (numItems != 0) {
      setIsOpen(true);
      setCounterClicks(0);
    } else {
      const param: any = {
        ...payload,
      };
      dispatch(LOGOUT_REQUESTED_ACTION(param));
      handleSwitchModalLogin();
      setCounterClicks(0);
      setClicksModalLevelStatus(0);
    }
  };
  // console.log(mySite, "mySite");
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();

  // console.log(userData, "userData");

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      if (
        window.location.pathname !== process.env.REACT_APP_EXPIRED_SESSION ||
        userLoggedIn
      ) {
        history.push(ROUTES.HOME);
        window.resetPass = false;
      }
    } else {
      handleCloseModalLogin();
      setCounterClicks(0);
      setCloseSesion(false);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      axios
        .get(
          `/search/resources/api/v2/categories?storeId=${storeID}&depthAndLimit=91%2C91&langId=-5`
        )
        .then((res) => {
          setTopCategories(res.data.contents);
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, locale]);

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [widthScreen, setWidthScreen] = useState(screen.width);

  const getSizeScreen = () => {
    window.addEventListener("resize", function () {
      setOpen(false);
    });
  };

  useEffect(() => {
    getSizeScreen();
  }, [widthScreen]);

  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
    setOpenMenu(false);
    setOpenModalReg(false);
  };

  const handleCloseModalLogin = () => setOpenModalLogin(false);
  const handleCloseModalReg = () => setOpenModalReg(false);

  const handleSwitchModalLogin = () => {
    setOpenModalReg(!openModalReg);
  };
  const goToSignInPage = () => {
    setOpenMenu(!openMenu);
    if (history.location.pathname != "/ventaporcatalogo") {
      history.push("/ventaporcatalogo");
      console.log("bug---entre al else RegistrationB2BLayout");
      let testimonios = document.getElementsByClassName("testimonialSlider");
      compruebaElemento();
      console.log(testimonios, "testimoinios");
      async function compruebaElemento() {
        do {
          await timeout(1000);
          await leerElemento();
        } while (testimonios[0] == null);
      }
      function leerElemento() {
        testimonios = document.getElementsByClassName("testimonialSlider");
        if (testimonios[0] != null) {
          setTimeout(() => {
            moveToForm();
          }, 1000);
        }
        return false;
      }
    } else {
      moveToForm();
    }
    handleCloseModalLogin();
  };

  const prueba = () => {
    console.log(history.location.pathname, "bug ---> location");
    if (history.location.pathname === "/ventaporcatalogo") {
      alert("hola");
      console.log("bug---entre al if Header");
      return;
    } else {
      alert("adios");
      console.log("bug---entre al  Else Header");
      let testimonios = document.getElementsByClassName("testimonialSlider");
      compruebaElemento();
      async function compruebaElemento() {
        do {
          await timeout(1000);
          await leerElemento();
        } while (testimonios[0] == null);
      }
      function leerElemento() {
        testimonios = document.getElementsByClassName("testimonialSlider");
        if (testimonios[0] != null) {
          console.log("bug---entre a leerElemento");
          setTimeout(() => {
            moveToForm();
          }, 1000);
        }
        return false;
      }
    }
  };

  const moveToForm = () => {
    console.log("bug --> entre MoveToFomr Header");
    let divForm = document.getElementById("distributor");
    if (divForm != null) {
      divForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleLinkCatalogo = (e) => {
    setOpenMenu(!openMenu);
    history.push("ventaporcatalogo");
  };

  useEffect(() => {
    if (!openModalLogin) {
      window.resetPass = false;
    }
  }, [openModalLogin]);

  useEffect(() => {
    if (props.callLoginFromAnotherComponent) {
      handleOpenModalLogin();
      props.setOpenLoginFromAnotherComponent(false);
    }
  }, [props.callLoginFromAnotherComponent]);

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
  // console.log(openModal, "openMODAL")
  const mystyle = {
    height: "65px",
    width: "192px",
  };

  let viewHeaderCart = false;
  if (
    window.location.pathname === "/Sapphire/cart" ||
    window.location.pathname === "/cart" ||
    window.location.pathname === "/Sapphire/checkout/shipping" ||
    window.location.pathname === "/checkout/shipping"
  ) {
    viewHeaderCart = true;
  }

  const handleBack = (e) => {
    // e.preventDefault()
    history.push(ROUTES.HOME);
  };
  useEffect(() => {
    if (closeSesion && isOpen === false && counterClicks < 2) {
      const param: any = {
        ...payload,
      };
      dispatch(LOGOUT_REQUESTED_ACTION(param));
      setClicksModalLevelStatus(0);
    }
  }, [isOpen, closeSesion, counterClicks]);

  // console.log(counterClicks);

  const handleClickParenteSpot = () => {
    const enlaceHijo = document.querySelector('.custom-div');
    if (enlaceHijo) {
      window.location.href = enlaceHijo.getAttribute('data-url') || '';
    }
  };

  React.useEffect(() => {
    setTimeout(()=> {
      setIsCreatedItemMenu(document.querySelector('.custom-div'));
    },1000)
    
  }, []);

  const HeaderCart = () => (
    <>
      {isMobile ? (
        <div
          style={{
            backgroundColor: "rgb(46, 48, 71)",
            height: "45.29px",
            width: "100%",
            display: "inline-block",
          }}>
          <div style={{ display: "inline-block", color: "white" }}>
            <span
              onClick={handleBack}
              style={{
                display: "inline-block",
                padding: "15px 20px 15px 20px",
              }}>
              <img
                style={{
                  color: "white",
                  width: "14px",
                  height: "14px",
                  marginRight: "auto",
                  marginBottom: "7px",
                }}
                src={backArrow}
              />
            </span>
            <span onClick={handleBack} style={{ display: "inline-block" }}>
              <img
                style={{
                  width: "50%",
                  height: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginTop: "10px",
                }}
                src={logoBlanco}
              />
            </span>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "rgb(46,48,71)",
            height: "58.29px",
            width: "100%",
            display: "inline-block",
          }}>
          <span
            onClick={handleBack}
            style={{ display: "inline-block", padding: "15px 20px 15px 20px" }}>
            <img
              style={{
                width: "142px",
                height: "23px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                cursor: "pointer",
              }}
              src={logoBlanco}
            />
          </span>
          <div
            style={{
              display: "flex",
              float: "right",
              position: "relative",
              marginRight: "20px",
              height: "100%",
              alignItems: "center",
            }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "300",
                color: "white",
              }}>
              <img
                alt="¿Necesitas ayuda?"
                src="https://dpjye2wk9gi5z.cloudfront.net/wcsstore/DportenisStorefrontAssetStore/Attachment/2020/centroayuda/support.svg"
                width="13px"
                style={{
                  color: "white",
                  fontWeight: "400",
                  verticalAlign: "middle",
                  marginRight: "2px",
                }}
              />
              &nbsp;&nbsp;¿Ayuda?, llama{" "}
              <a href="tel:6699155300" style={{ color: "white" }}>
                {" "}
                66 99 15 53 00{" "}
              </a>
            </p>
          </div>
        </div>
      )}
      {!isMobile && <div></div>}
    </>
  );

  return (
    <StyledHeader className="no-print">
      {viewHeaderCart && <HeaderCart />}
      <StyledContainer className={!viewHeaderCart ? "mobile-border" : ""}>
        {!viewHeaderCart && (
          <StyledGrid
            container
            justify={!viewHeaderCart ? "space-between" : "left"}
            alignItems={!viewHeaderCart ? "center" : "left"}>
            {!viewHeaderCart && (
              <Hidden mdUp>
                <StyledGrid item>
                  <button
                    className="menu-hamburger"
                    data-testid="menu-hamburger-element"
                    onClick={() => setOpen(!open)}>
                    <div className="menu-departamentos">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>
                </StyledGrid>
              </Hidden>
            )}

            {!viewHeaderCart && (
              <StyledGrid item>
                <StyledGrid
                  className="header-topbarSection"
                  container
                  alignItems="center"
                  spacing={2}>
                  {mySite != null && (
                    <StyledGrid item className="separadorHeader">
                      <div
                        className="header-branding"
                        style={{
                          marginTop: "-8px",
                        }}>
                        {/* <a href="home"> */}
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/");
                            setCounterClicks(counterClicks + 2);
                          }}>
                          <img
                            style={mystyle}
                            src="https://dpjye2wk9gi5z.cloudfront.net/wcsstore/Dpdirecto/images/logo-dpdirecto.png"
                            alt="DpDirecto"
                          />
                        </button>
                        {/* </a> */}
                      </div>
                    </StyledGrid>
                  )}
                </StyledGrid>
              </StyledGrid>
            )}

            {!viewHeaderCart && (
              <Hidden smDown>
                <StyledGrid item>
                  <StyledGrid
                    className="header-topbarSection"
                    container
                    alignItems="center"
                    spacing={2}>
                    <Hidden smDown>
                      <StyledGrid
                        className="separador"
                        item
                        data-testid="search-bar-desktop-largetablet-element">
                        <SearchBar
                          showSearchBar={showSearchBar}
                          closeSearchBar={() => setShowSearchBar(false)}
                          openSearchBar={() => setShowSearchBar(true)}
                        />
                      </StyledGrid>
                    </Hidden>
                  </StyledGrid>
                </StyledGrid>
              </Hidden>
            )}

            <Hidden smDown>
              <StyledGrid item>
                <StyledGrid
                  className="header-topbarSection separadorCuenta"
                  container
                  alignItems="center"
                  direction="row">
                  <Hidden smUp>
                    <StyledGrid item data-testid="search-bar-mobile-element">
                      <StyledSearchBarButton
                        onClick={() => setShowSearchBar(!showSearchBar)}
                        className={`header-actionsButton ${
                          showSearchBar && "active"
                        }`}
                        variant="text"
                        color="secondary">
                        <SearchIcon />
                      </StyledSearchBarButton>
                    </StyledGrid>
                  </Hidden>
                  {loggedIn && !viewHeaderCart ? (
                    <StyledGrid item>
                      <StyledButton
                        ref={myAccountElRef}
                        variant="text"
                        color="secondary"
                        className="header-actionsButton"
                        onClick={handleMyAccountClick}>
                        {isTablet ? (
                          <StyledHeaderActions>
                            <AccountBoxIcon />
                            <StyledTypography variant="body1" component="p">
                              {isMobile
                                ? t("Header.Actions.Account")
                                : t("Header.Actions.YourAccount")}
                            </StyledTypography>
                          </StyledHeaderActions>
                        ) : (
                          <StyledBox
                            className="welcome-text"
                            display="flex"
                            flexDirection="column">
                            <StyledTypography
                              variant="button"
                              component="p"
                              className="textoPrincipal">
                              {firstName
                                ? t("Header.Actions.WelcomeFirstname", {
                                    firstName,
                                  })
                                : t("Header.Actions.WelcomeNoFirstname", {
                                    lastName,
                                  })}
                            </StyledTypography>

                            <StyledBox
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              flexWrap="wrap">
                              <StyledTypography
                                variant="body2"
                                style={{ fontWeight: "400" }}>
                                {t("Header.Actions.YourAccount")}
                              </StyledTypography>
                              {myAccountPopperOpen ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </StyledBox>
                          </StyledBox>
                        )}
                      </StyledButton>
                      <StyledAccountPopper
                        id={myAccountPopperId}
                        open={myAccountPopperOpen}
                        anchorEl={myAccountElRef.current}
                        onClose={handleMyAccountPopperClose}
                        placement="bottom-end"
                        modifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: false,
                            boundariesElement: "scrollParent",
                          },
                          hide: {
                            enabled: true,
                          },
                        }}
                        className="account-popper">
                        <ClickAwayListener
                          onClickAway={handleMyAccountPopperClose}>
                          <StyledPaper className="horizontal-padding-2">
                            <StyledTypography
                              variant="body1"
                              component="div"
                              style={{ padding: "0px" }}>
                              <AccountPopperContent
                                handleClose={handleMyAccountPopperClose}
                                handleOrgChange={handleOrgChange}
                                handleContractChange={handleContractChange}
                                handleLogout={handleLogout}
                                isB2B={isB2B}
                                userName={{ firstName, lastName }}
                                logonId={logonId}
                                counter={counter}
                              />
                            </StyledTypography>
                          </StyledPaper>
                        </ClickAwayListener>
                      </StyledAccountPopper>
                    </StyledGrid>
                  ) : !viewHeaderCart ? (
                    <StyledGrid item onClick={handleOpenModalLogin}>
                      {isMobile && !viewHeaderCart ? (
                        <StyledButton
                          className="header-actionsButton"
                          variant="text"
                          color="secondary">
                          <StyledHeaderActions>
                            <AccountBoxIcon />
                            <StyledTypography variant="body1">
                              {t("Header.Actions.SignIn")}
                            </StyledTypography>
                          </StyledHeaderActions>
                        </StyledButton>
                      ) : !viewHeaderCart ? (
                        <StyledButton
                          id="btn_register"
                          className="header-actionsButton btn-count-register"
                          variant="text"
                          color="secondary">
                          <StyledHeaderActions>
                            <PersonIcon className="iconoPerson" />
                            <StyledTypography
                              className="textoPrincipal"
                              variant="body1"
                              component="span">
                              {t("Header.Actions.SignInRegister")}
                            </StyledTypography>
                          </StyledHeaderActions>
                        </StyledButton>
                      ) : (
                        <></>
                      )}
                    </StyledGrid>
                  ) : (
                    <></>
                  )}
                  {
                    //Se agrego de nuevo validacion de isShoppingEnabled que viene de caja para solo mostrar el carrito cuando esta logueado
                    !viewHeaderCart && isShoppingEnabled && (
                      <StyledGrid item>
                        <MiniCart
                          id={miniCartPopperId}
                          open={miniCartPopperOpen}
                          handleClick={handleMiniCartClick}
                          handleClose={handleMiniCartPopperClose}
                          ref={miniCartElRef}
                        />
                      </StyledGrid>
                    )
                  }
                </StyledGrid>
              </StyledGrid>
            </Hidden>

            {!viewHeaderCart && (
              <Hidden mdUp>
                <StyledGrid item>
                  <button
                    id="btn_menu_mobile"
                    className="menu-hamburger"
                    data-testid="menu-hamburger-element"
                    onClick={() => setOpenMenu(!openMenu)}>
                    <div className="menu-general">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>
                </StyledGrid>
              </Hidden>
            )}
          </StyledGrid>
        )}
      </StyledContainer>

      {!viewHeaderCart && (
        <Hidden mdUp>
          <StyledContainer className="bottom-padding-1 searchMobile">
            <SearchBar
              showSearchBar={showSearchBar}
              closeSearchBar={() => setShowSearchBar(false)}
              openSearchBar={() => setShowSearchBar(true)}
            />
          </StyledContainer>
        </Hidden>
      )}

      {!viewHeaderCart && (
        <Hidden smDown>
          <ExpandedMenu pages={topCategories} />
        </Hidden>
      )}

      <CustomStyledSwipeableDrawer
        anchor={useMediaQuery(theme.breakpoints.up("sm")) ? "left" : "left"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        className={"header-menu " + classes.overFlowContent}
        data-testid="header-menu-drawer-element">
        {!viewHeaderCart && (
          <>
            <div
              className="btn_dep mobileOnly activo active"
              style={{
                /*overflowX: "hidden",*/
                top: "8px",
                backgroundColor: "#007985 !important",
              }}
              onClick={() => setOpen(!open)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="titleMobilDepa">
              <i>Categorías</i>
            </div>
            {/* {isCreatedItemMenu &&  */}
              <div onClick={handleClickParenteSpot} style={{padding: "0 24px", fontSize: "1.5em", fontWeight: "600", borderBottom: "1px solid #007985",height: "53px", display:"flex", alignItems:"center", backgroundColor: "#1bb0aa"}}>
                <EspotCuentaRegresiva espotName={"ItemMenu1"} />
              </div>
             {/*  } */}
            
            <StyledContainer className="container-departamentos">
              <MegaMenu
                menutitle={t("MegaMenu.Title")}
                pages={topCategories}
                closeMegaMenu={() => setOpen(false)}
              />
            </StyledContainer>
          </>
        )}
      </CustomStyledSwipeableDrawer>

      <CustomStyledSwipeableDrawer
        id="menu_mobile"
        anchor={useMediaQuery(theme.breakpoints.up("sm")) ? "right" : "right"}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        onOpen={() => setOpenMenu(true)}
        className="header-menu-general mobile-menu"
        data-testid="header-menu-drawer-element">
        {!viewHeaderCart && (
          <StyledContainer className="header-menu-general-container">
            <StyledGrid
              container
              spacing={2}
              className={"menu-container-general " + (openMenu ? "open" : "")}>
              <div id="submenuFixed" className="visible activo">
                <div className="titleMobilDepa right">
                  <i>Menú Principal</i>
                </div>
                <div
                  id="btn_close_mobilemenu"
                  className="barras-menu mobileOnly active activo"
                  onClick={() => setOpenMenu(!openMenu)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="contenedorBuscador2"></div>

                <div className="margin-100 opcionesMenuGeneral">
                  {isShoppingEnabled && (
                    <StyledLink
                      to={"/cart"}
                      onClick={() => setOpenMenu(!openMenu)}>
                      <StyledButton
                        className="header-actionsButton"
                        variant="text"
                        color="secondary">
                        <StyledHeaderActions>
                          <ShoppingCartIcon className="iconCarritoWhite" />
                          <StyledTypography
                            className="textoPrincipal mobile"
                            variant="body1"
                            component="span">
                            Carrito de compras
                          </StyledTypography>
                        </StyledHeaderActions>
                      </StyledButton>
                    </StyledLink>
                  )}
                </div>

                <div className="opcionesMenuGeneral">
                  {loggedIn ? (
                    <StyledButton
                      className="header-actionsButton"
                      variant="text"
                      color="secondary"
                      onClick={(event) => handleLogout(event)}>
                      <StyledHeaderActions>
                        <PersonIcon className="iconoPersonWhite" />
                        <StyledTypography
                          className="textoPrincipal mobile"
                          variant="body1"
                          component="span">
                          Cerrar sesión
                        </StyledTypography>
                      </StyledHeaderActions>
                    </StyledButton>
                  ) : (
                    <StyledButton
                      id="btn_register_mobile"
                      className="header-actionsButton"
                      variant="text"
                      color="secondary"
                      onClick={() => handleOpenModalLogin()}>
                      <StyledHeaderActions>
                        <PersonIcon className="iconoPersonWhite" />
                        <StyledTypography
                          className="textoPrincipal mobile"
                          variant="body1"
                          component="span">
                          Entra a tu cuenta
                        </StyledTypography>
                      </StyledHeaderActions>
                    </StyledButton>
                  )}
                </div>

                <div className="opcionesMenuGeneral">
                  {loggedIn ? (
                    <StyledLink to={ROUTES.DASHBOARD}>
                      <StyledButton
                        className="header-actionsButton "
                        variant="text"
                        color="secondary"
                        onClick={() => setOpenMenu(!openMenu)}>
                        <StyledHeaderActions>
                          <PersonIcon className="iconoAddPersonWhite" />
                          <StyledTypography
                            className="textoPrincipal mobile"
                            variant="body1"
                            component="span">
                            Mi cuenta
                          </StyledTypography>
                        </StyledHeaderActions>
                      </StyledButton>
                    </StyledLink>
                  ) : (
                    <StyledButton
                      onClick={goToSignInPage}
                      className="header-actionsButton"
                      variant="text"
                      color="secondary">
                      <StyledHeaderActions>
                        <PersonAdd className="iconoAddPersonWhite" />
                        <StyledTypography
                          className="textoPrincipal mobile"
                          variant="body1"
                          component="span">
                          Obtén tu cuenta
                        </StyledTypography>
                      </StyledHeaderActions>
                    </StyledButton>
                  )}
                </div>
                <div>
                  <div className="opcionesMenuGeneral">
                    <Link
                      style={styledLinks}
                      to={"/ventaporcatalogo"}
                      onClick={() => {
                        setOpenMenu(!openMenu);
                      }}>
                      <CardMemberIcon className="iconoAddDistWhite" />
                      <StyledTypography
                        className="textoPrincipal mobile"
                        variant="body1"
                        component="span">
                        ¿Te interesa la venta por catálogo?{" "}
                      </StyledTypography>
                    </Link>
                  </div>
                  <div className="opcionesMenuGeneral">
                    <img
                      alt="¿Necesitas ayuda?"
                      style={styledIconHelp}
                      className="iconSupportMobile"
                      src="https://dzqguq0tiw9xx.cloudfront.net/wcsstore/DportenisStorefrontAssetStore/Attachment/2020/centroayuda/support.svg"
                    />
                    <Link
                      style={styledLinks}
                      to={"/centro-de-ayuda"}
                      onClick={() => setOpenMenu(!openMenu)}>
                      <StyledTypography
                        className="textoPrincipal mobile"
                        variant="body1"
                        component="span">
                        ¿Necesitas ayuda?
                      </StyledTypography>
                    </Link>
                  </div>
                  <div className="opcionesMenuGeneral">
                    <Link
                      style={styledLinks}
                      to={"/terminos-condiciones"}
                      onClick={() => setOpenMenu(!openMenu)}>
                      <StyledTypography
                        className="mobile terminos"
                        variant="body1"
                        component="span">
                        Términos y condiciones
                      </StyledTypography>
                    </Link>
                  </div>
                </div>
              </div>
            </StyledGrid>
          </StyledContainer>
        )}
      </CustomStyledSwipeableDrawer>

      <LoginModal
        openModalLogin={openModalLogin}
        handleCloseModalLogin={handleCloseModalLogin}
        openModalReg={openModalReg}
        handleSwitchModalLogin={handleSwitchModalLogin}
      />
      {closeSesion && (
        <ModalProductsOnCart
          text={dataForgetProductsOnCartLogOut.text}
          title={dataForgetProductsOnCartLogOut.title}
          ctas={dataForgetProductsOnCartLogOut.ctas}
        />
      )}
      {userLoggedIn &&
        closeSesion === false &&
        currentUser.isLogonIdValid === "true" && (
          <ModalProductsOnCart
            title={dataForgetProductsOnCartLogIn.title}
            text={dataForgetProductsOnCartLogIn.text}
            ctas={dataForgetProductsOnCartLogIn.ctas}
          />
        )}
      {userLoggedIn &&
        currentUser.isPhoneLinked === "false" &&
        !addPhoneAfter && (
          <ModalRegistratePhone fromHeaderComponent={fromHeaderComponent} />
        )}
    </StyledHeader>
  );
};

export { Header, LoginModal, ProductsModal };
