import Axios,{ AxiosRequestConfig } from "axios";
//import { InfoElectronico } from "./product-information-widget";
import { DEFAULT_INFO_ELECTRONICO, InfoElectronico } from "./ProductoElectronico";
//import { DEFAULT_INFO_ELECTRONICO, InfoElectronico } from "./ProductoElectronico";

const Electronicos = async (storeID, infoElectronicos : Map<string,InfoElectronico>,setInfoElectronicos) => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  //Valida si los productos son electronicos
  async function getInfoElectronicos(){
    let catEntriesId = ""
    if (infoElectronicos && infoElectronicos.size > 0) {
      infoElectronicos.forEach((value,key) => {
        catEntriesId += key + ","
      })
    }
    const urlDepartments = `/wcs/resources/store/${storeID}/department/findbyIds?catentries=${catEntriesId}`
    let config: AxiosRequestConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: urlDepartments,
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: source.token
    };

    Axios.request(config)
      .then(({ data }) => {
        Object.entries(data?.departments).forEach(([clave, valor]) => {
          const product = infoElectronicos.has(clave) ? infoElectronicos.get(clave) : undefined
          if(product)
            product.isElectronico = (valor === 'electronicos')
        });
        //Recupera los precios de contado
        getPriceElectronicos()
        
      })
      .catch((error) => {
        console.error("Error retrieving deparment information", error);
        setInfoElectronicos(infoElectronicos)
      });
  }
  
  //Obtiene el precio de contado de los productos electronicos
  async function getPriceElectronicos(){
    if(infoElectronicos){
      const urlPrecioDeContado = `/wcs/resources/store/${storeID}/price`;
      const params = new URLSearchParams();
      params.append("q","byCatalogEntryIds")
      params.append("checkEntitlement","false")
      params.append("profileName","IBM_Store_EntitledPrice_RangePrice_All")
      params.append("currency","MXN")
      //Filtra los productos electronicos
      infoElectronicos.forEach((value,key) => {
        if(value){
          params.append("catalogEntryId",key)
        }
      })

      let config: AxiosRequestConfig = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: urlPrecioDeContado,
        params,
        headers: { 
          'Content-Type': 'application/json', 
        }
      };
      
      Axios.request(config)
      .then(({data}) => {
        data.EntitledPrice.forEach((entitledPrice) => {
          const priceDeContado = entitledPrice.UnitPrice[0].price.value;
          const id :string = entitledPrice?.productId ? entitledPrice?.productId : "";
          const product = infoElectronicos.has(id) ? infoElectronicos.get(id) : DEFAULT_INFO_ELECTRONICO
          if(product)
            product.prices.offerDeContado = String(priceDeContado)
        })
        //console.log("newInfoElectronicos final",infoElectronicos)
        setInfoElectronicos(infoElectronicos)
      })
      .catch((error) => {
        console.error("Error retrieving price de contado",error);
        setInfoElectronicos(infoElectronicos)
      });
    }
  }

  await getInfoElectronicos();
  //return infoElectronicos;  
}

export { Electronicos }