/*

*==================================================

* Licensed Materials - Property of HCL Technologies

*

* HCL Commerce

*

* (C) Copyright HCL Technologies Limited 2020

*

*==================================================

*/

//Standard libraries

import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

//Custom libraries

import * as ROUTES from "../../constants/routes";

//Redux

import {
  entitledOrgSelector,
  activeOrgSelector,
} from "../../redux/selectors/organization";

import {
  currentContractIdSelector,
  contractSelector,
} from "../../redux/selectors/contract";
import { RootReducerState } from "../../redux/reducers";

//UI

import {
  StyledTypography,
  StyledSelect,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledListItemIcon,
  StyledButton,
  StyledFormControl,
  StyledInputLabel,
  StyledProgressPlaceholder,
} from "@hcl-commerce-store-sdk/react-component";

import { Divider } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import styled from "styled-components";
import { forUserIdSelector, userIdSelector } from "../../redux/selectors/user";
import ModalContext from "../../_foundation/context/ModalContext";
import { storageSessionHandler } from "../../_foundation/utils/storageUtil";
import {
  userNameSelector,
  loginStatusSelector,
  logonIdSelector,
} from "../../redux/selectors/user";

const StyledB2BOrgSection = styled.div`
  ${({ theme }) => `
    background: ${theme.palette.background.default};
    padding: 0px;
  `}
`;

function AccountPopperContent(props: any): JSX.Element {
  const {
    isB2B,
    userName,
    handleClose,
    handleOrgChange,
    handleContractChange,
    handleLogout,
    logonId,
  } = props;

  const { t } = useTranslation();

  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();

  const [activeOrg, setActiveOrg] = useState<any>(null);
  const [activeContract, setActiveContract] = useState<any>(null);
  const [levelAchieved, setLevelAchieved] = useState<any>(null);
  const orderDetails = useSelector(
    (state: RootReducerState) => state.orderDetails
  );
  const orderItems = orderDetails?.detailedOrderItems;
  const entitledOrgs = useSelector(entitledOrgSelector);
  const activeOrgId = useSelector(activeOrgSelector);
  const currentContractId = useSelector(currentContractIdSelector);
  const eligibleContracts = useSelector(contractSelector);
  const forUserId = useSelector(forUserIdSelector);
  const [edit, setEdit] = useState<boolean>(false);
  // Context
  const { levelStatus, numDistribuidor, setNumDistribuidor } =
    useContext(ModalContext);

  useEffect(() => {
    let levels = ["Bronce", "Plata", "Oro", "Platino", "Diamante"];
    if (levelStatus?.response?.rangoAlcanzado !== "") {
      let indexofLevel = levels.indexOf(activeOrg?.displayName);
      console.log(indexofLevel, "levels");
      setLevelAchieved(levels[indexofLevel + 1]);
    }
  }, [levelStatus]);

  let numberStatus;
  if (Math.sign(levelStatus?.response?.total) === -1) {
    numberStatus = Math.ceil(levelStatus?.response?.total) * -1;
  } else {
    numberStatus = Math.ceil(levelStatus?.response?.total);
  }

  const onOrgChange = (event) => {
    setActiveContract(null);
    setEdit(false);
    handleOrgChange(event);
  };

  const onContractChange = (event) => {
    setActiveContract(null);
    setEdit(false);
    handleContractChange(event);
  };

  useEffect(() => {
    if (entitledOrgs && activeOrgId) {
      let org = entitledOrgs.filter((o) => o.organizationId === activeOrgId)[0];
      if (org) {
        setActiveOrg(org);
      }
    }

    if (eligibleContracts && currentContractId) {
      const c = eligibleContracts[currentContractId];
      if (c) {
        setActiveContract(c);
      } else {
        setActiveContract(null);
      }
    }
  }, [entitledOrgs, activeOrgId, currentContractId, eligibleContracts]);

  const B2BOrgContractReadOnly = () => {
    return (
      <>
        <StyledTypography variant="body2" id="header-account-org">
          {"Número de distribuidor:"}
        </StyledTypography>
        {console.log(currentUser, "currentUser AccountPopperContent")}
        <StyledTypography
          id="header-account-activeOrg"
          aria-describedby="header-account-org"
          gutterBottom>
          {currentUser.isLogonIdValid === "false" ? "Pendiente" : logonId}
          {/* {logonId} */}
          {console.log(logonId, "LOGONID")}
        </StyledTypography>

        <StyledTypography variant="body2" id="header-account-org">
          {t("Header.AccountPopper.Nivel")}
        </StyledTypography>

        <StyledTypography
          id="header-account-activeOrg"
          aria-describedby="header-account-org"
          gutterBottom>
          {activeOrg?.displayName}
        </StyledTypography>
        {console.log(activeOrg?.displayName, "activeOrg.displayName")}

        {currentUser.isLogonIdValid === "true" &&
          activeOrg?.displayName !== "Diamante" &&
          (levelStatus?.response?.rangoAlcanzado != "7333333333333337938" &&
          levelStatus?.response?.rangoAlcanzado === "" ? (
            <>
              <StyledTypography
                id="header-account-activeOrg"
                aria-describedby="header-account-org"
                style={{
                  backgroundColor: "#E6F3F8",
                  padding: "10px 10px",
                  borderRadius: "5px",
                  fontSize: "13px",
                  marginBottom: "12px",
                }}>
                <span>
                  Compra ${new Intl.NumberFormat().format(numberStatus)} más
                  para llegar al{" "}
                </span>
                <span style={{ fontWeight: "bold" }}>
                  {levelStatus?.response?.rango}
                </span>{" "}
                <span>y conseguir </span>
                <span style={{ color: "#FD4364", fontWeight: "bold" }}>
                  {levelStatus?.response?.descuento}% de descuento
                </span>
                <span>
                  {" "}
                  a partir del {levelStatus?.response?.fechaFormateada}.
                </span>
              </StyledTypography>
            </>
          ) : (
            currentUser.isLogonIdValid === "true" && (
              <>
                <div
                  style={{
                    color: "#009EB7",
                    fontSize: "9px",
                    marginTop: "-8px",
                    paddingBottom: "10px",
                  }}>
                  Alcanzaste el nivel{" "}
                  <span style={{ fontWeight: "bold" }}>{levelAchieved}</span>{" "}
                  disfruta de tus privilegios
                  <div>
                    a partir del {levelStatus?.response?.fechaFormateada}.
                  </div>
                </div>
              </>
            )
          ))}
      </>
    );
  };

  console.log(currentContractId, "currentContractId");

  const B2BOrgContractForm = () => {
    return (
      <form
        name="headerOrgSwitchForm"
        id={`header-org-contract-form`}
        noValidate>
        {activeOrg && (
          <StyledFormControl variant="outlined">
            <StyledInputLabel
              disableAnimation={true}
              htmlFor="header-account-org">
              {t("Header.AccountPopper.Organization")}
            </StyledInputLabel>

            <StyledSelect
              native
              fullWidth
              value={activeOrg.organizationId}
              onChange={(event) => onOrgChange(event)}
              id="header-account-org">
              {entitledOrgs.map((o) => {
                return (
                  <option key={o.organizationId} value={o.organizationId}>
                    {o.displayName}
                  </option>
                );
              })}
            </StyledSelect>
          </StyledFormControl>
        )}

        {eligibleContracts && (
          <StyledFormControl variant="outlined">
            <StyledInputLabel
              disableAnimation={true}
              htmlFor="header-account-contract">
              {t("Header.AccountPopper.Contract")}
            </StyledInputLabel>

            <StyledSelect
              native
              fullWidth
              value={currentContractId}
              onChange={(event) => onContractChange(event)}
              id="header-account-contract">
              {Object.entries(eligibleContracts).map((v) => {
                const [k, c] = v;

                return (
                  <option key={k} value={k}>
                    {c}
                  </option>
                );
              })}
            </StyledSelect>
          </StyledFormControl>
        )}
      </form>
    );
  };

  const OrgAndContract = () => {
    return (
      <StyledB2BOrgSection style={{ padding: "0px" }}>
        {activeContract ? (
          edit ? (
            <B2BOrgContractForm />
          ) : (
            <B2BOrgContractReadOnly />
          )
        ) : (
          <StyledProgressPlaceholder />
        )}
      </StyledB2BOrgSection>
    );
  };

  const WelcomeSection = () => {
    return (
      <StyledListItem>
        <StyledListItemText
          primary={
            <>
              <StyledTypography variant="body2">
                {t("Header.AccountPopper.Welcome", { ...userName })}
              </StyledTypography>
            </>
          }></StyledListItemText>
      </StyledListItem>
    );
  };

  const AccountSetting = () => {
    return (
      <Link
        to={isB2B ? ROUTES.DASHBOARD : ROUTES.ACCOUNT}
        id="head-popper-myaccount_link"
        onClick={handleClose}>
        <StyledListItem>
          <StyledListItemIcon>
            <AccountCircleIcon />
          </StyledListItemIcon>

          <StyledListItemText
            primary={
              <>
                {isB2B
                  ? t("Dashboard.Title")
                  : t("Header.AccountPopper.AccountSetting")}
              </>
            }></StyledListItemText>
        </StyledListItem>
      </Link>
    );
  };

  const LogoutSection = () => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid

      <a href="#" onClick={(event) => handleLogout(event)}>
        <StyledListItem>
          <StyledListItemIcon>
            <ExitToAppIcon />
          </StyledListItemIcon>

          <StyledListItemText
            primary={
              <>{t("Header.AccountPopper.SignOut")}</>
            }></StyledListItemText>
        </StyledListItem>
      </a>
    );
  };

  return (
    <>
      <StyledList disablePadding>
        <WelcomeSection />

        {isB2B && activeOrg && (
          <>
            <OrgAndContract />
          </>
        )}

        <Divider component="li" />

        <AccountSetting />

        {!forUserId && (
          <>
            <Divider component="li" />

            <LogoutSection />
          </>
        )}
      </StyledList>
    </>
  );
}

AccountPopperContent.propTypes = {
  isB2B: PropTypes.bool.isRequired,

  userName: PropTypes.object.isRequired,

  handleClose: PropTypes.func.isRequired,

  counter: PropTypes.number.isRequired,

  handleOrgChange: PropTypes.func.isRequired,

  handleContractChange: PropTypes.func.isRequired,

  handleLogout: PropTypes.func.isRequired,

  logonId: PropTypes.string.isRequired,
};

export default AccountPopperContent;
