/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 * Custom Store for saved banamex session payment
 * @dev EA
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/banamex";
import initStates from "./initStates";

/**
 * BanamexSession reducer
 * handles states used by EvoPayments and saved session
 * @param state State object managed by api reducer
 * @param action The dispatched action
 */
const BanamexSessionReducer = createReducer(initStates.sessionBanamex, (builder) => {
  builder.addCase(ACTIONS.SET_SESSION, (state, action: AnyAction) => {
    state.session = action.payload;
  });
  builder.addCase(ACTIONS.SET_IS_CARD_SAVED, (state, action: AnyAction) => {
    state.isCardUseSaved = action.payload;
  });
  builder.addCase(ACTIONS.PAY_WITH_CARD_SAVED, (state, action: AnyAction) => {
    state.payWithCardSaved = action.payload;
  });
  builder.addCase(ACTIONS.SET_TRANSACTIONID, (state, action: AnyAction) => {
    state.transactionId = action.payload;
  });
  builder.addCase(ACTIONS.SET_IS_BTN_PRESSED, (state, action: AnyAction) => {
    state.isBtnPressed = action.payload;
  });
});

export default BanamexSessionReducer;
