/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ModalContext from "../../../../../_foundation/context/ModalContext";
//Custom libraries
interface RegistrationB2BContext {
  cid: string;
  switchModalLoginReg: any;
  handleCloseModalLogin?: any;
  goToSignInPage?: any;
}

const TextLogin = styled.span`
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  color: #000000;
`;

const SignUpButton = styled.button`
  background: #f1f1f1;
  color: #333;
  border-radius: 0;
  min-width: 230px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  line-height: 40px;
  :disabled {
    background: #1bb0aa9e;
  }
  :enabled {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const TextSignUp = styled.span`
  font-size: 13px;
  font-weight: 300;
  color: #000;
  font-family: "Roboto";
  line-height: 1.3;
`;
const styledLoginModal = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "10px",
    marginBottom: "20px",
    marginTop: "25px",
  },
  divItem: {
    marginTop: "30px",
  },
  divSepBot: {
    paddingBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      borderBottom: "1px solid #e9e9e9",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #e9e9e9",
      marginBottom: "10px",
      paddingTop: "15px",
    },
  },
  box: {
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  loginTextBox: {
    margin: "0px !important",
    width: "226px",
    height: "45px",
    borderRadius: "0px",
    border: "1px solid #bfbfbf",
    backgroud: "#FFFFFF !important",
  },
  divInput: {
    marginBottom: "30px",
  },
  divForgot: {
    marginTop: "10px",
  },
  "@global": {
    fieldset: {
      display: "none",
    },
  },
  labelRegistration: {
    marginBottom: "30px",
    lineHeight: "0.9",
  },
}));

/**
 * Registration links for B2B
 * displays links to B2B registration forms
 * @param props
 */

const RegistrationB2BLayout: React.FC<RegistrationB2BContext> = (
  props: any
) => {
  const { t } = useTranslation();
  const cid = props.cid;
  const classesLogin = styledLoginModal();
  const handleCloseModalLogin = props.handleCloseModalLogin;
  const switchModalLoginReg = props.switchModalLoginReg;
  const history = useHistory();
  const { setLinkHeader, linkHeader } = useContext(ModalContext);

  // useEffect(() => {

  // }, [history.location.pathname]);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const goToSignInPage = () => {
    if (history.location.pathname != "/ventaporcatalogo") {
      history.push("/ventaporcatalogo");
      console.log("bug---entre al else RegistrationB2BLayout");
      let testimonios = document.getElementsByClassName("testimonialSlider");
      compruebaElemento();
      console.log(testimonios, "testimoinios");
      async function compruebaElemento() {
        do {
          await timeout(1000);
          await leerElemento();
        } while (testimonios[0] == null);
      }
      function leerElemento() {
        testimonios = document.getElementsByClassName("testimonialSlider");
        if (testimonios[0] != null) {
          setTimeout(() => {
            moveToForm();
          }, 1000);
        }
        return false;
      }
    } else {
      moveToForm();
    }
    handleCloseModalLogin();
  };

  function moveToForm() {
    console.log("bug --> entre MoveToFomr Registrartion");
    let divForm = document.getElementById("distributor");
    console.log(divForm, "divForm");
    if (divForm != null) {
      divForm.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <>
      <Grid container className={classesLogin.divSepBot}>
        <TextLogin>¿No tiene una cuenta?</TextLogin>
      </Grid>
      <Grid className={classesLogin.labelRegistration}>
        <TextSignUp>
          Registrate súper rápido y recibe todos los beneficios de vender por
          catálogo con dpdirecto.
        </TextSignUp>
      </Grid>
      <SignUpButton onClick={goToSignInPage}>
        {"Aplica para vender por catálogo".toUpperCase()}
      </SignUpButton>
    </>
  );
};

export { RegistrationB2BLayout };
