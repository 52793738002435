/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const WC_PREVIEW_TOKEN = "WCPreviewToken";
export const NEW_PREVIEW_SESSION = "newPreviewSession";
export const STORE_ID = "STORE_ID";
export const CURRENT_USER = "currentUser";
export const FOR_USER_SESSION = "forUserSession";
export const WINDOW_COUNTER = "windowCounter";
export const WINDOW_ID = "windowId";
export const APPOVAL_TOOL_ID = "approvalManagement";
export const USERS_TOOL_ID = "userManagement";
export const ORG_TOOL_ID = "organizationManagement";
export const ACCOUNT = "ACCOUNT";
export const LANGID = "langId";
export const LOCALE = "locale";
export const SHOW_API_FLOW = "showAPIFlow";
export const PRODUCTION = "production";
export const FOR_USER_ID = "forUserId";
//parent CSR window path when shop on behalf.
export const SHOP_ON_BEHALF_PATH = "/shop-on-behalf";
export const SKIP_WC_TOKEN_HEADER = "skipWCTokenHeader";
export const EXPIRED_PASSWORD_PAGE_ERROR = "expiredPasswordPageError";
export const SHOW_API_FLOW_TRANSACTION = "Transaction";
export const SHOW_API_FLOW_SEARCH = "Search";
export const HOME = "home";
export const CATEGORYPAGE = "CategoryPage";
export const HOMEPAGE = "HomePage";
