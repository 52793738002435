/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

/**
 * usage
 * <StyledPromiseDate min={1} max={5}/> use min and max props
 * <StyledPromiseDate /> get min and max from storeconf
 */
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledNoInventoryMsg = styled.div`
  .noInventory {
    width: 140px;
    height: 30px;
    background-color: #FFF4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px 3px 5px;
    box-sizing: border-box;
  }

  .noInventory span {
    color: #B12704;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
  }

  p {
    display: block;
    font-family: Roboto;
    font-size: 14px !important;
    margin-top: 6px;
    font-weight: 400;
    text-align: left;
  }
`;

const ProductoAgotado = React.forwardRef((props: any, ref: any) => {
  const { t } = useTranslation();

  return (
    <StyledNoInventoryMsg>
      <div className="noInventory">
        <span>{t("productDetail.productOutOfStock")}</span>
      </div>
      <p>{t("productDetail.temporarilyOutOfStock")}</p>
    </StyledNoInventoryMsg>
  );
});

export { ProductoAgotado };
