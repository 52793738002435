/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";

//Components
import CustomMiniCartContent from "./CustomMiniCartContent";
//UI
import { Box, Divider, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledPaper,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";

const ButtonCart = styled.a`
  background: #1bb0aa;
  border-left: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-weight: bold;
  border: none;
  height: 40px;
  min-width: 270px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  padding: 0 15px;
  width: auto;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 40px;
  vertical-align: middle;
  margin-bottom: 15px !important;
`;

const styles = makeStyles((theme) => ({
  detailsBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      flexDirection: "column",
      textAlign: "center",
    },
  },
  avatarSize: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

interface MiniCartContentProps {
  title: string;
  orderItems: any[];
  numOrderItems: number;
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  emptyCartMsg: string;
  cartLinkLabel: string;
  handleCartOnClick: Function;
  handleClose: Function;
}

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>(
  (props: any, ref: any) => {
    const {
      title,
      orderItems,
      numOrderItems,
      subtotalLabel,
      subtotal,
      subtotalCurrency,
      emptyCartMsg,
      cartLinkLabel,
      handleCartOnClick,
      handleClose,
    } = props;

    const classes = styles();

    return (
      <StyledPaper
        ref={ref}
        className="mini-cart-container"
        data-testid="mini-cart-popper">
        <Box display="flex" height={60} alignItems="center">
          <Box flexGrow={1}>
            <StyledTypography
              variant="h6"
              className="horizontal-padding-2 vertical-padding-2">
              {title}
            </StyledTypography>
          </Box>
          <Box paddingRight={2}>
            <IconButton aria-label="cerrar">
              <CloseIcon onClick={handleClose} />
            </IconButton>
          </Box>
        </Box>
        {orderItems.length > 0 ? (
          <>
            {/* <StyledBox className="horizontal-padding-2">
              <OrderItemTable
                data={orderItems}
                miniCartView={true}
                handleMiniCartClose={handleClose}
              />
            </StyledBox> */}

            <CustomMiniCartContent
              data={orderItems}
              numOrderItems={numOrderItems}
              handleClose={handleClose}
            />

            <Divider />
            <StyledGrid
              container
              className="horizontal-padding-2 vertical-padding-2">
              <StyledGrid item xs={7}>
                <StyledTypography variant="body1" style={{ fontSize: "20px" }}>
                  {subtotalLabel}
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={5}>
                <StyledTypography
                  variant="body1"
                  align="right"
                  style={{ fontWeight: "bold", fontSize: "20px" }}>
                  <div>
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(Number(subtotal))}
                  </div>
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
            <StyledBox
              className="horizontal-padding-2 vertical-padding-1"
              align="center">
              <ButtonCart className="center" onClick={handleCartOnClick}>
                {cartLinkLabel}
              </ButtonCart>
            </StyledBox>
          </>
        ) : (
          <>
            <StyledTypography
              align="center"
              className="horizontal-padding-2 vertical-padding-10">
              {emptyCartMsg}
            </StyledTypography>
            <StyledBox
              className="horizontal-padding-2 vertical-padding-1"
              align="center">
              <ButtonCart className="center" onClick={handleClose}>
                {cartLinkLabel}
              </ButtonCart>
            </StyledBox>
          </>
        )}
      </StyledPaper>
    );
  }
);

export default StyledMiniCartContent;
